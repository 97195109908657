import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import CoinSwiper from './components/features/CoinSwiper.js';
import SettingsModal from './components/modals/SettingsModal.js';
import FavoritesModal from './components/modals/FavoritesModal.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Connection, PublicKey, Transaction, SystemProgram, Keypair, LAMPORTS_PER_SOL, VersionedTransaction } from '@solana/web3.js';
import logo from './assets/logo.webp';
import logotp from './assets/logo-tp.png';
import { WalletProvider, ConnectionProvider, useWallet, useConnection } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import bs58 from 'bs58';
import '@solana/wallet-adapter-react-ui/styles.css';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { SolletWalletAdapter } from '@solana/wallet-adapter-sollet';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { clusterApiUrl } from '@solana/web3.js';
import { useMemo } from 'react';
import { useCallback } from 'react';
import AuthFlow from './components/AuthFlow.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PumpCalculator from './components/features/GainsCalculator.js';
import GainsCalculator from './components/features/GainsCalculator.js';
import BananaStandDashboard from './ad/BananaStandDashboard.js';
import TokenBurnTracker from './components/reusables/BurnData.js';
import VideoPlayer from './components/reusables/VideoPlayer.js';
import wojakVideo from './assets/wojakbuysbanana.mp4'
import kodakVideo from './assets/promo.mp4'
import TokenWheel from './components/features/TokenWheel.js';
import AdminWhitelist from './components/admin/AdminWhitelist.js';
import AIAgentDashboard from './components/features/AIAgents/AIAgentDashboard.js';
import AgentsManagement from './components/features/AIAgents/AgentsManagements.js';
import { RuleTwoTone } from '@mui/icons-material';
import XRPTeaser from './components/Expansions/XRP/XRPTeaser.js';
import BSCDashboard from './components/Expansions/BSC/BSCDashboard.js';
import MaintenanceBanner from './components/common/MaintenanceBanner.js';
import AIAgentTester from './components/features/AIAgents/AIAgentTester.js';

import PropTypes from 'prop-types';
import LaunchpadPage from './pages/LaunchpadPage.js';
import LootBoxTeaser from './pages/LootBoxTeaser.js';
import GatedContent from './pages/GatedContent.js';
import AppTour from './components/reusables/joyride/AppTour.js';
import OrderLookupPage from './pages/OrderLookupPage.js';
import ApeGuide from './pages/ApeGuide.js';
import { Box } from '@mui/material';
import ApeMindDashboard from './pages/ApeMindDashboard.js';
import LandingPage from './pages/LandingPage.js';
import TierStructure from './pages/TierStructure.js'; 
import TrendingTokensPage from './pages/TrendingTokensPage.js';
import BalanceGatedPage from './pages/BalanceGatedPage.js';
import PawChainPage from './pages/PawChainPage.js';
import Marketplace from './pages/Marketplace.js';
import Roadmap from './pages/Roadmap.js';
import Claim from './pages/Claim.js';
import TermsOfUse from './pages/legal/TermsOfUse.js';
import TermsFireSale from './pages/legal/TermsFireSale.js';
import TermsAirdrop from './pages/legal/TermsAirdrop.js';
import TokenHolders from './components/modals/TokenHolders.js';
import Bounties from './pages/Bounties.js';
import AIHardwareProjects from './pages/AIHardwareProjects.js';
import GiveawayPage from './pages/GiveawayPage.js';
import CronosDashboard from './pages/Cronos.js';
import APIAccessPage from './pages/APIAccessPage.js';
import APIAccessCompletePage from './pages/APIAccessCompletePage.js';
import enhancedRequestHandler from './utils/enhancedRequestHandler.js';
import BnnaGate from './components/reusables/BnnaGate.js';
import BalanceCache from './utils/balanceCache.js';
import EnhancedLoading from './components/reusables/EnhancedLoading.js';
import { CircularProgress, Typography } from '@mui/material';
import LoginCheck from './components/auth/LoginCheck';
import { checkBalance } from './components/wallet/balanceCheck.js';
import AutoTPSLPage from './pages/AutoTPSLPage.js';
import Governance from './pages/Governance.js';
import Leaderboard from './components/reusables/Leaderboard.js';
import DemoAutoApeModal from './components/modals/DemoAutoApeModal';

// Add after your existing imports
const backoff = (retryCount) => {
  return Math.min(1000 * Math.pow(2, retryCount), 30000);
};
const requestTracker = {
  requests: new Map(),
  interval: 60000,
  limit: 50,

  canMakeRequest(endpoint) {
    const now = Date.now();
    const reqs = this.requests.get(endpoint) || [];
    const recentReqs = reqs.filter(time => now - time < this.interval);
    this.requests.set(endpoint, recentReqs);
    return recentReqs.length < this.limit;
  },

  trackRequest(endpoint) {
    const reqs = this.requests.get(endpoint) || [];
    reqs.push(Date.now());
    this.requests.set(endpoint, reqs);
  }
};
const enhancedCache = {
  data: new Map(),
  set(key, value, type) {
    this.data.set(`${type}-${key}`, value);
  },
  get(key, type) {
    return this.data.get(`${type}-${key}`);
  }
};
export const requestQueue = {
  queue: [],
  processing: false,
  maxRetries: 3,
  retryDelay: 1000,

  async add(request) {
    return new Promise((resolve, reject) => {
      this.queue.push({
        request,
        retries: 0,
        resolve,
        reject
      });
      if (!this.processing) this.process();
    });
  },

  async process() {
    if (this.processing || this.queue.length === 0) return;
    this.processing = true;
    
    while (this.queue.length > 0) {
      const { request, retries, resolve, reject } = this.queue[0];
      
      try {
        const result = await request();
        resolve(result);
        this.queue.shift();
        await new Promise(r => setTimeout(r, 2000)); // 2s delay between requests
      } catch (error) {
        if (retries < this.maxRetries && error.message.includes('Rate limited')) {
          // Retry rate-limited requests
          this.queue[0].retries++;
          await new Promise(r => setTimeout(r, this.retryDelay * Math.pow(2, retries)));
          continue;
        }
        reject(error);
        this.queue.shift();
      }
    }
    
    this.processing = false;
  }
};

const AIAgentDashboardWrapper = ({ handleBuy, handleSell }) => {
  const { publicKey } = useWallet();
  
  console.log('Wrapper props check:', {
    hasHandleBuy: typeof handleBuy === 'function',
    hasHandleSell: typeof handleSell === 'function',
    publicKey: publicKey?.toString()
  });
  

  return (
    <AIAgentDashboard 
      publicKey={publicKey}
      handleBuy={handleBuy}
      handleSell={handleSell}
    />
  );
};
const App = ( props) => {
  const [refreshBalancesCallback, setRefreshBalancesCallback] = useState(null);

  const [showSettings, setShowSettings] = useState(false);
  const [showFavorites, setShowFavorites] = useState(false);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [buyAmount, setBuyAmount] = useState('custom');
  const [favorites, setFavorites] = useState([]);
  const [wallet, setWallet] = useState(null);
  const [ownedTokens, setOwnedTokens] = useState({});
  const [boughtTokens, setBoughtTokens] = useState([]);
  const [customAmount, setCustomAmount] = useState(localStorage.getItem('customAmount') || '');
  const [latestMintAddress, setLatestMintAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [ownedTokensData, setOwnedTokensData] = useState([]);
  const { publicKey, connected, sendTransaction } = useWallet();
  const [tradingWallet, setTradingWallet] = useState(null);
  const [latestMintAddresses, setLatestMintAddresses] = useState([]); 
  const [wsConnected, setWsConnected] = useState(false);
  const [wsRetryCount, setWsRetryCount] = useState(0);
  const [tokens, setTokens] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [hasRequiredTokens, setHasRequiredTokens] = useState(false);
  const [phaseOneActive, setPhaseOneActive] = useState(false);
  const [devFeeSet, setDevFeeSet] = useState(false)
  const [walletConnected, setWalletConnected] = useState(false);
  const isProcessing = useRef(false);
  const lastUserDataFetch = useRef(null);
  const [isWalletChecking, setIsWalletChecking] = useState(true);

  const tokenDataCache = useRef({});
  // Add near other state declarations
const [isWalletRequired, setIsWalletRequired] = useState(true);
const [hasBnnaTokens, setHasBnnaTokens] = useState(false);

  const PLATFORM_TOKENS = [
    'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump',
  ];
  const [points, setPoints] = useState(0);
  const [completedTasks, setCompletedTasks] = useState({
    walletConnected: false,
    tradingWalletCreated: false,
    bananaMillionaire: false,
    bananaBurner: false,
    bananaResearcher: false,
    bananaGamer: false,
    apeOfLegend: false,
    richApe: false,
    apenoob: false,
    boughtBNNA: false,
    usedPumpCalculator: false,
    usedTokenAssessor: false,
  });
  const toastConfig = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      background: 'rgba(23, 32, 42, 0.95)',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      borderRadius: '12px',
      boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
      color: 'white',
      fontSize: '14px',
      padding: '16px',
    },
    progressStyle: {
      background: 'linear-gradient(to right, #4CAF50, #81C784)',
    },
  };
  const [coins, setCoins] = useState([
    { 
      name: 'ApeOut: Swift Exit Strategy for Crypto Traders', 
      description: 'ApeOut is a cutting-edge application designed for crypto traders who need to quickly "ape out" of their token positions, especially in the volatile world of memecoins and pump-and-dump schemes. Built with a focus on the pump.fun ecosystem, ApeOut provides a streamlined interface for rapid selling of tokens to maximize profits or minimize losses.',
      image: logo,
      mint: 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump'
    },
  ]);
  const DEV_WALLET = '5LW5YcDNQipJPzEUXCuwTu6gE4vzaEuiAqFSyjfpjBZx'; // Replace with your actual dev wallet address
  const FEE_PERCENTAGE = 0.009; // 0.9%
  const SELL_FEE_AMOUNT = 0.0001
  const [autoSnipeEnabled, setAutoSnipeEnabled] = useState(false);
  const [tradeSettings, setTradeSettings] = useState({
    priorityFee: 0.0001,
    slippage: 15,
    amount: 0.001 // Default amount
  });
  const [tokenPurchaseHistory, setTokenPurchaseHistory] = useState(
    JSON.parse(localStorage.getItem('tokenPurchaseHistory') || '{}')
  );
  const balanceCache = {
    data: new Map(),
    timestamp: new Map(),
    TTL: 30000,
  
    set(key, value) {
      this.data.set(key, value);
      this.timestamp.set(key, Date.now());
    },
  
    get(key) {
      const timestamp = this.timestamp.get(key);
      if (!timestamp) return null;
      if (Date.now() - timestamp > this.TTL) {
        this.data.delete(key);
        this.timestamp.delete(key);
        return null;
      }
      return this.data.get(key);
    }
  };
  const validateEnvironment = () => {
    const required = ['REACT_APP_API_URL'];
    const missing = required.filter(key => !process.env[key]);
    
    if (missing.length > 0) {
      console.error(`Missing required environment variables: ${missing.join(', ')}`);
      return false;
    }
    return true;
  };
  const { connection } = useConnection();

  const [showDemoModal, setShowDemoModal] = useState(false);
  const originalBuy = useRef(null);

  // Add a demo mode state in App.js
  const [isDemoMode, setIsDemoMode] = useState(false);

  useEffect(() => {
    if (!wsConnected && wsRetryCount > 0) {
      toast.warning(`Connection lost. Retrying... (${wsRetryCount}/5)`);
    } else if (wsConnected && wsRetryCount > 0) {
      toast.success('Connection restored');
      toast.success(
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span>❌</span>
          <div>
            <div style={{ fontWeight: 500 }}>Connection Restored</div>
            
          </div>
        </div>,
        toastConfig
      );
    }
  }, [wsConnected, wsRetryCount]);

  useEffect(() => {
    if (!validateEnvironment()) {
      toast.error('Application not properly configured. Check console for details.');
    }
    console.log('Current API URL:', process.env.REACT_APP_API_URL);
  }, []);

  useEffect(() => {
    const checkExistingAuth = async () => {
      if (!connected || !publicKey) {
        setIsWalletRequired(true);
        setIsAuthenticated(false);
        setHasBnnaTokens(false);
        setIsInitialLoad(false); // Clear loading

        return;
      }
    
      try {
        setWalletConnected(true);
        setWallet(publicKey);
        await new Promise(r => setTimeout(r, 20000));
        const cachedBalance = BalanceCache.get(publicKey.toString());
        if (cachedBalance !== null) {
          const hasBnna = cachedBalance > 0;
          setHasBnnaTokens(hasBnna);
          setIsWalletRequired(false);
          setIsAuthenticated(hasBnna);
          setIsInitialLoad(false);
          return;
        }
        
        // Check BNNA balance
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/token-balance/${publicKey.toString()}/DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump`
        );
    
        if (!response.ok) {
          throw new Error('Failed to fetch BNNA balance');
        }
    
        const data = await response.json();
        const hasBnna = data.success && data.balance > 0;
        
        setHasBnnaTokens(hasBnna);
        setIsWalletRequired(false);
    
        if (!hasBnna) {
          setIsAuthenticated(false);
          setIsInitialLoad(false); // Clear loading when no BNNA

          return;
        }
    
        // Continue with existing trading wallet check logic
        const storedTradingWallet = localStorage.getItem('tradingWallet');
        if (storedTradingWallet) {
          setTradingWallet(JSON.parse(storedTradingWallet));
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        setIsAuthenticated(false);
        setHasBnnaTokens(false);
      }
      setIsInitialLoad(false);
    };
  
    checkExistingAuth();
  }, [connected, publicKey]);

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
    setFavorites(storedFavorites);
    const storedBuyAmount = localStorage.getItem('buyAmount') || 'custom';
    setBuyAmount(storedBuyAmount);
    const storedBoughtTokens = JSON.parse(localStorage.getItem('boughtTokens')) || [];
    setBoughtTokens(storedBoughtTokens);
    const storedSettings = localStorage.getItem('tradeSettings');
    if (storedSettings) {
      setTradeSettings(JSON.parse(storedSettings));
    }
  }, []);
  useEffect(() => {
    if (connected && publicKey) {
      setWalletConnected(true);
      setIsWalletChecking(false);
    } else if (!connected) {
      setWalletConnected(false);
      setIsWalletChecking(false);
    }
  }, [connected, publicKey]);
  useEffect(() => {
      const persistedWalletConnection = localStorage.getItem('walletConnected');
      if (persistedWalletConnection === 'true' && connected && publicKey) {
        setIsWalletConnected(true);
        setWallet(publicKey);
      }
  }, []);

  useEffect(() => {
    const checkTokenAndBuy = async () => {
      if (autoSnipeEnabled && latestMintAddress) {
        try {
          // Call handleBuy directly since the balance check is already done via the button
          handleBuy(latestMintAddress);
        } catch (error) {
          console.error('Error during auto-snipe:', error);
          toast.error('An error occurred during the auto-snipe process.');
        }
      }
    };

    checkTokenAndBuy();
  }, [autoSnipeEnabled, latestMintAddress]); // Add autoSnipeEnabled as a dependency
  
  
  // useEffect(() => {
  //   const loadPurchaseHistory = async () => {
  //     if (publicKey) {
  //       try {
  //         const response = await fetch(
  //           `${process.env.REACT_APP_API_URL}api/user/${publicKey.toString()}`
  //         );
  //         const userData = await response.json();
  //         if (userData.tokenPurchases) {
  //           const historyMap = userData.tokenPurchases.reduce((acc, purchase) => {
  //             acc[purchase.tokenMint] = purchase;
  //             return acc;
  //           }, {});
  //           setTokenPurchaseHistory(prevHistory => ({
  //             ...prevHistory,
  //             ...historyMap
  //           }));
  //           localStorage.setItem('tokenPurchaseHistory', JSON.stringify(historyMap));
  //         }
  //       } catch (error) {
  //         console.error('Error loading purchase history:', error);
  //       }
  //     }
  //   };
  
  //   loadPurchaseHistory();
  // }, [publicKey]);


  // Function to clear tokens
  const clearTokens = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/clear-tokens`, {
        method: 'POST',
      });
      
      if (!response.ok) {
        throw new Error('Failed to clear tokens');
      }
      
      setTokens([]);
      setPage(1);
      setHasMore(true);
      await fetchNewTokens(1); // Fetch first page after clearing
      toast.success('Token list cleared successfully');
    } catch (error) {
      console.error('Error clearing tokens:', error);
      toast.error('Failed to clear tokens');
    }
  };

  const updateTokenData = async (mintAddress) => {
    const loadingToast = toast.loading(`Fetching data for ${mintAddress.slice(0, 6)}...`);
    try {
      const tokenData = await fetchTokenData(mintAddress);
      
      setCoins(prevCoins => prevCoins.map(coin => 
        coin.mint === mintAddress ? {
          ...coin,
          name: tokenData.name,
          description: tokenData.description,
          image: tokenData.imageUri || coin.image,
          marketCap: tokenData.marketCap,
          usdMarketCap: tokenData.usdMarketCap,
          socialLinks: tokenData.socialLinks,
          metrics: tokenData.metrics,
          isPlaceholder: false
        } : coin
      ));
  
      toast.update(loadingToast, {
        render: "Token data updated successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000
      });
    } catch (error) {
      console.error('Error updating token data:', error);
      toast.update(loadingToast, {
        render: `Failed to fetch token data: ${error.message}`,
        type: "error",
        isLoading: false,
        autoClose: 3000
      });
    }
  };
  const handleAuthComplete = async (tradingWallet) => {
    if (!connected || !publicKey) {
      console.error('Wallet not connected');
      setIsAuthenticated(false);
      return;
    }

    try {
      const balances = await checkBalance(publicKey.toString(), tradingWallet);
      
      if (balances.bnnaBalance > 0) {
        setTradingWallet(tradingWallet);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        toast.error('Required BNNA tokens not found');
      }
    } catch (error) {
      console.error('Auth completion error:', error);
      setIsAuthenticated(false);
      
      if (!error.message.includes('Rate limited')) {
        toast.error('Error verifying token balance');
      }
    }
  };
  const fetchNewTokens = async () => {

    return requestQueue.add(async () => {
      // ... existing fetchNewTokens code ...
      setIsLoading(true);
    try {
      console.log('Fetching new tokens');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/latest_mint_addresses`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        
      });
  
      if (!response.ok) {
        if (response.status === 429) {
          throw new Error('Rate limited - please wait before trying again');
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
          await new Promise(resolve => setTimeout(resolve, 2000));

      
      if (!data.success) {
        throw new Error(data.error || 'Failed to fetch mint addresses');
      }
  
      // Create placeholder data for each mint address
      const newCoins = data.mintAddresses.map(mintAddress => ({
        name: `Token (${mintAddress.slice(0, 6)}...)`,
        description: 'Click "Fetch Details" to load token information',
        image: 'placeholder_image_url',
        mint: mintAddress,
        timestamp: Date.now(),
        isPlaceholder: true // Flag to indicate this is placeholder data
      }));
  
      setCoins(prevCoins => {
        const existingMints = new Set(prevCoins.map(coin => coin.mint));
        const filteredNewCoins = newCoins.filter(coin => !existingMints.has(coin.mint));
        return [...prevCoins, ...filteredNewCoins].slice(0, 25); // Keep only latest 25 tokens
      });
  
    } catch (error) {
      console.error('Error fetching new tokens:', error);
      toast.error(error.message || 'Failed to fetch new tokens');
    } finally {
      setIsLoading(false);
    }
    });
    
  };
  const fetchUserData = async (forceUpdate = false) => {
     if (!forceUpdate && Date.now() - lastUserDataFetch < 30000) return;
  lastUserDataFetch = Date.now();
    if (!requestTracker.canMakeRequest('user_data')) {
      console.warn('Rate limit reached for user data endpoint');
      return;
    }
  
    return requestQueue.add(async () => {
      let retries = 3;
      while (retries > 0) {
        try {
          requestTracker.trackRequest('user_data');
  
          if (!publicKey && !forceUpdate) {
            console.warn('Wallet not connected');
            setIsWalletConnected(false);
            return;
          }
          
          const response = await fetch(`${process.env.REACT_APP_API_URL}api/user_balances/${tradingWallet.publicKey}`);
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          
          const data = await response.json();
          if (data.success) {
            const tokenBalances = data.balances.reduce((acc, token) => {
              acc[token.mint] = token.balance;
              return acc;
            }, {});
            setOwnedTokens(tokenBalances);
            setOwnedTokensData(data.ownedTokensData);
            return;
          }
          throw new Error(data.message || 'Failed to fetch user data');
          
        } catch (error) {
          retries--;
          if (retries === 0) throw error;
          await new Promise(resolve => setTimeout(resolve, backoff(3 - retries)));
        }
      }
    });
  };
  const toggleFavorite = (coin) => {
    const newFavorites = favorites.includes(coin)
      ? favorites.filter(fav => fav !== coin)
      : [...favorites, coin];
    setFavorites(newFavorites);
    localStorage.setItem('favorites', JSON.stringify(newFavorites));
    
    if (favorites.includes(coin)) {
      toast.success(`Removed ${coin.name} from favorites`);
    } else {
      toast.success(`Added ${coin.name} to favorites`);
    }
  };
  const removeFavorite = (coin) => {
    const newFavorites = favorites.filter(fav => fav !== coin);
    setFavorites(newFavorites);
    localStorage.setItem('favorites', JSON.stringify(newFavorites));
    toast.success(`Removed ${coin.name} from favorites`);
  };
  const sendFunds = async () => {
    if (!publicKey || !tradingWallet) {
      toast.error("Wallet not connected or trading wallet not created");
      return;
    }
  
    const amount = 0.00001; // You might want to make this configurable
    try {
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey(tradingWallet.walletPublicKey),
          lamports: amount * LAMPORTS_PER_SOL,
        })
      );
      console.log(amount * LAMPORTS_PER_SOL)
  
      const signature = await sendTransaction(transaction, connection);
      const latestBlockhash = await connection.getLatestBlockhash();
      await connection.confirmTransaction({
        signature,
        blockhash: latestBlockhash.blockhash,
        lastValidBlockHeight: latestBlockhash.lastValidBlockHeight
      }, 'confirmed');
        
      toast.success(`Successfully sent ${amount} SOL to trading wallet. Signature: ${signature}`);
    } catch (error) {
      console.error('Error sending funds:', error);
      toast.error(`Failed to send funds: ${error.message}`);
    }
  };
  const sendDevFee = async (tradeAmount) => {
    const toastId = toast.loading(
      <div>
        <div>Processing dev fee...</div>
        <div style={{fontSize: '0.8em', opacity: 0.8}}>
          Trade Amount: {tradeAmount} SOL
          Expected Fee: {(tradeAmount * FEE_PERCENTAGE).toFixed(6)} SOL
        </div>
      </div>
    );
    let retries = 3;
    
    while (retries > 0) {
      try {
        if (!tradingWallet) {
          throw new Error("Trading wallet not initialized");
        }
  
        // const feeAmount = 0.000000001; // Match Python script's minimal amount
        const feeAmount = tradeAmount * FEE_PERCENTAGE;
        toast.update(toastId, {
          render: (
            <div>
              <div>Sending fee transaction...</div>
              <div style={{fontSize: '0.8em', opacity: 0.8}}>
                Amount: {feeAmount.toFixed(6)} SOL
                From: {tradingWallet.walletPublicKey.slice(0, 6)}...
                To: {DEV_WALLET.slice(0, 6)}...
              </div>
            </div>
          ),
          isLoading: true
        });
        
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/send-fee-auto`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: feeAmount,
            senderPublicKey: tradingWallet.walletPublicKey,
            recipientAddress: DEV_WALLET,
            privateKey: tradingWallet.privateKey
          }),
        });
  
        const data = await response.json();
        
        if (data.success) {
          toast.update(toastId, { 
            render: (
              <div>
                <div>Dev Fee Sent Successfully!</div>
                <div style={{fontSize: '0.8em', opacity: 0.8}}>
                  Amount: {feeAmount.toFixed(6)} SOL
                  TX: {data.signature?.slice(0, 8)}...
                </div>
              </div>
            ),
            type: "success", 
            isLoading: false, 
            autoClose: 5000 
          });
          return;
        }
        
        throw new Error(data.error || 'Transaction failed');
  
      } catch (error) {
        console.error('Error sending dev fee:', error);
        retries--;
        
        if (retries === 0) {
          toast.update(toastId, { 
            render: (
              <div>
                <div>Failed to send dev fee</div>
                <div style={{fontSize: '0.8em', opacity: 0.8}}>
                  Error: {error.message}
                  Attempts: {3 - retries}/3
                </div>
              </div>
            ),
            type: "error", 
            isLoading: false, 
            autoClose: 5000 
          });
          return false;
        } else {
          toast.update(toastId, {
            render: (
              <div>
                <div>Retrying fee transaction...</div>
                <div style={{fontSize: '0.8em', opacity: 0.8}}>
                  Attempts remaining: {retries}
                  Last error: {error.message}
                </div>
              </div>
            ),
            isLoading: true
          });
          console.log(`Retrying... ${retries} attempts left`);
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      }
    }
    return false;
  };
  const getPrivateKeyForPublicKey = (publicKey) => {
    const storedTradingWallet = JSON.parse(localStorage.getItem('tradingWallet'));
    if (storedTradingWallet && storedTradingWallet.publicKey === publicKey) {
      return storedTradingWallet.privateKey;
    }
    return null;
  };
  const originalHandleBuy = async (mintAddress, amount, slippage, priorityFee) => {
    try {
      // Validate wallets - with demo mode support
      const walletValidation = {
        mainWallet: !!wallet?.publicKey,
        tradingWallet: !!tradingWallet?.walletPublicKey
      };
      
      // Check if we should bypass wallet validation
      if (isDemoMode) {
        console.log('Demo mode active - bypassing full wallet validation');
        // In demo mode, we only need the trading wallet
        if (!walletValidation.tradingWallet) {
          toast.error('Please connect your trading wallet for demo mode');
          return { success: false, error: 'Trading wallet required for demo mode' };
        }
      } else {
        // Normal mode - require both wallets
        if (!walletValidation.mainWallet || !walletValidation.tradingWallet) {
          console.log('Wallet validation failed: ', walletValidation);
          toast.error('Please connect both wallets to trade');
          return { success: false, error: 'Wallet validation failed' };
        }
      }
      
      // Rest of the function remains the same
      // ...
    } catch (error) {
      console.error('Error processing buy:', error);
      toast.error(`Buy failed: ${error.message}`);
      return { success: false, error: error.message };
    }
  };
  const handleBuy = async (params) => {
    // Extract parameters
    const { mint, amount, slippage = 15, priorityFee = 0.001 } = params;
    
    console.log('handleBuy called with:', { mint, amount, slippage, priorityFee });
    
    // Call the original buy function with the correct parameters
    return await originalHandleBuy(mint, amount, slippage, priorityFee);
  };
  const determinePool = (marketCap, preferredPool) => {
    if (preferredPool === 'pump') return 'pump';
    if (preferredPool === 'raydium') return 'raydium';
    // Auto mode based on market cap
    return marketCap > 100000 ? 'raydium' : 'pump';
  };


 
  // Utility function to validate and normalize priority fee
const normalizePriorityFee = (fee) => {
  // Convert to number and ensure it's not negative
  const normalizedFee = Math.max(0, Number(fee));
  
  // Ensure we have max 6 decimal places to prevent floating point issues
  return Number(normalizedFee.toFixed(6));
};


const decryptTradingWalletKey = async (tradingWallet) => {
  if (!tradingWallet?.privateKey) {
    throw new Error('Trading wallet or private key not found');
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/decrypt-key`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer true` // Use appropriate auth token
      },
      body: JSON.stringify({
        encryptedKey: tradingWallet.privateKey,
        walletAddress: tradingWallet.walletPublicKey
      })
    });

    if (!response.ok) {
      throw new Error('Failed to decrypt private key');
    }

    const { decryptedKey } = await response.json();
    if (!decryptedKey) {
      throw new Error('Decryption returned empty key');
    }

    return decryptedKey;
  } catch (error) {
    console.error('Error decrypting private key:', error);
    throw new Error('Failed to decrypt wallet credentials');
  }
};

const originalHandleBuyRaydium = async (coin, amount) => {
  if (isProcessing.current) {
    console.log('Transaction already processing');
    return false;
  }

  toast.dismiss();
  let decryptedKey = null;
  
  try {
    isProcessing.current = true;

    // Decrypt the private key
    decryptedKey = await decryptTradingWalletKey(tradingWallet);

    // Create keypair with decrypted key
    const tradingKeypair = Keypair.fromSecretKey(bs58.decode(decryptedKey));

    // Verify keypair matches public key
    if (tradingKeypair.publicKey.toBase58() !== tradingWallet.walletPublicKey) {
      throw new Error('Key pair verification failed');
    }

    const bundledTxArgs = [{
      publicKey: tradingKeypair.publicKey.toBase58(),
      action: "buy",
      mint: coin,
      denominatedInSol: "true",
      amount: amount,
      slippage: Number(tradeSettings.slippage),
      priorityFee: Number(tradeSettings.priorityFee),
      pool: "raydium"
    }];

    const response = await fetch(`https://pumpportal.fun/api/trade-local`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bundledTxArgs)
    });

    if (response.status === 200) {
      const transactions = await response.json();
      console.log('Received transactions:', transactions);

      let encodedSignedTransactions = [];
      let signatures = [];

      // Sign each transaction
      for (let i = 0; i < bundledTxArgs.length; i++) {
        const tx = VersionedTransaction.deserialize(
          new Uint8Array(bs58.decode(transactions[i]))
        );
        tx.sign([tradingKeypair]);
        encodedSignedTransactions.push(bs58.encode(tx.serialize()));
        signatures.push(bs58.encode(tx.signatures[0]));
      }

      // Submit to Jito
      const jitoResponse = await fetch(`https://mainnet.block-engine.jito.wtf/api/v1/bundles`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          jsonrpc: "2.0",
          id: 1,
          method: "sendBundle",
          params: [encodedSignedTransactions]
        })
      });

      if (jitoResponse.ok) {
        toast.success(
          <div>
            <div>Buy successful!</div>
            <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
              <a 
                href={`https://solscan.io/tx/${signatures[0]}`} 
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#4ade80' }}
              >
                View on Solscan
              </a>
            </div>
          </div>
        );

        // Update UI state
        setOwnedTokens(prev => ({
          ...prev,
          [coin]: (prev[coin] || 0) + parseFloat(amount)
        }));

        return true;
      } else {
        throw new Error('Jito bundle submission failed');
      }
    } else {
      throw new Error(`API error: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Buy error:', error);
    toast.error(`Buy failed: ${error.message}`);
    return false;
  } finally {
    // Clear decrypted key from memory
    decryptedKey = null;
    isProcessing.current = false;
  }
};
const handleBuyRaydium = async (coin, amount) => {
  try {
    const result = await originalHandleBuyRaydium(coin, amount);
    if (result) {
      await refreshBalancesCallback('buyRaydium');
    }
    return result;
  } catch (error) {
    throw error;
  }
};

const handleSell = async (coin, amount, pool) => {
  try {
    const result = await originalHandleSell(coin, amount, pool);
    if (result) {
      await refreshBalancesCallback('sell');
    }
    return result;
  } catch (error) {
    throw error;
  }
};


const isSOLAmount = (amount) => {
  // SOL amounts are typically small decimals (< 100 SOL is common)
  // Token amounts are usually larger whole numbers
  return amount < 100 && amount > 0;
};

  const originalHandleSell = async (coin, amount, pool) => {
    if (isProcessing.current) {
      console.log('Transaction already processing');
      return false;
    }
  
    toast.dismiss();
  
    if (!wallet?.publicKey || !tradingWallet?.walletPublicKey) {
      console.error('Wallet validation failed:', {
        mainWallet: !!wallet?.publicKey,
        tradingWallet: !!tradingWallet?.walletPublicKey
      });
      toast.error("Please ensure both wallets are connected");
      return false;
    }
  
    const { feeAmount, effectiveAmount } = calculateFeeAmount(amount);
  
    const toastId = toast.loading(
      <div>
        <div style={{ fontWeight: 500 }}>Processing Sell Order</div>
        <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
          Trade Amount: {amount} Tokens<br/>
         
        </div>
      </div>,
      toastConfig
    );
  
    try {
      isProcessing.current = true;
  
      if (!tradingWallet?.apiKey) {
        throw new Error("Trading wallet not properly initialized");
      }
  
      const response = await fetch(`https://pumpportal.fun/api/trade?api-key=${tradingWallet.apiKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'sell',
          mint: coin,
          amount: amount,
          denominatedInSol: isSOLAmount(amount).toString(),
          slippage: 15,
          priorityFee: 0.0001,
          pool: pool,
        }),
      });
  
      if (!response.ok) {
        toast.error('Swapping with Raydium. Please try again later.');
        const sellSuccess = await handleSellRaydium(coin, amount);
        if (sellSuccess) {
          return true;
        }
        
        throw new Error(`Trade API error: ${response.status}`);

      }
  
      const sellResult = await response.json();
      
      if (!sellResult.signature) {
        throw new Error(sellResult.errors || 'Transaction failed');
      }
  
      // Send dev fee after successful trade
      // await sendDevFee(0.0001);
  
      // Update UI after success
      toast.update(toastId, {
        render: (
          <div>
            <div>Sell successful!</div>
            <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
              <a 
                href={`https://solscan.io/tx/${sellResult.signature}`} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{ color: '#4ade80' }}
              >
                View on Solscan
              </a>
            </div>
          </div>
        ),
        type: "success",
        isLoading: false,
        autoClose: 5000
      });
  
      // Update token states
      setOwnedTokens(prev => ({
        ...prev,
        [coin]: Math.max(0, (prev[coin] || 0) - amount)
      }));
  
      if (ownedTokens[coin] - amount <= 0) {
        const newBoughtTokens = boughtTokens.filter(token => token !== coin);
        setBoughtTokens(newBoughtTokens);
        localStorage.setItem('boughtTokens', JSON.stringify(newBoughtTokens));
      }
  
      return true;
  
    } catch (error) {
      console.error('Error processing sell:', error);
      
      toast.update(toastId, {
        render: (
          <div>
            <div style={{ fontWeight: 500 }}>Sell Failed</div>
            <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
              {error.message}
            </div>
          </div>
        ),
        type: "error",
        isLoading: false,
        autoClose: 5000
      });
      
      return false;
    } finally {
      isProcessing.current = false;
    }
  };
  const handleAutoTPSLSell = async (coin, amount, slippage = 15, priorityFee = 0.001) => {
    if (isProcessing.current) {
      console.log('Transaction already processing');
      return false;
    }

    toast.dismiss();
    let decryptedKey = null;
    
    try {
        isProcessing.current = true;

        // Use the same decryption method as handleBuyRaydium
        decryptedKey = await decryptTradingWalletKey(tradingWallet);

        // Create keypair from decrypted key
        const tradingKeypair = Keypair.fromSecretKey(bs58.decode(decryptedKey));

        // Verify keypair matches public key
        if (tradingKeypair.publicKey.toBase58() !== tradingWallet.walletPublicKey) {
            throw new Error('Key pair verification failed');
        }

        const bundledTxArgs = [{
            publicKey: tradingKeypair.publicKey.toBase58(),
            action: "sell",
            mint: coin,
            denominatedInSol: "true",
            amount: amount,
            slippage: Number(slippage), // Use passed param instead of tradeSettings
            priorityFee: Number(priorityFee), // Use passed param instead of tradeSettings
            pool: "raydium"
        }];

        const response = await fetch(`https://pumpportal.fun/api/trade-local`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bundledTxArgs)
        });

        if (response.status === 200) {
            const transactions = await response.json();
            
            let encodedSignedTransactions = [];
            let signatures = [];

            // Sign each transaction
            for (let i = 0; i < bundledTxArgs.length; i++) {
                const tx = VersionedTransaction.deserialize(
                    new Uint8Array(bs58.decode(transactions[i]))
                );
                tx.sign([tradingKeypair]);
                encodedSignedTransactions.push(bs58.encode(tx.serialize()));
                signatures.push(bs58.encode(tx.signatures[0]));
            }

            // Submit to Jito
            const jitoResponse = await fetch(`https://mainnet.block-engine.jito.wtf/api/v1/bundles`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    jsonrpc: "2.0",
                    id: 1,
                    method: "sendBundle",
                    params: [encodedSignedTransactions]
                })
            });

            if (jitoResponse.ok) {
                toast.success(
                    <div>
                        <div>Sell successful!</div>
                        <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
                            <a 
                                href={`https://solscan.io/tx/${signatures[0]}`} 
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#4ade80' }}
                            >
                                View on Solscan
                            </a>
                        </div>
                    </div>
                );

                return true;
            } else {
                throw new Error('Jito bundle submission failed');
            }
        } else {
            throw new Error(`API error: ${response.statusText}`);
        }
    } catch (error) {
        console.error('Sell error:', error);
        toast.error(`Sell failed: ${error.message}`);
        return false;
    } finally {
        decryptedKey = null;  // Clear decrypted key
        isProcessing.current = false;
    }
};
  
  // Helper function for Pump pool sell
  const attemptPumpSell = async (coin, amount, toastId) => {
    if (!tradingWallet?.apiKey) {
      throw new Error("Trading wallet not properly initialized");
    }
  
    const response = await fetch(`https://pumpportal.fun/api/trade?api-key=${tradingWallet.apiKey}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'sell',
        mint: coin,
        amount: amount,
        denominatedInSol: 'true',
        slippage: 15,
        priorityFee: 0.0005,
        pool: 'pump',
      }),
    });
  
    if (!response.ok) {
      throw new Error(`Pump trade API error: ${response.status}`);
    }
  
    const sellResult = await response.json();
    if (!sellResult.signature) {
      throw new Error(sellResult.errors || 'Pump transaction failed');
    }
  
    toast.update(toastId, {
      render: (
        <div>
          <div>Sell successful on Pump!</div>
          <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
            <a 
              href={`https://solscan.io/tx/${sellResult.signature}`} 
              target="_blank" 
              rel="noopener noreferrer"
              style={{ color: '#4ade80' }}
            >
              View on Solscan
            </a>
          </div>
        </div>
      ),
      type: "success",
      isLoading: false,
      autoClose: 5000
    });
  
    return true;
  };
  
  // Helper function for Raydium pool sell
  const attemptRaydiumSell = async (coin, amount, toastId) => {
    const tradingKeypair = Keypair.fromSecretKey(
      bs58.decode(tradingWallet.privateKey)
    );
  
    // Set up transaction arguments
    const bundledTxArgs = [{
      publicKey: tradingKeypair.publicKey.toBase58(),
      action: "sell",
      mint: coin,
      denominatedInSol: 'true',
      amount: Number(amount).toFixed(4),
      slippage: 15,
      priorityFee: 0.0005,
      pool: "raydium"
    }];
  
    const response = await fetch(`https://pumpportal.fun/api/trade-local`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bundledTxArgs)
    });
  
    if (!response.ok) {
      throw new Error(`Raydium API error: ${response.status}`);
    }
  
    const transactions = await response.json();
    let encodedSignedTransactions = [];
    let signatures = [];
  
    // Sign each transaction
    for (const tx of transactions) {
      const transaction = VersionedTransaction.deserialize(
        new Uint8Array(bs58.decode(tx))
      );
      transaction.sign([tradingKeypair]);
      signatures.push(bs58.encode(transaction.signatures[0]));
      encodedSignedTransactions.push(bs58.encode(transaction.serialize()));
    }
  
    // Send to Jito
    const jitoResponse = await fetch(`https://mainnet.block-engine.jito.wtf/api/v1/bundles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "jsonrpc": "2.0",
        "id": 1,
        "method": "sendBundle",
        "params": [encodedSignedTransactions]
      })
    });
  
    if (!jitoResponse.ok) {
      throw new Error('Failed to submit Raydium transaction bundle');
    }
  
    // Wait for confirmation
    const connection = new Connection('https://api.mainnet-beta.solana.com');
    let confirmed = false;
    let retries = 0;
    const maxRetries = 5;
  
    while (!confirmed && retries < maxRetries) {
      const status = await connection.getSignatureStatus(signatures[0]);
      if (status.value?.confirmationStatus === 'confirmed' || 
          status.value?.confirmationStatus === 'finalized') {
        confirmed = true;
        break;
      }
      retries++;
      await new Promise(resolve => setTimeout(resolve, 2000));
    }
  
    if (!confirmed) {
      throw new Error('Raydium transaction failed to confirm');
    }
  
    toast.update(toastId, {
      render: (
        <div>
          <div>Sell successful on Raydium!</div>
          <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
            <a 
              href={`https://solscan.io/tx/${signatures[0]}`} 
              target="_blank" 
              rel="noopener noreferrer"
              style={{ color: '#4ade80' }}
            >
              View on Solscan
            </a>
          </div>
        </div>
      ),
      type: "success",
      isLoading: false,
      autoClose: 5000
    });
  
    return true;
  };
  
  // Helper function to update token states
  const updateTokenStates = async (coin, amount) => {
    setOwnedTokens(prev => ({
      ...prev,
      [coin]: Math.max(0, (prev[coin] || 0) - amount)
    }));
  
    if (ownedTokens[coin] - amount <= 0) {
      const newBoughtTokens = boughtTokens.filter(token => token !== coin);
      setBoughtTokens(newBoughtTokens);
      localStorage.setItem('boughtTokens', JSON.stringify(newBoughtTokens));
    }
  };
  const originalHandleSellRaydium = async (coin, amount) => {
    if (isProcessing.current) {
        console.log('Transaction already processing');
        return false;
    }

    toast.dismiss();
    
    try {
        isProcessing.current = true;

        // Create keypair from trading wallet's private key
        const tradingKeypair = Keypair.fromSecretKey(
            bs58.decode(tradingWallet.privateKey)
        );

        // Set up transaction arguments exactly as in docs
        const bundledTxArgs = [{
            publicKey: tradingKeypair.publicKey.toBase58(),
            action: "sell",  // Only change from buy implementation
            mint: coin,
            denominatedInSol: "true",
            amount: amount,
            slippage: Number(tradeSettings.slippage),
            priorityFee: Number(tradeSettings.priorityFee),
            pool: "raydium"
        }];

        // First API call to get unsigned transactions
        const response = await fetch(`https://pumpportal.fun/api/trade-local`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(bundledTxArgs)
        });

        if (response.status === 200) {
            const transactions = await response.json();
            console.log('Received transactions:', transactions);

            let encodedSignedTransactions = [];
            let signatures = [];

            // Sign each transaction
            for (let i = 0; i < bundledTxArgs.length; i++) {
                const tx = VersionedTransaction.deserialize(
                    new Uint8Array(bs58.decode(transactions[i]))
                );
                tx.sign([tradingKeypair]);
                encodedSignedTransactions.push(bs58.encode(tx.serialize()));
                signatures.push(bs58.encode(tx.signatures[0]));
            }

            // Send to Jito
            try {
                const jitoResponse = await fetch(`https://mainnet.block-engine.jito.wtf/api/v1/bundles`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "jsonrpc": "2.0",
                        "id": 1,
                        "method": "sendBundle",
                        "params": [
                            encodedSignedTransactions
                        ]
                    })
                });

                console.log('Jito response:', jitoResponse);

                if (jitoResponse.ok) {
                    // Update UI with success
                    toast.success(
                        <div>
                            <div>Sell successful!</div>
                            <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
                                <a 
                                    href={`https://solscan.io/tx/${signatures[0]}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    style={{ color: '#4ade80' }}
                                >
                                    View on Solscan
                                </a>
                            </div>
                        </div>
                    );

                    // Log each transaction
                    for (let i = 0; i < signatures.length; i++) {
                        console.log(`Transaction ${i}: https://solscan.io/tx/${signatures[i]}`);
                    }

                    return true;
                } else {
                    throw new Error('Jito bundle submission failed');
                }
            } catch (e) {
                console.error('Jito error:', e.message);
                throw e;
            }
        } else {
            console.error('API error:', response.statusText);
            throw new Error(`API error: ${response.statusText}`);
        }
    } catch (error) {
        console.error('Sell error:', error);
        toast.error(`Sell failed: ${error.message}`);
        return false;
    } finally {
        isProcessing.current = false;
    }
};
const handleSellRaydium = async (coin, amount) => {
  try {
    const result = await originalHandleSellRaydium(coin, amount);
    if (result) {
      await refreshBalancesCallback('sellRaydium');
    }
    return result;
  } catch (error) {
    throw error;
  }
};


  const handleBuyAmountChange = (value) => {
    setBuyAmount(value);
    localStorage.setItem('buyAmount', value);
    if (value === 'custom') {
      setCustomAmount('');
      localStorage.removeItem('customAmount');
    }
  };
  const handleCustomAmountChange = (value) => {
    setCustomAmount(value);
    localStorage.setItem('customAmount', value);
  };
  const fetchTokenData = async (mintAddress) => {
    if (tokenDataCache[mintAddress] && Date.now() - tokenDataCache[mintAddress].timestamp < 300000) {
      return tokenDataCache[mintAddress].data;
    }
    return enhancedRequestHandler.makeRequest(
      `token-data-${mintAddress}`,
      async () => {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/token-data/${mintAddress}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data.data;
      }
    );
  };
  const manualFetchTokens = () => {
    console.log("Manual fetch triggered");
    fetchNewTokens();
  };
  const handleScroll = useCallback((event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      if (!isLoading && hasMore) {
        fetchNewTokens(page + 1);
      }
    }
  }, [isLoading, hasMore, page]);
  const calculateFeeAmount = (tradeAmount) => {
    const fee = tradeAmount * FEE_PERCENTAGE;
    return {
      feeAmount: fee,
      effectiveAmount: tradeAmount - fee
    };
  };
  const storePurchaseInfo = async (mintAddress, amount) => {
    try {
      console.debug('Storing token purchase info:', { mintAddress, amount });
      
      // Fetch current market cap
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${mintAddress}`);
      const data = await response.json();
      
      if (!data.success) {
        console.error('Failed to fetch token data for purchase tracking');
        return;
      }
  
      const purchaseInfo = {
        tokenMint: mintAddress,
        purchaseMarketCap: data.data.marketCap,
        purchaseTimestamp: new Date(),
        amount: amount
      };
  
      // Store in database
      await fetch(`${process.env.REACT_APP_API_URL}api/store-purchase`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          walletAddress: publicKey.toString(),
          purchaseInfo
        })
      });
  
      console.debug('Successfully stored purchase info:', purchaseInfo);
    } catch (error) {
      console.error('Error storing purchase info:', error);
    }
  };

  // Add effect to monitor state changes
  useEffect(() => {
    console.log('showDemoModal state changed:', showDemoModal);
  }, [showDemoModal]);

  useEffect(() => {
    // Define the handleBuy function that will be passed to components
    const handleBuy = async (params) => {
      // Extract parameters
      const { mint, amount, slippage = 15, priorityFee = 0.001 } = params;
      
      console.log('handleBuy called with:', { mint, amount, slippage, priorityFee });
      
      // Call the original buy function with the correct parameters
      return await originalHandleBuy(mint, amount, slippage, priorityFee);
    };
    
    // Make this function available to the component
    window.handleBuy = handleBuy;
    
    // ... rest of effect
  }, []);

  return (
    <div className="App">
      {!phaseOneActive ? (
        <>
          <MaintenanceBanner />
          <AuthFlow 
            onComplete={handleAuthComplete} 
            hasRequiredTokens={hasRequiredTokens} 
            setTradingWallet={setTradingWallet} 
            tradingWallet={tradingWallet} 
            fetchUserData={fetchUserData} 
            handleBuy={handleBuy} 
            handleSell={handleSell} 
            ownedTokens={ownedTokens} 
            wallet={wallet} 
            setWallet={setWallet} 
            walletConnected={walletConnected} 
            connected={connected}
            sendDevFee={sendDevFee} 
            points={points}
            setPoints={setPoints}
            completedTasks={completedTasks}
            setCompletedTasks={setCompletedTasks} 
            setTradeSettings={setTradeSettings} 
            tradeSettings={tradeSettings} 
            setTokenPurchaseHistory={setTokenPurchaseHistory} 
            tokenPurchaseHistory={tokenPurchaseHistory} 
            handleAutoTPSLSell={handleAutoTPSLSell} 
            handleBuyRaydium={handleBuyRaydium} 
            getPrivateKeyForPublicKey={getPrivateKeyForPublicKey}
            showDemoModal={showDemoModal}
            setShowDemoModal={setShowDemoModal}
          />
        </>
      ) : (
        <>
          <img src={logotp} alt="ApeOut Logo" className="app-logo" />
          <WalletMultiButton />
          
          {tradingWallet && tradingWallet.publicKey && (
            <div className="trading-wallet-info">
              Trading Wallet: {tradingWallet.publicKey.slice(0, 4)}...{tradingWallet.publicKey.slice(-4)}
            </div>
          )}
          
          {latestMintAddress && (
            <div className="latest-mint-address">
              Latest Mint Address: {latestMintAddress}
            </div>
          )}
          
          <div className="disclaimer">
            ⚠️ Beta version. Trading involves high risk. Use at your own discretion.
          </div>
          
          <button className="clear-tokens-button" onClick={clearTokens}>
            Clear Tokens
          </button>
          
          <button className="fetch-tokens-button" onClick={manualFetchTokens}>
            Fetch Tokens
          </button>
          
          <CoinSwiper 
            coins={coins}
            onFavorite={toggleFavorite} 
            onBuy={handleBuy}
            favorites={favorites}
            onScroll={handleScroll}
            onSell={handleSell}
            key={coins.map(coin => coin.mint).join(',')}
            updateTokenData={updateTokenData}
          />  
          
          <div className="scroll-indicator">
            <span className="arrow">⬇️</span>
            <p>Swipe for more coins</p>
          </div>
          
          <div className="auto-snipe-container">
            <button 
              className={`auto-snipe-button ${autoSnipeEnabled ? 'enabled' : ''}`}
              onClick={async () => {
                if (!ownedTokens['DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump']) {
                  document.querySelector('.auto-snipe-button').classList.add('shake');
                  setTimeout(() => {
                    document.querySelector('.auto-snipe-button').classList.remove('shake');
                  }, 500);
                  toast.warning("You must own ApeOut Tokens such as $BNNA to Turn On Auto APE!");
                } else {
                  setAutoSnipeEnabled(prev => !prev);
                }
              }}
            >
              🤖🦍
              {autoSnipeEnabled ? 'Turn Auto APE OFF' : 'Turn Auto APE ON'}
            </button>
          </div>
          
          <div className="button-row">
            <button 
              className="settings-button"
              onClick={() => setShowSettings(true)}
            >
              ⚙️
            </button>
            <button 
              className="favorites-button"
              onClick={() => setShowFavorites(true)}
            >
              ❤️
            </button>
          </div>
          
          {showSettings && (
            <SettingsModal 
              onClose={() => setShowSettings(false)}
              buyAmount={buyAmount}
              setBuyAmount={handleBuyAmountChange}
              customAmount={customAmount}
              setCustomAmount={handleCustomAmountChange}
              tradingWallet={tradingWallet}
              sendFunds={sendFunds}
              wallet={wallet}
            />
          )}
          
          {showFavorites && (
            <FavoritesModal 
              onClose={() => setShowFavorites(false)}
              favorites={favorites}
              onBuy={handleBuy}
              onSell={handleSell}
              ownedTokens={ownedTokens}
              ownedTokensData={ownedTokensData}
              onRemoveFavorite={removeFavorite}
              boughtTokens={boughtTokens}
              wallet={wallet}
              isWalletConnected={isWalletConnected}
              fetchOwnedTokens={fetchUserData}
            />
          )}
          
          <DemoAutoApeModal
            open={showDemoModal}
            onClose={() => {
              console.log('Closing modal from App.js'); 
              setShowDemoModal(false);
            }}
            tradingWallet={tradingWallet}
            handleBuy={handleBuy}
            handleSell={handleSell}
            ownedTokensData={ownedTokens}
            setTradeSettings={(settings) => {
              setTradeSettings(prev => ({
                ...prev,
                ...settings
              }));
            }}
            tradeSettings={tradeSettings}
            tokenPurchases={tokenPurchaseHistory}
            publicKey={publicKey}
            isDemoMode={isDemoMode}
            setIsDemoMode={setIsDemoMode}
          />
          
          <ToastContainer position="bottom-right" />
        </>
      )}
    </div>
  );
};

App.propTypes = {
  render: PropTypes.func
};
App.defaultProps = {
  render: null
};
const AppWrapper = () => {
  const network = 'https://mainnet.helius-rpc.com/?api-key=03227d28-b6de-4a36-9d90-cd0cc7c2f8eb' || clusterApiUrl('mainnet-beta');
  const wallets = useMemo(() => [new PhantomWalletAdapter(), new SolflareWalletAdapter()], []);
  return (
    <Router basename="/">
    <ConnectionProvider endpoint={network}>
    <WalletProvider wallets={wallets} autoConnect>
      <WalletModalProvider>
      <Routes>
          <Route path="/app" element={<App />} />
          <Route path="/pump-calculator" element={<GainsCalculator />} />
          <Route path="/banana-stand" element={<BananaStandDashboard />} />
          <Route path="/burn" element={<TokenBurnTracker />} />
          <Route path="/ai-agents" element={<AIAgentDashboardWrapper 
          />} />
          <Route path="/auto-tpsl" element={<AutoTPSLPage />} />
            <Route path="/pawchain" element={<PawChainPage />} />
            <Route path="/cronos" element={<CronosDashboard />} />
          <Route path="/trending-tokens" element={<TrendingTokensPage />} />
          <Route path="/video" element={<VideoPlayer src={wojakVideo}className="custom-video-class" />} />
          <Route path="/video2" element={<VideoPlayer src={kodakVideo}className="custom-video-class" />} />
          <Route path="/wheel" element={
  <TokenWheel />} />
  <Route path="/alpha-chat" element={<BalanceGatedPage />} />
  <Route path="/marketplace" element={<Marketplace />} />
  <Route path="/roadmap" element={<Roadmap />} />
  <Route path="/bounties" element={<Bounties />} />
  <Route path="/giveaway" element={<GiveawayPage />} />
  <Route path="/api-access" element={<APIAccessPage />} />

<Route 
  path="/admin/whitelist" 
  element={<AdminWhitelist />}
/>
<Route path="/launchpad" element={<LaunchpadPage />} />
<Route path="/apemind" element={<ApeMindDashboard />} />
<Route path="/" element={<LandingPage />} />

<Route 
                path="/agents" 
                element={
                  <App 
                    render={props => (
                      <AIAgentDashboardWrapper
                        {...props}
                        handleBuy={props.handleBuy}
                        handleSell={props.handleSell}
                      />
                    )}
                  />
                }
              />
              <Route path="/xrp" element={<XRPTeaser />} />
<Route path="/bsc" element={<BSCDashboard />} /> {/* Add this line */}
<Route path="/test-agents" element={<AIAgentTester />} />
<Route path="/lootbox" element={<LootBoxTeaser />} />
<Route path="/gated-content" element={<GatedContent />} />
<Route path="/order-lookup" element={<OrderLookupPage />} />
<Route path="/tier-structure" element={<TierStructure />} />
<Route path="/api-access/complete" element={<APIAccessCompletePage />} />

<Route 
  path="/ape-guide" 
  element={
    <Box sx={{ 
      background: 'linear-gradient(to bottom, #111827, #000000)',
      minHeight: '100vh',
      py: 4
    }}>
      <ApeGuide />
    </Box>
  } 
/>
<Route path="/claim" element={<Claim />} />
<Route path="/terms-of-use" element={<TermsOfUse />} />
<Route path="/terms-fire-sale" element={<TermsFireSale />} />
<Route path="/terms-airdrop" element={<TermsAirdrop />} />
<Route path="/token-holders" element={<TokenHolders />} />
<Route path="/hardware-projects" element={<AIHardwareProjects />} />
<Route path="/governance" element={<Governance />} />
        </Routes>
      </WalletModalProvider>
    </WalletProvider>
  </ConnectionProvider>
  </Router>
  );
};

export default AppWrapper;


