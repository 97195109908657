// src/pages/GiveawayPage.js
import React from 'react';
import { 
  Card, 
  CardHeader, 
  CardContent, 
  CardMedia,
  Typography, 
  Grid, 
  Container, 
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';
import { Divider, Button } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupIcon from '@mui/icons-material/Group';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Receipt from '@mui/icons-material/Receipt';
import Book from '@mui/icons-material/Book';
import { FaTiktok } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';


const FooterLink = styled('a')(({ theme }) => ({
    color: 'rgba(255, 255, 255, 0.8)',
    textDecoration: 'none',
    fontSize: '0.875rem',
    padding: theme.spacing(0, 1),
    transition: 'all 0.3s ease',
    '&:hover': {
      color: 'white',
      transform: 'translateY(-2px)',
    }
  }));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
    color: 'white',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-3px)',
      color: '#3b82f6',
    },
  }));

const SocialFooter = styled(Box)(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(2),
    background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0.7))',
    backdropFilter: 'blur(10px)',
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(3),
    zIndex: 1000,
  }));

const InstructionCard = styled(Card)(({ theme }) => ({
    background: 'linear-gradient(135deg, #1b5e20 0%, #2e7d32 100%)',
    border: '2px solid #ffd700',
    marginBottom: theme.spacing(6),
    padding: theme.spacing(3),
  }));

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(135deg, #1b5e20 0%, #2e7d32 100%)',
  border: '2px solid #ffd700',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-8px)',
    border: '2px solid #ffe57f',
    boxShadow: theme.shadows[15],
  },
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  '& .MuiCardHeader-title': {
    color: '#ffd700',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
}));

const MilestoneBox = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 215, 0, 0.1)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const GiveawayPage = () => {
  const gadgets = [
    {
      name: "L Ring 2",
      description: "A revolutionary ring that translates speech in real-time and controls various gadgets with gestures",
      milestone: "5,000 holders",
      imageUrl: "https://i.kickstarter.com/assets/046/836/009/a97fda593302ed89ffd3d637e212525b_original.jpg?anim=false&fit=cover&gravity=auto&height=576&origin=ugc&q=92&v=1728628268&width=1024&sig=eb2BcFICMqBOyPKYbuhsGFzgqOurAMnaXBBGN4HxUbU%3D",
      features: ["Real-time translation", "Gesture control", "10-hour battery life", "14 extra charges"]
    },
    {
      name: "Kimto Lens",
      description: "Professional 12x zoom lens with 400mm focal length and f/1.8 aperture",
      milestone: "10,000 holders",
      imageUrl: "https://i.kickstarter.com/assets/047/259/060/50e7f7cdb87583ebc39c4568e62007e0_original.jpg?anim=false&fit=cover&gravity=auto&height=873&origin=ugc&q=92&v=1731492586&width=1552&sig=OyJm3jDSqozcqzwcLYXPITveFfbrP2eaK3GPZI0dpKg%3D",
      features: ["12x zoom", "400mm focal length", "f/1.8 aperture", "Universal phone compatibility"]
    },
    // {
    //     name: "Mi Belly Belt",
    //     description: "A belt that adjusts precisely to 0.6mm with just a touch or even automatically. It lasts over 2 weeks on a full battery and comes in different styles.",
    //     milestone: "15,000 holders",
    //     imageUrl: "",
    //     features: [""]
    //   },
    //   {
    //     name: "Measured Scale",
    //     description: "A kitchen scale that is marketed as the most advanced in the world. It has an advanced temperature sensor and is designed to be versatile enough for mixology and coffee. It also has a companion app that is loaded with smart functions.",
    //     milestone: "20,000 holders",
    //     imageUrl: "",
    //     features: []
    //   },
      {
        name: "Greater Rain Grater",
        description: "The world's first electric grater that activates with just one touch. It has three grating options: fine, coarse, and elegant shavings. It can also handle chocolate and other ingredients.",
        milestone: "15,000 holders",
        imageUrl: "https://www.yankodesign.com/images/design_news/2024/06/this-tiny-electric-grater-lets-you-rain-heaps-of-parmesan-cheese-over-your-pasta-and-salads/Worlds_First_One_Touch_Electric_Cheese_Grater_07.jpg",
        features: []
      },
      {
        name: "Dey Smart Pill Bottle",
        description: "A smart pill bottle that uses lights, sounds, and notifications to remind you to take your medication. It also sends you a call on your smartphone if you open the bottle within a minute of the reminder.",
        milestone: "20,000 holders",
        imageUrl: "https://mms.businesswire.com/media/20170502005356/en/584067/5/pillsy-combo-pack.jpg",
        features: []
      },
      {
        name: "Sparus U3 Flashlight",
        description: "A flashlight that offers a classic white light with up to 700 lumens of brightness, a green laser pointer, red lighting, a UV lamp, and a red, white, and blue emergency signal. It is lightweight and has an 850mAh battery that can last up to 53 hours.",
        milestone: "25,000 holders",
        imageUrl: "https://thegadgetrend.com/wp-content/uploads/2024/11/SPERAS-U3-Versatile-EDC-Flashlight-for-All-Your-Light-Needs-2.jpg",
        features: []
      },
  ];

  return (
    <Box sx={{
      minHeight: '100vh',
      background: 'linear-gradient(180deg, #1a472a 0%, #2e5a35 50%, #78350f 100%)',
      padding: 4
    }}>
      <Container maxWidth="xl">
        <Typography 
          variant="h2" 
          component="h1" 
          align="center" 
          sx={{ 
            color: '#ffd700',
            mb: 4,
            fontWeight: 'bold',
            '& span': { fontSize: '3rem' }
          }}
        >
          <span>🌴</span> Jungle Rewards 2025 <span>🌴</span>
        </Typography>

        <Typography 
          variant="h5" 
          align="center" 
          sx={{ 
            color: '#ffe57f',
            mb: 6 
          }}
        >
          Join our community and win these amazing gadgets when we hit our milestones!
        </Typography>
        

        <Grid container spacing={4}>
          {gadgets.map((gadget, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <StyledCard>
                <StyledCardHeader title={gadget.name} />
                <CardMedia
                  component="img"
                  height="200"
                  image={gadget.imageUrl}
                  alt={gadget.name}
                  sx={{ objectFit: 'cover' }}
                />
                <CardContent>
                  <Typography sx={{ color: '#a5d6a7', mb: 2 }}>
                    {gadget.description}
                  </Typography>
                  
                  <MilestoneBox>
                    <Typography variant="h6" sx={{ color: '#ffd700', mb: 1 }}>
                      Milestone Target:
                    </Typography>
                    <Typography sx={{ color: '#ffe57f' }}>
                      {gadget.milestone}
                    </Typography>
                  </MilestoneBox>

                  <List>
                    {gadget.features.map((feature, idx) => (
                      <ListItem key={idx} sx={{ padding: 0.5 }}>
                        <ListItemIcon sx={{ minWidth: 30 }}>
                          <CircleIcon sx={{ color: '#ffd700', fontSize: 8 }} />
                        </ListItemIcon>
                        <ListItemText 
                          primary={feature} 
                          sx={{ '& .MuiListItemText-primary': { color: '#a5d6a7' } }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
        
      </Container>
      <InstructionCard>
  <Typography variant="h4" sx={{ color: '#ffd700', mb: 3, textAlign: 'center' }}>
    How to Enter 🎯
  </Typography>
  
  <Grid container spacing={4} sx={{ mb: 3 }}>
    <Grid item xs={12} md={4}>
      <Box sx={{ textAlign: 'center', color: '#a5d6a7' }}>
        <GroupIcon sx={{ fontSize: 40, color: '#ffd700', mb: 1 }} />
        <Typography variant="h6" sx={{ color: '#ffe57f', mb: 1 }}>
          Step 1: Join Community
        </Typography>
        <Typography>
          Hold required amount of tokens to qualify for specific milestone giveaways - At Least 1M $BNNA
        </Typography>
      </Box>
    </Grid>
    
    <Grid item xs={12} md={4}>
      <Box sx={{ textAlign: 'center', color: '#a5d6a7' }}>
        <TaskAltIcon sx={{ fontSize: 40, color: '#ffd700', mb: 1 }} />
        <Typography variant="h6" sx={{ color: '#ffe57f', mb: 1 }}>
          Step 2: Complete Tasks
        </Typography>
        <Typography>
          Participate in community events and complete social media tasks
        </Typography>
      </Box>
    </Grid>
    
    <Grid item xs={12} md={4}>
      <Box sx={{ textAlign: 'center', color: '#a5d6a7' }}>
        <EmojiEventsIcon sx={{ fontSize: 40, color: '#ffd700', mb: 1 }} />
        <Typography variant="h6" sx={{ color: '#ffe57f', mb: 1 }}>
          Step 3: Win Prizes
        </Typography>
        <Typography>
          Winners randomly selected from qualified participants
        </Typography>
      </Box>
    </Grid>
  </Grid>
  
  <Divider sx={{ 
    bgcolor: 'rgba(255, 215, 0, 0.3)', 
    my: 3 
  }} />
  
  <Box sx={{ textAlign: 'center' }}>
    <Typography variant="h6" sx={{ color: '#ffe57f', mb: 2 }}>
      Important Notes:
    </Typography>
    <Typography sx={{ color: '#a5d6a7', mb: 2 }}>
      • Must maintain token holding until giveaway completion<br />
      • One entry per wallet address<br />
      • Winners will be announced on our social media channels<br />
      • Prizes will be shipped worldwide<br />
      • Staking boosts your chances of winning

    </Typography>
    <Button 
      variant="contained" 
      sx={{ 
        bgcolor: '#ffd700', 
        color: '#1b5e20',
        '&:hover': {
          bgcolor: '#ffe57f',
        },
        mt: 2
      }}
      href="https://gleam.io/usiPG/free-gape-airdrop-giveaway
" 
      target="_blank"
    >
      Enter Giveaway
    </Button>
  </Box>
</InstructionCard>
{/* <SocialFooter>
  <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}>
    <SocialIconButton href="https://x.com/apeoutmeme" target="_blank" aria-label="Twitter">
      <TwitterIcon />
    </SocialIconButton>
    <SocialIconButton href="https://t.me/apeoutmeme" target="_blank" aria-label="Telegram">
      <TelegramIcon />
    </SocialIconButton>
    <SocialIconButton href="https://github.com/apeoutmeme/apeoutdotfun-" target="_blank" aria-label="GitHub">
      <GitHubIcon />
    </SocialIconButton>
    <SocialIconButton href="https://www.youtube.com/shorts/f4OeszM6ukE" target="_blank" aria-label="YouTube">
      <YouTubeIcon />
    </SocialIconButton>
    <SocialIconButton href="https://apeout.meme/whitepaper" target="_blank" aria-label="Whitepaper">
      <Receipt />
    </SocialIconButton>
    <SocialIconButton href="https://medium.com/@apeoutcrypto" target="_blank" aria-label="Medium">
      <Book />
    </SocialIconButton>
    <SocialIconButton href="https://tiktok.com/@apeoutmeme" target="_blank" aria-label="TikTok">
      <FaTiktok />
    </SocialIconButton>
  </Box>
  <Box 
    sx={{ 
      display: 'flex', 
      gap: 3, 
      justifyContent: 'center',
      flexWrap: 'wrap',
      px: 2
    }}
  >
    <FooterLink 
      href="https://apeout.notion.site/16595c4b66bd80a8866fca86f11d9ee2?v=16595c4b66bd809fab47000c93c737ee&p=17695c4b66bd80f08c03c76b869f54e9&pm=s" 
      target="_blank"
      rel="noopener noreferrer"
    >
      Press Releases
    </FooterLink>
    <FooterLink 
      href="/terms-of-use" 
      target="_blank"
      rel="noopener noreferrer"
    >
      Terms of Service
    </FooterLink>
    <FooterLink 
      href="/bounties" 
      target="_blank"
      rel="noopener noreferrer"
    >
      Bounties
    </FooterLink>
    <FooterLink 
      href="https://apeout.notion.site/16595c4b66bd80a8866fca86f11d9ee2" 
      target="_blank"
      rel="noopener noreferrer"
    >
      Documentation
    </FooterLink>
    <FooterLink 
      href="/roadmap" 
      target="_blank"
      rel="noopener noreferrer"
    >
      Roadmap
    </FooterLink>
    <FooterLink 
      href="/tier-structure" 
      target="_blank"
      rel="noopener noreferrer"
    >
      Tiers
    </FooterLink>
  </Box>
</SocialFooter> */}

    </Box>
    
  );
};

export default GiveawayPage;