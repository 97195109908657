const balanceCache = {
    data: new Map(),
    timestamp: new Map(),
    TTL: 30000,
  };
  
const CACHE_TTL = 30000; // 30 seconds

export const checkBalance = async (publicKey, tradingWallet) => {
  const now = Date.now();
  const cacheKey = `${publicKey}_${tradingWallet?.walletPublicKey || ''}`;
  
  // Check cache first
  const cachedData = balanceCache.get(cacheKey);
  if (cachedData && now - cachedData.timestamp < CACHE_TTL) {
    return cachedData.data;
  }

  // Make API request if cache miss
  try {
    const [solResponse, bnnaResponse] = await Promise.all([
      fetch(`${process.env.REACT_APP_API_URL}api/sol-balance/${tradingWallet?.walletPublicKey}`),
      fetch(`${process.env.REACT_APP_API_URL}api/token-balance/${tradingWallet?.walletPublicKey}/DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump`)
    ]);

    const [solData, bnnaData] = await Promise.all([
      solResponse.json(),
      bnnaResponse.json()
    ]);

    const result = {
      solBalance: solData.balance,
      bnnaBalance: bnnaData.success ? bnnaData.balance : 0
    };

    // Update cache
    balanceCache.set(cacheKey, {
      data: result,
      timestamp: now
    });

    return result;
  } catch (error) {
    console.error('Balance check failed:', error);
    throw error;
  }
};