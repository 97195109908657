import { styled, keyframes } from '@mui/material/styles';
import { Box, Paper, Button, IconButton, Link } from '@mui/material';
import { FaTiktok } from 'react-icons/fa';


const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

// Animation keyframes
const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

// Base styled components
export const StyledSection = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(8, 0),
  position: 'relative',
  background: '#121212',
  overflow: 'hidden',
}));

export const StyledCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    background: 'rgba(255, 255, 255, 0.08)',
  }
}));

// In your styles file, replace the current GlowButton with:
export const GlowButton = styled(Button)(({ theme }) => ({
    position: 'relative',
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    transition: 'all 0.3s ease-in-out',
    cursor: 'pointer', // Add this
    pointerEvents: 'auto', // Add this
    textDecoration: 'none', // Add this
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 6px 10px 4px rgba(255, 105, 135, .4)',
      background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
      textDecoration: 'none', // Add this
    },
    '&:active': {
      transform: 'translateY(1px)',
    },
    '&.outlined': {
      background: 'transparent',
      border: '2px solid #FE6B8B',
      '&:hover': {
        background: 'rgba(255, 105, 135, 0.1)',
      }
    },
    // Add this section for link behavior
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    }
  }));

export const FloatingIcon = styled(Box)`
  animation: ${float} 6s ease-in-out infinite;
  & svg {
    font-size: 48px;
    color: white;
  }
`;

export const SocialButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-3px) scale(1.1)',
    color: 'white',
  }
}));

export const PulsingCard = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'all 0.3s ease-in-out',
}));


export const RotatingBackground = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, transparent 70%)',
  animation: `${rotate} 10s linear infinite`,
}));

export const SocialFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(4),
}));

export const FooterLink = styled(Link)(({ theme }) => ({
  color: 'white',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  }
}));


export const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-3px) scale(1.1)',
    color: 'white',
  }
}));

export const GlowingButton = styled(Button)({
    backgroundColor: "#C59E57", // Gold
    color: "#322E29", // Dark brown text for contrast
    border: "2px solid #FEFFEB", // Light Beige Border
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: "18px",
    padding: "12px 24px",
    transition: "all 0.3s ease-in-out",
    boxShadow: "0px 4px 10px rgba(197, 158, 87, 0.8)",
  
    "&:hover": {
      backgroundColor: "#FEFFEB", // Light Beige Hover
      color: "#322E29",
      border: "2px solid #C59E57", // Gold Border
      boxShadow: "0px 6px 14px rgba(197, 158, 87, 1)",
    },
  
    "&:active": {
      transform: "scale(0.95)",
      boxShadow: "0px 2px 6px rgba(197, 158, 87, 0.5)",
    },
  });