import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Casino,
  Psychology,
  School,
  Rocket,
  Stars,
  AutoAwesome,
} from '@mui/icons-material';
import lootboxImage from '../assets/lootbox.webp'
import logo from '../assets/logo-tp.png';  // Adjust path as needed
import { Home } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import LootBoxOpener from '../components/features/LootBoxOpener';
import idleVideo from '../assets/lootboxes.mp4';
import openVideo from '../assets/lootbox-open.mp4';
import { LockOpen } from '@mui/icons-material';
import { Telegram } from '@mui/icons-material';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FaTiktok } from 'react-icons/fa';
import { Receipt } from '@mui/icons-material';
import { VideogameAsset } from '@mui/icons-material';
import { Book } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import openVideoChristmas from '../assets/christmas_lootbox_open.mp4';
import idleVideoChristmas from '../assets/Christmas-lootbox-idle.mp4';
import { keyframes } from '@emotion/react';
import LootboxDisplay from '../components/features/lootbox/LootboxDisplay';


// Add after the existing keyframes and before styled components
const float = keyframes`
  0%, 100% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
`;

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
`;

// Add this with your other keyframes (float, spin, bounce)
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const SocialFooter = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0.7))',
  backdropFilter: 'blur(10px)',
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(3),
  zIndex: 1000,
}));
const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    transform: 'translateY(-5px) scale(1.1)',
    color: '#FFD700',
    boxShadow: '0 5px 15px rgba(255, 215, 0, 0.3)',
  },
}));

// Styled components
const HeroSection = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, #322E29 0%, #9B7940 100%)`,
  padding: theme.spacing(15, 2),
  textAlign: 'center',
  borderRadius: '0 0 50px 50px',
  marginBottom: theme.spacing(8),
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at top right, rgba(197, 158, 87, 0.3), transparent 70%)`,
    borderRadius: 'inherit',
  }
}));

const GlowingTitle = styled(Typography)(({ theme }) => ({
  color: '#FEFFEB', // Light beige
  textShadow: '0 0 20px rgba(197, 158, 87, 0.4)', // Gold glow
  fontWeight: 900,
  marginBottom: theme.spacing(2),
  animation: 'glow 2s ease-in-out infinite alternate',
  '@keyframes glow': {
    from: {
      textShadow: '0 0 20px rgba(197, 158, 87, 0.4)',
    },
    to: {
      textShadow: `0 0 30px rgba(197, 158, 87, 0.7), 
                   0 0 40px rgba(155, 121, 64, 0.3)`, // Using darker gold for depth
    },
  },
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-10px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100px',
    height: '3px',
    background: 'linear-gradient(90deg, transparent, #FFD700, transparent)',
  }
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  background: 'rgba(50, 46, 41, 0.95)', // Dark beige background
  backdropFilter: 'blur(10px)',
  border: '1px solid #C59E57', // Gold border
  transform: 'translateY(0)',
  transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
  boxShadow: '0 4px 15px rgba(197, 158, 87, 0.1)',
  '&:hover': {
    transform: 'translateY(-15px) scale(1.02)',
    boxShadow: '0 12px 35px rgba(197, 158, 87, 0.3)',
    '& .MuiSvgIcon-root': {
      transform: 'scale(1.2)',
      color: '#FFD700',
    }
  },
  '& .MuiSvgIcon-root': {
    transition: 'all 0.3s ease',
    fontSize: '3rem',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(145deg, rgba(197, 158, 87, 0.1), transparent)',
    borderRadius: 'inherit',
    pointerEvents: 'none',
  }
}));

const LootBoxImage = styled('img')({
  width: '100%',
  maxWidth: '400px',
  height: 'auto',
  margin: '2rem auto',
  animation: 'float 3s ease-in-out infinite',
  '@keyframes float': {
    '0%, 100%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-20px)' },
  },
});

const LogoImage = styled('img')({
  width: '120px',
  height: 'auto',
  marginBottom: '1rem',
  animation: 'float 3s ease-in-out infinite',
  filter: 'drop-shadow(0 0 10px rgba(197, 158, 87, 0.3))', // Gold glow
  '@keyframes float': {
    '0%, 100%': { 
      transform: 'translateY(0)',
      filter: 'drop-shadow(0 0 10px rgba(197, 158, 87, 0.3))'
    },
    '50%': { 
      transform: 'translateY(-10px)',
      filter: 'drop-shadow(0 5px 15px rgba(197, 158, 87, 0.4))'
    },
  },
});

const NavContainer = styled(Box)({
  position: 'absolute',
  top: '20px',
  left: '20px',
  zIndex: 10,
  padding: '10px',
  backdropFilter: 'blur(8px)',
  background: 'rgba(50, 46, 41, 0.3)', // Dark beige with transparency
  borderRadius: '12px',
  border: '1px solid rgba(197, 158, 87, 0.2)', // Subtle gold border
});

const OpenLootBoxButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(-45deg, 
    #C59E57, 
    #9B7940, 
    #423D36, 
    #322E29
  )`,
  backgroundSize: '400% 400%',
  animation: 'gradient 15s ease infinite, pulse 2s infinite',
  border: '2px solid #C59E57', // Gold border
  borderRadius: '50px',
  padding: '15px 40px',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  color: '#FEFFEB', // Light beige text
  textTransform: 'none',
  boxShadow: '0 0 20px rgba(197, 158, 87, 0.2)', // Gold shadow
  transition: 'all 0.3s ease-in-out',
  position: 'relative',
  overflow: 'hidden',

  '&:hover': {
    transform: 'translateY(-3px) scale(1.05)',
    boxShadow: '0 10px 30px rgba(197, 158, 87, 0.3)',
    borderColor: '#9B7940', // Darker gold on hover
    animation: `${pulse} 1s ease-in-out infinite`,
    '&:before': {
      transform: 'translateX(100%)',
    }
  },

  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '-100%',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(120deg, transparent, rgba(254, 255, 235, 0.2), transparent)', // Light beige shine
    transition: 'all 0.5s ease',
  },

  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%'
    },
    '50%': {
      backgroundPosition: '100% 50%'
    },
    '100%': {
      backgroundPosition: '0% 50%'
    }
  },

  '@keyframes pulse': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(197, 158, 87, 0.4)', // Gold pulse
      transform: 'scale(1)'
    },
    '70%': {
      boxShadow: '0 0 0 15px rgba(197, 158, 87, 0)',
      transform: 'scale(1.05)'
    },
    '100%': {
      boxShadow: '0 0 0 0 rgba(197, 158, 87, 0)',
      transform: 'scale(1)'
    }
  }
}));

const LootBoxTeaser = () => {
    const [isNavigating, setIsNavigating] = useState(false);
    const [showLootBox, setShowLootBox] = useState(false);
    const [lastOpenedItem, setLastOpenedItem] = useState(null); // Add this if you want to show the received item
    const [isChristmas, setIsChristmas] = useState(false);
    const [showChristmas, setShowChristmas] = useState(false);
    const { publicKey } = useWallet();
    const [lootboxData, setLootboxData] = useState(null);

    const handleOpenComplete = (item) => {
      // Handle what happens after opening animation
      console.log('Loot box opened!', item);
      setLastOpenedItem(item);
      setLootboxData(prev => ({
        ...prev,
        lootboxes: {
          ...prev?.lootboxes,
          quantity: prev?.lootboxes?.quantity - 1,
          lastOpened: new Date().toISOString()
        }
      }));
  };
  // Create a navigation handler
  const handleHomeNavigation = () => {
    setIsNavigating(true);
    window.location.href = '/'; // This will force a full page refresh
  };
  const features = [
    {
      title: 'Mystery Loot Boxes',
      description: 'Unlock exclusive trading tools, bot features, and special rewards through our mystery loot box system.',
      icon: <Casino fontSize="large" sx={{ 
        animation: `${float} 3s ease-in-out infinite`,
        color: '#FFD700'
      }} />,
      tags: ['Trading Tools', 'Bot Features', 'Exclusive Rewards'],
    },
    {
      title: 'Bot Customization',
      description: 'Customize your trading bot with unique components and strategies found in loot boxes.',
      icon: <Psychology fontSize="large" />,
      tags: ['Strategy Modules', 'Performance Boosts', 'Custom Algorithms'],
    },
    {
      title: 'Learn & Earn',
      description: 'Unlock educational content and earn rewards while mastering automated trading strategies.',
      icon: <School fontSize="large" />,
      tags: ['Mini-Courses', 'Trading Tips', 'Rewards'],
    },
  ];

  return (
    <Box sx={{ bgcolor: '#1a1a1a', minHeight: '100vh', color: 'white' }}>
        <NavContainer>
      <Button
        onClick={handleHomeNavigation}
        disabled={isNavigating}
        variant="contained"
        startIcon={<Home />}
        sx={{
          background: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(10px)',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.2)',
          },
        }}
      >
        Back Home
      </Button>
      <WalletMultiButton />
    </NavContainer>
      <HeroSection>
        <Container maxWidth="lg">
        <Box sx={{ mb: 4 }}>
          <LogoImage 
            src={logo} 
            alt="Pump.fun Logo"
          />
        </Box>
          <GlowingTitle variant="h1">
            The Future of Trading
          </GlowingTitle>
          <Typography variant="h4" sx={{ color: 'rgba(255,255,255,0.9)', mb: 4 }}>
            Unlock Powerful Trading Tools Through Mystery Loot Boxes
          </Typography>
          <Button
  variant="contained"
  size="large"
  startIcon={<Telegram />}
  href="https://t.me/apeoutmeme" // Replace with your actual Telegram link
  target="_blank"
  rel="noopener noreferrer"
  sx={{
    background: 'linear-gradient(-45deg, #229ED9, #1F96CF, #1C8DC5, #1984BB)',
    backgroundSize: '400% 400%',
    animation: 'gradient 15s ease infinite, pulse 2s infinite',
    padding: '15px 40px',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'none',
    borderRadius: '50px',
    border: '2px solid rgba(255, 255, 255, 0.2)',
    boxShadow: '0 0 20px rgba(34, 158, 217, 0.5)',
    transition: 'all 0.3s ease-in-out',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      background: 'linear-gradient(-45deg, #1984BB, #1C8DC5, #1F96CF, #229ED9)',
      transform: 'translateY(-3px) scale(1.05)',
      boxShadow: '0 10px 30px rgba(34, 158, 217, 0.6)',
      '&:before': {
        transform: 'translateX(100%)',
      }
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-100%',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
      transition: 'all 0.5s ease',
    },
    '@keyframes gradient': {
      '0%': { backgroundPosition: '0% 50%' },
      '50%': { backgroundPosition: '100% 50%' },
      '100%': { backgroundPosition: '0% 50%' }
    },
    '@keyframes pulse': {
      '0%': { boxShadow: '0 0 0 0 rgba(34, 158, 217, 0.4)' },
      '70%': { boxShadow: '0 0 0 15px rgba(34, 158, 217, 0)' },
      '100%': { boxShadow: '0 0 0 0 rgba(34, 158, 217, 0)' }
    }
  }}
>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    Join Our Community
    <Typography 
      variant="caption" 
      sx={{ 
        opacity: 0.9,
        fontSize: '0.7rem',
        mt: 0.5 
      }}
    >
      Get Early Access
    </Typography>
  </Box>
</Button>
        </Container>
        <Box sx={{ 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
  position: 'relative',
  my: 4
}}>
  <OpenLootBoxButton
   
    onClick={() => setShowLootBox(true)}
    startIcon={<LockOpen sx={{ fontSize: 24 }} />}
  >
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      Open Mystery Box
      <Typography 
        variant="caption" 
        sx={{ 
          opacity: 0.8,
          fontSize: '0.7rem',
          mt: 0.5 
        }}
      >
        Unlock Exclusive Content
      </Typography>
    </Box>
  </OpenLootBoxButton>

</Box>
      </HeroSection>
      <LootboxDisplay walletAddress={publicKey} onDataFetched={setLootboxData} lootboxData={lootboxData} />



      <Container maxWidth="lg" sx={{ mb: 11 }}>
        <Grid container spacing={6}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <FeatureCard>
                <CardContent sx={{ textAlign: 'center', p: 4, color: 'white' }}>
                  <Box sx={{ mb: 3 }}>
                    {feature.icon}
                  </Box>
                  <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 3, color: 'rgba(255,255,255,0.7)' }}>
                    {feature.description}
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center' }}>
                    {feature.tags.map((tag, i) => (
                      <Chip
                        key={i}
                        label={tag}
                        icon={<AutoAwesome />}
                        sx={{
                          background: 'linear-gradient(45deg, #667eea 30%, #764ba2 90%)',
                          color: 'white',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'translateY(-5px)',
                            boxShadow: '0 5px 15px rgba(102, 126, 234, 0.4)',
                          }
                        }}
                      />
                    ))}
                  </Box>
                </CardContent>
              </FeatureCard>
            </Grid>
          ))}
        </Grid>


      </Container>
<LootBoxOpener
  isOpen={showLootBox}
  onClose={() => setShowLootBox(false)}
  idleVideo={idleVideo}
  openingVideo={openVideo}
  onOpenComplete={handleOpenComplete}
  isLocked={false} // Or whatever condition you want to use
/>
<LootBoxOpener
  isOpen={showChristmas}
  onClose={() => setShowChristmas(false)}
  idleVideo={idleVideoChristmas}
  openingVideo={openVideoChristmas}
  onOpenComplete={handleOpenComplete}
  isLocked={false} // Or whatever condition you want to use
  onDataFetched={setLootboxData}

/>
<SocialFooter>
  <SocialIconButton
  href="https://x.com/apeoutmeme"
  target="blank"
  aria-label="Twitter"
  >
  <TwitterIcon />
  </SocialIconButton>
  <SocialIconButton
  href="https://t.me/apeoutmeme
  "
  target="blank"
  aria-label="Telegram"
  >
  <TelegramIcon />
  </SocialIconButton>
  <SocialIconButton
  href="https://github.com/apeoutmeme/apeoutdotfun-
  "
  target="blank"
  aria-label="GitHub"
  >
  <GitHubIcon />
  </SocialIconButton>
  <SocialIconButton
  href="https://www.youtube.com/shorts/f4OeszM6ukE"
  target="blank"
  aria-label="YouTube"
  >
  
  <YouTubeIcon />
  </SocialIconButton>
  <SocialIconButton
  href="https://apeout.meme/whitepaper"
  target="blank"
  aria-label="Whitepaper"
  >
  <Receipt />
  </SocialIconButton>
  <SocialIconButton
  href="https://apeout.com"
  target="blank"
  aria-label="Whitepaper"
  >
  <VideogameAsset />
  </SocialIconButton>
  <SocialIconButton
  href="https://medium.com/@apeoutcrypto"
  target="blank"
  aria-label="Whitepaper"
  >
  <Book />
  </SocialIconButton>
  <SocialIconButton
    href="https://tiktok.com/@apeoutmeme" // Add your TikTok URL
    target="_blank"
    rel="noopener noreferrer"
    aria-label="TikTok"
  >
    <FaTiktok />
  </SocialIconButton>
  
  </SocialFooter>


    </Box>
    
  );
};

export default LootBoxTeaser;