import React, { useState, useEffect } from 'react';
import { Alert, Collapse, Box, Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

const MaintenanceBanner = () => {
  const [maintenance, setMaintenance] = useState(null);

  useEffect(() => {
    const checkMaintenanceStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/maintenance/status`);
        const data = await response.json();
        if (data.success) {
          setMaintenance(data.maintenanceMode);
        }
      } catch (error) {
        console.error('Error checking maintenance status:', error);
      }
    };

    // Check immediately
    // checkMaintenanceStatus();

    // Then check every minute
    const interval = setInterval(checkMaintenanceStatus,60060000);

    return () => clearInterval(interval);
  }, []);

  if (!maintenance?.enabled) {
    return null;
  }

  return (
    <Collapse in={true}>
      <Box sx={{ width: '100%' }}>
        <Alert 
          severity="warning"
          icon={<ConstructionIcon />}
          sx={{
            borderRadius: 0,
            alignItems: 'center',
            '& .MuiAlert-message': {
              width: '100%'
            }
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              System Maintenance
            </Typography>
            <Typography variant="body2">
              {maintenance.message}
            </Typography>
            {maintenance.estimatedDuration && (
              <Typography variant="body2" color="text.secondary">
                Estimated duration: {maintenance.estimatedDuration}
              </Typography>
            )}
          </Box>
        </Alert>
      </Box>
    </Collapse>
  );
};

export default MaintenanceBanner;