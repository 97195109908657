import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Card, CardContent, Skeleton, Chip, Collapse } from '@mui/material';
import { CardGiftcard, SmartToy, AutoAwesome, Inventory, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import PropTypes from 'prop-types';




const LootboxDisplay = ({ walletAddress, onDataFetched, lootboxData }) => {
    const [userData, setUserData] = useState(null);
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showInventory, setShowInventory] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First fetch lootbox data
        const lootboxResponse = await fetch(`${process.env.REACT_APP_API_URL}api/lootboxes/${walletAddress}`);
        const lootboxData = await lootboxResponse.json();

        if (lootboxData.success) {
          setUserData(lootboxData);
        } else {
          setError(lootboxData.error);
        }

        // Then fetch agents data
        const agentsResponse = await fetch(`${process.env.REACT_APP_API_URL}api/ai-agents/${walletAddress}`);
        const agentsData = await agentsResponse.json();
        setAgents(agentsData.agents || []);

      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    if (walletAddress) {
      fetchData();
    }
  }, [walletAddress]);

  if (loading) {
    return <Box sx={{ mt: 4 }}><Skeleton variant="rectangular" height={200} /></Box>;
  }

  const groupedInventory = userData?.inventory?.reduce((acc, item) => {
    acc[item.type] = [...(acc[item.type] || []), item];
    return acc;
  }, {}) || {};

  return (
    <Box sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card sx={{ 
            background: 'linear-gradient(135deg, #322E29 0%, #9B7940 100%)',
            color: 'white',
            height: '100%'
          }}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CardGiftcard sx={{ fontSize: 24 }} />
                  <Typography variant="h6" sx={{ ml: 1 }}>Your Lootboxes</Typography>
                </Box>
                <Chip 
                  label={`${userData?.lootboxes?.quantity || 0} Available`}
                  color="primary"
                  sx={{ bgcolor: '#9B7940' }}
                />
              </Box>

              <Box sx={{ mt: 2, mb: 3 }}>
                {userData?.lootboxes?.lastOpened && (
                  <Typography variant="body2" sx={{ opacity: 0.8 }}>
                    Last opened: {new Date(userData.lootboxes.lastOpened).toLocaleString()}
                  </Typography>
                )}
              </Box>

              <Box 
                onClick={() => setShowInventory(!showInventory)}
                sx={{ 
                  cursor: 'pointer',
                  p: 1,
                  borderRadius: 1,
                  bgcolor: 'rgba(255,255,255,0.1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Inventory sx={{ fontSize: 20, mr: 1 }} />
                  <Typography>
                    Inventory ({userData?.inventory?.length || 0} items)
                  </Typography>
                </Box>
                {showInventory ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </Box>

              <Collapse in={showInventory}>
                <Box sx={{ mt: 2 }}>
                  {Object.entries(groupedInventory).map(([type, items]) => (
                    <Box key={type} sx={{ mb: 2 }}>
                      <Typography variant="subtitle2" sx={{ color: '#FFD700', mb: 1 }}>
                        {type} ({items.length})
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {items.map((item, idx) => (
                          <Chip
                            key={idx}
                            size="small"
                            label={item.name}
                            sx={{ 
                              bgcolor: item.rarity === 'LEGENDARY' ? 'rgba(255, 215, 0, 0.2)' :
                                     item.rarity === 'RARE' ? 'rgba(33, 150, 243, 0.2)' :
                                     'rgba(255, 255, 255, 0.1)'
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Collapse>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ 
            background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
            color: 'white' 
          }}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <SmartToy sx={{ fontSize: 24 }} />
                <Typography variant="h6" sx={{ ml: 1 }}>Trading Agents</Typography>
              </Box>
              {agents.map((agent, index) => (
                <Box key={index} sx={{ 
                  mt: 2, 
                  p: 2, 
                  bgcolor: 'rgba(255,255,255,0.1)', 
                  borderRadius: 1,
                  '&:hover': {
                    bgcolor: 'rgba(255,255,255,0.15)'
                  }
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">
                      {agent.icon} {agent.name}
                    </Typography>
                    <Chip
                      size="small"
                      label={agent.status}
                      sx={{
                        bgcolor: agent.status === 'active' ? 'success.main' : 'warning.main',
                        color: 'white'
                      }}
                    />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2" sx={{ opacity: 0.8 }}>
                      {agent.personality} • {agent.tokens?.length || 0} tokens created
                    </Typography>
                  </Box>
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LootboxDisplay;

LootboxDisplay.propTypes = {
    walletAddress: PropTypes.string,
    lootboxData: PropTypes.shape({
      lootboxes: PropTypes.shape({
        quantity: PropTypes.number,
        lastOpened: PropTypes.string
      }),
      inventory: PropTypes.array
    })
  };