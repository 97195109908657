import React, { useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Box, Typography, Card, CardContent, CircularProgress } from '@mui/material';
import CustomLoader from '../components/common/CustomLoader';

const pageStyles = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url('https://t4.ftcdn.net/jpg/07/68/68/01/360_F_768680151_avEYcjWo1yFrWWuao0jGVEpNOcy9u8hA.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    minHeight: '100vh',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'radial-gradient(circle at center, rgba(25, 118, 210, 0.1) 0%, rgba(0,0,0,0.3) 100%)',
      pointerEvents: 'none'
    }
  };

const BalanceGatedPage = () => {
  const { publicKey, connected } = useWallet();
  const [bnnaBalance, setBnnaBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const REQUIRED_BALANCE = 1000000; // Set your required balance here

  const fetchBnnaBalance = async () => {
    if (!publicKey) return;
    
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/user_balances/${publicKey.toString()}`);
      const data = await response.json();
      
      if (data.success) {
        const bnnaToken = data.balances.find(
          token => token.mint === 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump'
        );
        setBnnaBalance(bnnaToken?.balance || 0);
      }
    } catch (error) {
      console.error('Error fetching BNNA balance:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (connected && publicKey) {
  //     fetchBnnaBalance();
  //   }
  // }, [connected, publicKey]);

  if (!connected) {
    return (
        <Box sx={{ ...pageStyles }}>
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            gap: 3,
            p: 3,
            position: 'relative',
            zIndex: 1
          }}
        >
          <Typography variant="h5" gutterBottom sx={{ color: 'white' }}>
            Connect Your Wallet
          </Typography>
          <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} gutterBottom>
            Please connect your wallet to access this content
          </Typography>
          <WalletMultiButton />
        </Box>
      </Box>
    );
  }
  if (isLoading) {
    return (
        <Box sx={{ ...pageStyles }}>
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              minHeight: '100vh',
              position: 'relative',
              zIndex: 1
            }}
          >
            <CustomLoader
              media="https://media1.tenor.com/m/X8r5xQB_GyAAAAAd/banana-shake-banana.gif"
              mediaType="gif"
              text="Checking BNNA Balance..."
              size={200}
              bgColor="rgba(26, 35, 126, 0.9)"
              pulseColor="#90caf9"
            />
          </Box>
        </Box>
    );
  }

  if (bnnaBalance < REQUIRED_BALANCE) {
    return (
        <Box sx={{ ...pageStyles }}>
          <Box sx={{ 
            maxWidth: '800px',
            margin: '0 auto',
            p: 3,
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            zIndex: 1
          }}>
            <Card sx={{
              background: 'linear-gradient(135deg, rgba(26, 35, 126, 0.9) 0%, rgba(13, 71, 161, 0.9) 100%)',
              backdropFilter: 'blur(10px)',
              color: 'white',
              borderRadius: '16px',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
              width: '100%'
            }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Insufficient Balance
                </Typography>
                <Typography variant="body1">
                  You need at least {REQUIRED_BALANCE.toLocaleString()} BNNA to access this content.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Your current balance: {bnnaBalance.toLocaleString()} BNNA
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
    );
  }

  return (
    <Box sx={{ p: 3, ...pageStyles }}>
      <Card sx={{
        background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
        color: 'white',
        borderRadius: '16px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)'
      }}>
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom sx={{
            background: 'linear-gradient(90deg, #FFD700, #FFA500)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontWeight: 'bold',
            mb: 3
          }}>
            🎉 VIP Access Granted 🎉
          </Typography>
          
          <Typography variant="body1" sx={{ color: '#90caf9', mb: 3 }}>
            Your BNNA Balance: {bnnaBalance.toLocaleString()}
          </Typography>

          <Box sx={{ 
            p: 4, 
            border: '2px solid rgba(255, 255, 255, 0.1)',
            borderRadius: '12px',
            backdropFilter: 'blur(10px)',
            background: 'rgba(255, 255, 255, 0.05)',
            mb: 3
          }}>
            <Typography variant="h6" sx={{ color: '#90caf9', mb: 2 }}>
              🔥 Exclusive Alpha Group Access 🔥
            </Typography>
            
            <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.9)', mb: 3 }}>
              Congratulations! You now have access to our private trading signals group.
            </Typography>

            <a 
              href="https://t.me/apeoutcalls" 
              target="_blank" 
              rel="noopener noreferrer" 
              style={{ textDecoration: 'none' }}
            >
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                p: 2,
                borderRadius: '8px',
                transition: 'transform 0.2s, box-shadow 0.2s',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 8px 20px rgba(33, 150, 243, 0.3)'
                }
              }}>
                <svg 
                  viewBox="0 0 24 24" 
                  width="24" 
                  height="24" 
                  fill="#fff"
                >
                  <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.562 8.161c-.18 1.897-.962 6.502-1.359 8.627-.168.9-.5 1.201-.82 1.23-.697.064-1.226-.461-1.901-.903-1.056-.692-1.653-1.123-2.678-1.799-1.185-.781-.417-1.21.258-1.911.177-.184 3.247-2.977 3.307-3.23.007-.032.015-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.139-5.062 3.345-.479.329-.913.489-1.302.481-.428-.009-1.252-.242-1.865-.44-.751-.245-1.349-.374-1.297-.789.027-.216.324-.437.893-.663 3.498-1.524 5.831-2.529 6.998-3.015 3.333-1.386 4.025-1.627 4.477-1.635.099-.002.321.023.465.141.119.098.238.318.207.505z"/>
                </svg>
                <Typography variant="button" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Join Telegram Group
                </Typography>
              </Box>
            </a>

            <Typography variant="caption" sx={{ 
              display: 'block', 
              mt: 2, 
              color: 'rgba(255, 255, 255, 0.6)' 
            }}>
              💎 Get instant access to premium signals and market analysis
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BalanceGatedPage;