import React, { memo } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { AccountBalanceWallet } from '@mui/icons-material';
import { StyledSection, GlowingButton } from '../../styles/StyledComponent';
import { Box } from '@mui/material';


const HeroSection = memo(() => {
  return (
    <StyledSection>
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h1" gutterBottom>
              APE OUT
            </Typography>
            <Typography variant="h2" gutterBottom>
              Fast. Efficient. Ape-Powered Trading.
            </Typography>
            <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
              Trade instantly on Solana & BSC with AI-powered bots, stake $BNNA,
              and discover the next moonshot on our launchpad.
            </Typography>
            <Grid container spacing={2}>
  <Grid item>
   
  </Grid>
</Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="hero-image-container">
              <img
                src="https://github.com/apeoutmeme/resources/blob/25d45eafb420c43e12415d24d88abd4939b17f90/assets/dec27/trending-tokens.png?raw=true"
                alt="Trading Dashboard"
                width={600}
                height={400}
                priority
                className="hero-image"
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </StyledSection>
  );
});

HeroSection.displayName = 'HeroSection';
export default HeroSection;