import { useState, useRef, useEffect } from 'react';
import toast from 'react-toastify';



const isValidBase58 = (str) => {
  try {
    return /^[1-9A-HJ-NP-Za-km-z]+$/.test(str);
  } catch (error) {
    return false;
  }
};

const isSolanaAddress = (address) => {
  try {
    if (!address) return false;
    // Specific check for Solana address format
    return /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{32,44}$/.test(address);
  } catch (error) {
    return false;
  }
};

const useAutoTPSL = ({ handleBuy, handleSell, handleBuyWithRaydium, handleAutoTPSLSell, tradingWallet, tradeSettings }) => {
  const [isMonitoring, setIsMonitoring] = useState(false);
  const [currentMarketCap, setCurrentMarketCap] = useState(null);
  const [initialMarketCap, setInitialMarketCap] = useState(null);
  const [targetTp, setTargetTp] = useState(null);
  const [targetSl, setTargetSl] = useState(null);
  const [monitoredToken, setMonitoredToken] = useState(() => {
    const saved = localStorage.getItem('monitoredToken');
    return saved ? JSON.parse(saved) : null;
  });
  const monitoringRef = useRef(null);
  const retryCount = useRef(0);
  const MAX_RETRIES = 3;
  const POLL_INTERVAL = 10000; // 10 seconds

  useEffect(() => {
    if (monitoredToken) {
      setIsMonitoring(true);
      setInitialMarketCap(monitoredToken.initialMc);
      setTargetTp(monitoredToken.tpTarget);
      setTargetSl(monitoredToken.slTarget);
      startMonitoringLoop(monitoredToken);
    }
  }, []);

  const startMonitoringLoop = async (tokenData) => {
    while (isMonitoring) {
      try {
        let currentMc;
        
        if (tokenData.isPumpToken) {
          const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${tokenData.mintAddress}`);
          const data = await response.json();
          if (data.success) {
            currentMc = data.data.marketCap;
          }
        } else {
          const response = await fetch(`https://api.dexscreener.com/latest/dex/tokens/${tokenData.mintAddress}`);
          const data = await response.json();
          console.log(data)
          if (data.pairs && data.pairs[0]) {
            currentMc = parseFloat(data.pairs[0].marketCap);
          }
        }
  
        if (currentMc) {
          setCurrentMarketCap(currentMc);
          
          // Check TP/SL conditions
          // Check TP/SL conditions
          if (currentMc >= tokenData.tpTarget || currentMc <= tokenData.slTarget) {
            try {
              if (!tokenData.mintAddress) {
                throw new Error('Missing mint address');
              }
          
              if (!isSolanaAddress(tokenData.mintAddress)) {
                throw new Error('Invalid Solana address format');
              }
          
              const sellSuccess = tokenData.isPumpToken
                ? await handleSell(tokenData.mintAddress, tokenData.buyAmount)
                : await handleAutoTPSLSell(
                    tokenData.mintAddress,
                    tokenData.buyAmount,
                    tokenData.tradeSettings?.slippage || 15,
                    tokenData.tradeSettings?.priorityFee || 0.001
                  );
          
              if (sellSuccess) {
                stopMonitoring();
                break;
              } else {
                throw new Error('Sell transaction failed');
              }
            } catch (error) {
              console.error('Auto TP/SL sell failed:', error.message);
              retryCount.current += 1;
              if (retryCount.current >= MAX_RETRIES) {
                console.error('Max retries reached for auto TP/SL sell');
                stopMonitoring();
                break;
              }
              await new Promise(resolve => setTimeout(resolve, 5000));
            }
          }
        }
  
        await new Promise(resolve => setTimeout(resolve, 5000));
      } catch (error) {
        console.error('Error in monitoring loop:', error);
        await new Promise(resolve => setTimeout(resolve, 10000));
      }
    }
  };

  const startMonitoring = async (mintAddress, buyAmount, takeProfitPercent, stopLossPercent) => {
    try {
      // Validate the amount using trade settings
      const amount = tradeSettings?.amount || buyAmount;
      if (!amount || isNaN(amount) || amount <= 0) {
        throw new Error('Please enter a valid amount');
      }

      if (!mintAddress || !isValidBase58(mintAddress)) {
        throw new Error('Invalid mint address format');
      }

      // Prompt user to confirm the token's origin
      const isPumpToken = window.confirm('Is this a Pump.fun token? Click OK for Pump.fun, Cancel for Raydium/DEX');
  
      // Select appropriate buy method based on user input and include trade settings
      const buySuccess = isPumpToken 
  ? await handleBuy(mintAddress, amount).catch(error => {
      console.error('Buy failed:', error);
      throw new Error(`Failed to buy token: ${error.message}`);
    })
  : await handleBuyWithRaydium(
      mintAddress, 
      amount, 
      tradeSettings?.slippage || 15, 
      tradeSettings?.priorityFee || 0.001
    ).catch(error => {
      console.error('Buy with Raydium failed:', error);
      throw new Error(`Failed to buy token on DEX: ${error.message}`);
    });
  
      if (!buySuccess) {
        console.log(amount)
        console.log(tradeSettings?.slippage) 
        console.log(tradeSettings?.priorityFee) 
        console.log(tradeSettings)
        throw new Error('Initial buy failed');
      }
      console.log(buySuccess)
  
      // Fetch initial market cap based on token type
      let initialMc;
      if (isPumpToken) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${mintAddress}`);
        const data = await response.json();
        
        if (!data.success || !data.data.marketCap) {
          throw new Error('Failed to fetch initial market cap from Pump.fun');
        }
        initialMc = data.data.marketCap;
      } else {
        const response = await fetch(`https://api.dexscreener.com/latest/dex/tokens/${mintAddress}`);
        const data = await response.json();
        
        if (!data.pairs || !data.pairs[0]) {
          throw new Error('Failed to fetch token data from DEXScreener');
        }
        
        const pair = data.pairs[0];
        initialMc = parseFloat(pair.marketCap);
      }
  
      const tpTarget = initialMc * (1 + (takeProfitPercent / 100));
      const slTarget = initialMc * (1 - (stopLossPercent / 100));
      
      const tokenData = {
        mintAddress,
        buyAmount: amount, // Use the validated amount
        initialMc,
        tpTarget,
        slTarget,
        takeProfitPercent,
        stopLossPercent,
        isPumpToken,
        tradeSettings: { // Store trade settings with the token data
          slippage: tradeSettings?.slippage || 15,
          priorityFee: tradeSettings?.priorityFee || 0.001,
          amount: amount
        }
      };
  
      localStorage.setItem('monitoredToken', JSON.stringify(tokenData));
      setMonitoredToken(tokenData);
      
      setInitialMarketCap(initialMc);
      setCurrentMarketCap(initialMc);
      setTargetTp(tpTarget);
      setTargetSl(slTarget);
      setIsMonitoring(true);
  
      startMonitoringLoop(tokenData);
  
    } catch (error) {
      console.error('Failed to start monitoring:', error);
      stopMonitoring();
      throw error;
    }
  };

  const stopMonitoring = () => {
    if (monitoringRef.current) {
      clearInterval(monitoringRef.current);
      monitoringRef.current = null;
    }
    localStorage.removeItem('monitoredToken');
    setMonitoredToken(null);
    setIsMonitoring(false);
    setCurrentMarketCap(null);
    setInitialMarketCap(null);
    setTargetTp(null);
    setTargetSl(null);
    retryCount.current = 0;
  };

  useEffect(() => {
    return () => {
      if (monitoringRef.current) {
        clearInterval(monitoringRef.current);
      }
    };
  }, []);

  return {
    isMonitoring,
    currentMarketCap,
    initialMarketCap,
    targetTp,
    targetSl,
    monitoredToken,
    startMonitoring,
    stopMonitoring
  };
};

export default useAutoTPSL;