import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Card,
  CardContent,
  Grid,
  styled,
  IconButton,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import preview1 from '../../../assets/bsc/preview1.mov';
import preview2 from '../../../assets/bsc/preview2.mov';


const PreviewContainer = styled(Paper)({
  background: 'linear-gradient(135deg, rgba(243, 186, 47, 0.1), rgba(197, 158, 87, 0.2))',
  backdropFilter: 'blur(16px)',
  padding: '2rem',
  borderRadius: '24px',
  border: '1px solid rgba(243, 186, 47, 0.2)',
});

const VideoCard = styled(Card)({
  background: 'transparent',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '16px',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.02)',
  },
});

const VideoOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.3)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  transition: 'opacity 0.3s ease',
  '&:hover': {
    opacity: 1,
  },
});

const StyledIconButton = styled(IconButton)({
  background: 'rgba(243, 186, 47, 0.8)',
  '&:hover': {
    background: 'rgba(197, 158, 87, 0.8)',
  },
});

const BSCPreview = () => {
  const [playing, setPlaying] = useState({ video1: false, video2: false });

  const togglePlay = (videoId) => {
    const video = document.getElementById(videoId);
    if (video) {
      if (playing[videoId]) {
        video.pause();
      } else {
        video.play();
      }
      setPlaying({ ...playing, [videoId]: !playing[videoId] });
    }
  };

  return (
    <PreviewContainer elevation={3}>
      <Typography 
        variant="h3" 
        sx={{ 
          color: '#F3BA2F',
          textAlign: 'center',
          mb: 4,
          fontWeight: 'bold',
          textShadow: '0px 2px 4px rgba(0,0,0,0.3)'
        }}
      >
        Auto-Ape on BSC Preview
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <VideoCard elevation={5}>
            <Box sx={{ position: 'relative' }}>
              <video
                id="video1"
                style={{ width: '100%', borderRadius: '16px' }}
                poster="/path-to-thumbnail1.jpg"
              >
                <source src={preview1} type="video/mp4" />
              </video>
              <VideoOverlay>
                <StyledIconButton onClick={() => togglePlay('video1')}>
                  {playing.video1 ? <PauseIcon /> : <PlayArrowIcon />}
                </StyledIconButton>
              </VideoOverlay>
            </Box>
            <CardContent sx={{ background: 'rgba(0,0,0,0.7)' }}>
              <Typography variant="h6" sx={{ color: '#FEFFEB', mb: 1 }}>
                Auto-Ape Strategy Demo
              </Typography>
              <Typography sx={{ color: '#C59E57' }}>
                Watch how our Auto-Ape feature instantly deploys your strategy on BSC
              </Typography>
            </CardContent>
          </VideoCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <VideoCard elevation={5}>
            <Box sx={{ position: 'relative' }}>
              <video
                id="video2"
                style={{ width: '100%', borderRadius: '16px' }}
                poster="/path-to-thumbnail2.jpg"
              >
                <source src={preview2} type="video/mp4" />
              </video>
              <VideoOverlay>
                <StyledIconButton onClick={() => togglePlay('video2')}>
                  {playing.video2 ? <PauseIcon /> : <PlayArrowIcon />}
                </StyledIconButton>
              </VideoOverlay>
            </Box>
            <CardContent sx={{ background: 'rgba(0,0,0,0.7)' }}>
              <Typography variant="h6" sx={{ color: '#FEFFEB', mb: 1 }}>
                Performance Overview
              </Typography>
              <Typography sx={{ color: '#C59E57' }}>
                See real-time results and performance metrics of our BSC strategies
              </Typography>
            </CardContent>
          </VideoCard>
        </Grid>
      </Grid>
    </PreviewContainer>
  );
};

export default BSCPreview;