import React from 'react';
import { Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowForward, AccountBalance, SwapHoriz, CheckCircle } from '@mui/icons-material';

const StyledBox = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, rgba(243, 186, 47, 0.1), rgba(197, 158, 87, 0.2))',
  backdropFilter: 'blur(16px)',
  border: '1px solid rgba(243, 186, 47, 0.2)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const BridgeButton = styled(Button)({
  background: 'linear-gradient(45deg, #F3BA2F 30%, #C59E57 90%)',
  borderRadius: '8px',
  border: 0,
  color: '#322E29',
  padding: '12px 24px',
  fontWeight: 'bold',
  '&:hover': {
    background: 'linear-gradient(45deg, #C59E57 30%, #F3BA2F 90%)',
  }
});

const BridgeGuide = () => {
  return (
    <StyledBox>
      <Typography variant="h5" sx={{ color: 'white', mb: 2 }}>
        Bridge to Solana
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <AccountBalance sx={{ color: '#F3BA2F' }} />
          </ListItemIcon>
          <ListItemText 
            primary="Connect your wallet to BSC"
            secondary="Ensure you have BNB for gas fees"
            sx={{ color: 'white' }}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SwapHoriz sx={{ color: '#F3BA2F' }} />
          </ListItemIcon>
          <ListItemText 
            primary="Use Rhino.fi Bridge"
            secondary="Bridge your tokens from BSC to Solana"
            sx={{ color: 'white' }}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckCircle sx={{ color: '#F3BA2F' }} />
          </ListItemIcon>
          <ListItemText 
            primary="Complete Transaction"
            secondary="Wait for confirmation on Solana network"
            sx={{ color: 'white' }}
          />
        </ListItem>
      </List>
      <BridgeButton
        endIcon={<ArrowForward />}
        onClick={() => window.open('https://app.rhino.fi/bridge?chain=BINANCE&token=USDC&chainOut=SOLANA', '_blank')}
        fullWidth
      >
        Bridge Now
      </BridgeButton>
    </StyledBox>
  );
};

export default BridgeGuide;