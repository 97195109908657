import React, { useState, useEffect } from 'react';
import './AIAgentDashboard.css';

import CreateAgent from './CreateAgent';
import HowItWorks from '../../common/HowItWorks';
import bbAvatar from '../../../assets/avatars/bb.webp';
import whaletrackAvatar from '../../../assets/avatars/whaletrack.webp';
import guardianAvatar from '../../../assets/avatars/guardian.webp';
import alphaHuntAvatar from '../../../assets/avatars/alpha-hunt.webp';
import dcaAvatar from '../../../assets/avatars/dca.webp';
import alphaSeekersAvatar from '../../../assets/avatars/alpha_seekers.webp';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, Grid, Button, Paper, Stack, IconButton, Dialog, DialogTitle, DialogContent, ButtonGroup } from '@mui/material';
import ComingSoonOverlay from '../../common/ComingSoonOverlay';
import AgentsManagement from './AgentsManagements';
import { useNavigate } from 'react-router-dom';
import logoTp from '../../../assets/logo-tp.png';
import DashboardIntro from './DashboardIntro';
import ActivityFeed from './ActivityFeed';
import SwarmSimulation from '../../swarm-sim/SwarmSimulation';

const StyledHomeButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
}));
const StyledHomeIconButton = styled(IconButton)(({ theme }) => ({
  background: 'linear-gradient(to right, #4f46e5, #3b82f6)',
  padding: '8px',
  borderRadius: '12px',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(to right, #3b82f6, #4f46e5)',
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)',
  },
  '&:disabled': {
    opacity: 0.7,
    background: 'linear-gradient(to right, #4f46e5, #3b82f6)',
  },
  '@media (max-width: 600px)': {
    padding: '6px',
  }
}));
const StyledHomeImage = styled('img')({
  width: '42px',
  height: '42px',
  borderRadius: '12px',
  border: '1px solid white',
  
  '@media (max-width: 600px)': {
    width: '36px',
    height: '36px',
  }
});
const DashboardNav = styled('nav')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 40px',
  width: '100%',
  position: 'relative',
  backdropFilter: 'blur(10px)',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  '@media (max-width: 1024px)': {
    flexDirection: 'column',
    gap: '20px',
    padding: '20px',
  }
}));
const NavButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  marginLeft: '80px', // Space for home button
  '@media (max-width: 1024px)': {
    marginLeft: 0,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  '@media (max-width: 600px)': {
    gap: '8px',
  }
}));
const NavButton = styled('button')(({ active }) => ({
  padding: '10px 20px',
  borderRadius: '12px',
  border: 'none',
  cursor: 'pointer',
  fontSize: '16px',
  fontWeight: 600,
  color: 'white',
  background: active 
    ? 'linear-gradient(to right, #4f46e5, #3b82f6)'
    : 'rgba(255, 255, 255, 0.1)',
  transition: 'all 0.3s ease',
  backdropFilter: 'blur(5px)',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    background: active 
      ? 'linear-gradient(to right, #3b82f6, #4f46e5)'
      : 'rgba(255, 255, 255, 0.2)',
  },
  '@media (max-width: 600px)': {
    padding: '8px 16px',
    fontSize: '14px',
  }
}));
const WalletButtonContainer = styled('div')({
  marginLeft: 'auto',
  '@media (max-width: 1024px)': {
    marginLeft: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  }
});
const StyledWalletButton = styled(WalletMultiButton)(({ theme }) => ({
  padding: '12px 24px !important',
  background: 'linear-gradient(to right, #4f46e5, #3b82f6) !important',
  border: 'none !important',
  borderRadius: '12px !important',
  color: 'white !important',
  fontWeight: '600 !important',
  transition: 'all 0.3s ease !important',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    background: 'linear-gradient(to right, #3b82f6, #4f46e5) !important',
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)',
  },
  '@media (max-width: 600px)': {
    padding: '10px 20px !important',
    fontSize: '14px !important',
  }
}));

const AIAgentDashboard = ({publicKey, handleBuy, handleSell}) => {
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('explore');
  const navigate = useNavigate();
  const [isNavigating, setIsNavigating] = useState(false);

  const handleHomeNavigation = () => {
    setIsNavigating(true);
    window.location.href = '/'; // This will force a full page refresh
  };

  useEffect(() => {
    console.log('AIAgentDashboard props:', {
      hasPublicKey: !!publicKey,
      hasHandleBuy: typeof handleBuy === 'function',
      hasHandleSell: typeof handleSell === 'function'
    });
  }, [publicKey, handleBuy, handleSell]);
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        // TODO: Replace with actual API call
        const mockAgents = [
          {
            id: 1,
            name: "Aping With Big Balls",
            description: "Aggressive momentum trading strategy",
            performance: "+233.5%",
            trades: 156,
            creator: "9oMoQZ...wZaC",
            avatar: bbAvatar
          },
          // Add more mock agents...
          {
            id: 2,
            name: "Blue Chip Guardian",
            description: "Conservative strategy focusing on established tokens with strong fundamentals",
            performance: "+45.2%",
            trades: 82,
            creator: "5xKpL...mN3r",
            avatar: guardianAvatar,
            strategy: "Conservative"
          },
          {
            id: 3,
            name: "Whale Tracker Elite",
            description: "Copy-trades top performing wallets with proven track records",
            performance: "+127.8%",
            trades: 134,
            creator: "7jRtY...vB9q",
            avatar: whaletrackAvatar,
            strategy: "Copy-trading"
          },
          {
            id: 4,
            name: "Telegram Alpha Hunter",
            description: "Automatically executes trades based on signals from premium Telegram channels",
            performance: "+167.3%",
            trades: 98,
            creator: "3pNkR...qX2w",
            avatar: alphaHuntAvatar,
            strategy: "Signal-based"
          },
          {
            id: 5,
            name: "Twitter Alpha Seeker",
            description: "Monitors and trades based on influential crypto Twitter accounts with proven track records",
            performance: "+142.9%",
            trades: 112,
            creator: "8mVtH...yP4k",
            avatar: alphaSeekersAvatar,
            strategy: "Social-trading"
          },
          {
            id: 6,
            name: "DCA Master",
            description: "Smart DCA strategy that automatically buys your favorite tokens during dips using technical indicators",
            performance: "+76.4%",
            trades: 245,
            creator: "2wQjL...nR9s",
            avatar: dcaAvatar,
            strategy: "DCA"
          }
        ];
        setAgents(mockAgents);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching agents:', error);
        setIsLoading(false);
      }
    };

    fetchAgents();
  }, []);

  return (
    <>
    <div className="agent-dashboard">
  <DashboardNav>
    <NavButtonsContainer>
      
      <NavButton
        active={activeTab === 'explore'}
        onClick={() => setActiveTab('explore')}
      >
        Explore Agents
      </NavButton>
      <NavButton
        active={activeTab === 'create'}
        onClick={() => setActiveTab('create')}
      >
        Create Agent
      </NavButton>
      <NavButton
        active={activeTab === 'manage'}
        onClick={() => setActiveTab('manage')}
      >
        My Agents
      </NavButton>
      <NavButton
        active={activeTab === 'how'}
        onClick={() => setActiveTab('how')}
      >
        How It Works
      </NavButton>
      <NavButton
  active={activeTab === 'simulate'}
  onClick={() => setActiveTab('simulate')}
>
  Simulate Swarm
</NavButton>
    </NavButtonsContainer>
    <WalletButtonContainer>
      <StyledWalletButton />
      <StyledHomeButton>
      <StyledHomeIconButton
        onClick={handleHomeNavigation}
        disabled={isNavigating}
      >
        <StyledHomeImage 
          src={logoTp} 
          alt="Home" 
        />
      </StyledHomeIconButton>
    </StyledHomeButton>
    </WalletButtonContainer>
  </DashboardNav>
  <DashboardIntro />
  
<div className="dashboard-content">
        {activeTab === 'create' ? (
          <CreateAgent setActiveTab={setActiveTab} activeTab={activeTab} publicKey={publicKey} />
        ) : activeTab === 'how' ? (
          <HowItWorks setActiveTab={setActiveTab} activeTab={activeTab} />
        ) : activeTab === 'manage' ? (
          <AgentsManagement setActiveTab={setActiveTab} activeTab={activeTab} publicKey={publicKey} handleBuy={handleBuy} handleSell={handleSell} />
        ) : activeTab === 'simulate' ? (
          <SwarmSimulation setActiveTab={setActiveTab} activeTab={activeTab} handleBuy={handleBuy} handleSell={handleSell} />
        ) : isLoading ? (
          <div className="loading-spinner">Loading...</div>
        ) : (
          <div className="agents-grid">
            {agents.map(agent => (
              <div
                key={agent.id}
                className="agent-card"
              >
                <img src={agent.avatar} alt={agent.name} className="agent-avatar" />
                <h3 style={{ color: 'white' }}>{agent.name}</h3>
                <p style={{ color: 'white' }}>{agent.description}</p>
                <div className="agent-stats">
                  <span className="performance">{agent.performance}</span>
                  <span style={{ color: 'white' }} className="trades">{agent.trades} trades</span>
                </div>
                <div className="agent-creator">
                  Created by: {agent.creator}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
      <Box sx={{ width: '100%' }}>   
      <ActivityFeed />
    </Box>
    </>
  );
};

export default AIAgentDashboard;