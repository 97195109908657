import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Skeleton,
  Avatar,
  LinearProgress,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import StarsIcon from '@mui/icons-material/Stars';
import { toast } from 'react-toastify';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#fff',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
}));

const RankChip = styled(Chip)(({ rank }) => {
  const getRankColor = () => {
    switch (rank) {
      case 'Ape Chieftain': return 'linear-gradient(45deg, #FFD700 30%, #FFA500 90%)';
      case 'OG Ape': return 'linear-gradient(45deg, #C0C0C0 30%, #A9A9A9 90%)';
      case 'GovernAPE': return 'linear-gradient(45deg, #CD7F32 30%, #8B4513 90%)';
      default: return 'linear-gradient(45deg, #4A4A4A 30%, #2A2A2A 90%)';
    }
  };

  return {
    background: getRankColor(),
    color: '#000',
    fontWeight: 'bold',
    padding: '8px 12px',
    '& .MuiChip-icon': {
      color: '#000',
    },
  };
});

const LeaderboardContainer = styled(Paper)({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  borderRadius: '15px',
  padding: '20px',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
});

const Leaderboard = () => {
  const [holders, setHolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const GAPE_MINT = '4FM5bXZS4qnMK4Vdn4yRM4mANUVd97xYKZ2yKxziJRkd';

  const getRank = (balance) => {
    if (balance >= 1000000) return 'Ape Chieftain';
    if (balance >= 500000) return 'OG Ape';
    if (balance >= 100000) return 'GovernAPE';
    return 'Ape Initiate';
  };

  const getRankIcon = (rank) => {
    switch (rank) {
      case 'Ape Chieftain': return <StarsIcon />;
      case 'OG Ape': return <WorkspacePremiumIcon />;
      default: return null;
    }
  };

  const fetchHolders = async () => {
    try {
      const response = await fetch(`/api/token-holders/${GAPE_MINT}`);
      const data = await response.json();
      console.log('Raw API response:', data);

      if (data.success && data.data && data.data.holders) {
        // Process the holders data directly from the holders array
        const processedHolders = data.data.holders
          .map(holder => ({
            address: holder.owner,
            balance: Number(holder.amount),
            rank: getRank(Number(holder.amount))
          }))
          .sort((a, b) => b.balance - a.balance);

        console.log('Processed holders:', processedHolders);
        setHolders(processedHolders);
      } else {
        console.error('Invalid data structure:', data);
        toast.error('Failed to fetch leaderboard data');
      }
    } catch (error) {
      console.error('Error fetching holders:', error);
      toast.error('Error loading leaderboard');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHolders();
  }, []);

  // Add total holders count display
  const TotalHolders = () => (
    <Typography 
      variant="subtitle1" 
      sx={{ 
        color: 'rgba(255, 255, 255, 0.7)',
        mt: 2 
      }}
    >
      Total Holders: {holders.length}
    </Typography>
  );

  const LoadingSkeleton = () => (
    <TableRow>
      <StyledTableCell><Skeleton variant="text" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} /></StyledTableCell>
      <StyledTableCell><Skeleton variant="text" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} /></StyledTableCell>
      <StyledTableCell><Skeleton variant="text" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} /></StyledTableCell>
      <StyledTableCell><Skeleton variant="text" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} /></StyledTableCell>
    </TableRow>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <LeaderboardContainer elevation={3}>
        <Box sx={{ mb: 4, textAlign: 'center' }}>
          <Typography variant="h3" sx={{ color: '#fff', mb: 2 }}>
            $GAPE Leaderboard
          </Typography>
          <Typography variant="subtitle1" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            Top GAPE holders and their prestigious ranks
          </Typography>
          <TotalHolders />
        </Box>

        <Box sx={{ mb: 4, display: 'flex', gap: 2, justifyContent: 'center', flexWrap: 'wrap' }}>
          {['Ape Chieftain', 'OG Ape', 'GovernAPE', 'Ape Initiate'].map((rank) => (
            <Tooltip 
              key={rank} 
              title={`${rank} - Required Balance: ${
                rank === 'Ape Chieftain' ? '1,000,000+' :
                rank === 'OG Ape' ? '500,000+' :
                rank === 'GovernAPE' ? '100,000+' : '< 100,000'
              } GAPE`}
            >
              <RankChip
                rank={rank}
                label={rank}
                icon={getRankIcon(rank)}
              />
            </Tooltip>
          ))}
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Rank</StyledTableCell>
                <StyledTableCell>Wallet</StyledTableCell>
                <StyledTableCell>Balance</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                Array.from({ length: 10 }).map((_, index) => (
                  <LoadingSkeleton key={index} />
                ))
              ) : (
                holders.map((holder, index) => (
                  <TableRow 
                    key={holder.address}
                    sx={{ 
                      '&:hover': { 
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        transition: 'background-color 0.3s'
                      }
                    }}
                  >
                    <StyledTableCell>#{index + 1}</StyledTableCell>
                    <StyledTableCell>
                      {holder.address.slice(0, 4)}...{holder.address.slice(-4)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {holder.balance.toLocaleString()} GAPE
                    </StyledTableCell>
                    <StyledTableCell>
                      <RankChip
                        rank={holder.rank}
                        label={holder.rank}
                        icon={getRankIcon(holder.rank)}
                        size="small"
                      />
                    </StyledTableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </LeaderboardContainer>
    </Container>
  );
};

export default Leaderboard;