import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper, Container } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const APIAccessPage = () => {
  const [address, setAddress] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!address) {
      toast.error('Please enter a wallet address');
      return;
    }

    setIsSubmitting(true);
    try {
      // Google Forms submission URL (replace FORM_ID with your actual form ID)
      const formUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSdrfexjIZ-Xvq9Uo-yup0pkSqYCVzKnXUKpsz0pekFo3E631A/formResponse';
      
      // Create form data
      const formData = new FormData();
      formData.append('entry.2134962527', address); // Replace with your actual entry ID

      // Submit to Google Forms
      await fetch(formUrl, {
        method: 'POST',
        mode: 'no-cors', // Required for Google Forms
        body: formData,
      });

      // Navigate to completion page
      navigate('/api-access/complete');
      
    } catch (error) {
      console.error('Submission error:', error);
      // Note: Due to CORS, we won't actually get error info
      // But we'll still navigate on completion since the form should submit
      navigate('/api-access/complete');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(to bottom, #111827, #000000)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: 4,
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={24}
          sx={{
            p: 4,
            background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.9), rgba(31, 41, 55, 0.9))',
            backdropFilter: 'blur(16px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: '#fbbf24',
              textAlign: 'center',
              fontWeight: 'bold',
              mb: 2,
              fontSize: { xs: '2rem', sm: '3rem' },
            }}
          >
            Coming Soon
          </Typography>
          
          <Typography
            sx={{
              color: 'white',
              textAlign: 'center',
              mb: 4,
              opacity: 0.9,
            }}
          >
            Our API is under development. Register now for early access!
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter your wallet address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#fbbf24',
                  },
                },
              }}
            />
            
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={{
                py: 1.5,
                px: 4,
                background: 'linear-gradient(to right, #fbbf24, #d97706)',
                '&:hover': {
                  background: 'linear-gradient(to right, #d97706, #b45309)',
                },
                '&:disabled': {
                  background: 'rgba(251, 191, 36, 0.5)',
                },
              }}
            >
              {isSubmitting ? 'Registering...' : 'Get Early Access'}
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default APIAccessPage;