import React from 'react';
import { Box, Card, Typography, Button, Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Rocket as RocketIcon,
  Star as StarIcon,
  Diamond as DiamondIcon 
} from '@mui/icons-material';
import BackToHomeButton from '../components/reusables/BackHomeButton';



const TierCard = styled(Card)(({ theme, variant }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4),
  transition: 'transform 0.3s ease-in-out',
  background: variant === 'pro' 
    ? 'linear-gradient(135deg, #1a237e 0%, #311b92 100%)'
    : variant === 'elite'
      ? 'linear-gradient(135deg, #b71c1c 0%, #880e4f 100%)'
      : 'linear-gradient(135deg, #1565c0 0%, #0277bd 100%)',
  color: '#fff',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 12px 24px rgba(0,0,0,0.2)',
  },
}));

const FeatureList = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: '24px 0',
  '& li': {
    margin: '12px 0',
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: '"✓"',
      marginRight: '8px',
      color: '#4caf50',
    },
  },
});

const TierStructure = () => {
  const tiers = [
    {
      title: 'Basic Tier',
      icon: <StarIcon sx={{ fontSize: 40 }} />,
      price: '0 $BNNA',
      features: [
        'Basic trading functionality',
        'Standard market data',
        'Basic AI agent templates',
        'Community support',
        'Standard execution speed',
      ],
      variant: 'basic',
      buttonText: 'Get Started',
      link: '/app' // Added link for Basic tier
    },
    {
      title: 'Pro Tier - (Holders Only)',
      icon: <RocketIcon sx={{ fontSize: 40 }} />,
      price: '1,000,000 $BNNA',
      features: [
        'Advanced trading features',
        'Real-time market analytics',
        'Custom AI agent creation',
        'Priority support',
        'Enhanced execution speed',
        'Advanced order types',
        'Access to Banana Stand'

      ],
      variant: 'pro',
      buttonText: 'Upgrade to Pro',
      link: 'https://dexscreener.com/solana/g3rhsoewmmvembdkbec6sfwu91rbmkdyeztwdnmawjxb' // Added link for Pro tier
    },
    {
      title: 'Elite Tier - (Stakers Only)',
      icon: <DiamondIcon sx={{ fontSize: 40 }} />,
      price: '5M - 10M $BNNA',
      features: [
        'Premium trading suite',
        'Exclusive market insights',
        'Advanced AI customization',
        '24/7 dedicated support',
        'Maximum execution priority',
        'Custom strategy development',
        'Early access to new features'
        
      ],
      variant: 'elite',
      buttonText: 'Go Elite',
      link: '/banana-stand' // Added link for Elite tier
    }
  ];
  

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(to bottom, #000000, #1a1a1a)',
        py: 8
      }}
    >
      <BackToHomeButton />
      <Container maxWidth="lg">
        <Typography 
          variant="h2" 
          align="center" 
          sx={{ 
            color: '#fff',
            mb: 6,
            fontWeight: 700,
            textShadow: '0 2px 4px rgba(0,0,0,0.5)'
          }}
        >
          Choose Your Trading Tier
        </Typography>
        
        <Grid container spacing={4} alignItems="stretch">
          {tiers.map((tier) => (
            <Grid item xs={12} md={4} key={tier.title}>
              <TierCard variant={tier.variant}>
                <Box sx={{ textAlign: 'center', mb: 3 }}>
                  {tier.icon}
                </Box>
                <Typography 
                  variant="h4" 
                  component="h2" 
                  sx={{ 
                    mb: 2,
                    textAlign: 'center',
                    fontWeight: 600
                  }}
                >
                  {tier.title}
                </Typography>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    mb: 3,
                    textAlign: 'center',
                    color: '#4caf50'
                  }}
                >
                  {tier.price}
                </Typography>
                <FeatureList>
                  {tier.features.map((feature) => (
                    <li key={feature}>{feature}</li>
                  ))}
                </FeatureList>
                <Box sx={{ mt: 'auto' }}>
                  <Button
                    variant="contained"
                    fullWidth
                    href={tier.link}
                    sx={{
                      py: 1.5,
                      backgroundColor: 'rgba(255,255,255,0.1)',
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.2)',
                      }
                    }}
                  >
                    {tier.buttonText}
                  </Button>
                </Box>
              </TierCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default TierStructure;