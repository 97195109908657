import React, { lazy, Suspense, memo, useEffect, useTransition } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Paper,
  IconButton,
  Stack
} from '@mui/material';
import {
  Twitter as TwitterIcon,
  Telegram as TelegramIcon,
  GitHub as GitHubIcon,
  YouTube as YouTubeIcon,
  Receipt,
  Book,
  SmartToy,
  AccountBalanceWallet,
  Rocket,
  Assessment,
  Token,
  Assistant
} from '@mui/icons-material';
import { FaTiktok } from 'react-icons/fa';
import { styled, keyframes } from '@mui/material/styles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import logoImage from '../assets/bnnalogo.png';
import logoGif from '../assets/gifs/banana-single.gif'
import logoGif2 from '../assets/gifs/bnna.gif'

import HeroSection from './sections/HeroSection';
import LoadingState from '../components/common/LoadingState';
import ErrorBoundary from '../components/common/ErrorBoundary';
import { CircularProgress } from '@mui/material';

const PoweredBySection = lazy(() => import('./sections/PoweredBySection'));
const VideoSection = lazy(() => import('./sections/VideoSection'));
const FooterSection = lazy(() => import('./sections/FooterSection'));
const AsSeenOnSection = lazy(() => import('./sections/AsSeenOnSection'));
const StakingSection = lazy(() => import('./sections/StakingSection'));

// Animation keyframes
const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const AnimatedLogo = styled('img')({
  width: '120px',
  height: 'auto',
  margin: '2rem auto',
  display: 'block',
  filter: 'drop-shadow(0 0 8px rgba(251, 191, 36, 0.3))',
});

// Styled components with enhanced animations
const AnimatedSection = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(8, 0),
  position: 'relative',
  transition: 'background-color 0.6s ease-in-out',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle at center, rgba(59,130,246,0.1) 0%, rgba(0,0,0,0) 70%)',
    opacity: 0,
    transition: 'opacity 0.6s ease-in-out',
  },
  '&.aos-animate::before': {
    opacity: 1,
  }
}));

const FloatingIcon = styled(Box)(({ theme }) => ({
  animation: `${float} 6s ease-in-out infinite`,
  '& svg': {
    fontSize: 48,
    color: 'white',
  }
}));

const PulsingCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '&:hover': {
    transform: 'translateY(-8px) scale(1.02)',
    background: 'rgba(255, 255, 255, 0.08)',
    '& .icon-wrapper': {
      animation: `${pulse} 2s ease-in-out infinite`,
    }
  }
}));

const RotatingBackground = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'radial-gradient(circle at center, white 0%, transparent 70%)',
  opacity: 0.1,
  animation: `${rotate} 20s linear infinite`,
  pointerEvents: 'none',
});

const GlowingButton = styled(Button)({
  backgroundColor: "#C59E57", // Gold
  color: "#322E29", // Dark brown text for contrast
  border: "2px solid #FEFFEB", // Light Beige Border
  fontWeight: "bold",
  textTransform: "uppercase",
  fontSize: "18px",
  padding: "12px 24px",
  transition: "all 0.3s ease-in-out",
  boxShadow: "0px 4px 10px rgba(197, 158, 87, 0.8)",

  "&:hover": {
    backgroundColor: "#FEFFEB", // Light Beige Hover
    color: "#322E29",
    border: "2px solid #C59E57", // Gold Border
    boxShadow: "0px 6px 14px rgba(197, 158, 87, 1)",
  },

  "&:active": {
    transform: "scale(0.95)",
    boxShadow: "0px 2px 6px rgba(197, 158, 87, 0.5)",
  },
});


const LoadingFallback = () => (
  <Box 
    sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      minHeight: '200px',
      bgcolor: '#121212'
    }}
  >
    <CircularProgress />
  </Box>
);


const LandingPage = () => {
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.AOS.init({
        duration: 1200,
        once: false,
        mirror: true,
        offset: 100
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    startTransition(() => {
      // Any state updates that might cause suspense
    });
  }, []);

  return (
    <Box sx={{ bgcolor: '#121212', color: 'white' }}>
      
      <ErrorBoundary fallback={<div>Error loading powered by section</div>}>
          <Suspense fallback={<LoadingFallback />}>
            <HeroSection />
            <GlowingButton 
                  variant="contained" 
                  color="primary"
                  href="/app"
                  target="_blank"
                >
                  Start Trading
                </GlowingButton>
        </Suspense>
      </ErrorBoundary>
      <Box 
  sx={{ 
    textAlign: 'center',
    py: 4,
    background: 'linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.6))',
    borderRadius: '16px',
    my: 3
  }}
  data-aos="fade-in"
  data-aos-duration="1000"
  data-aos-delay="200"
  data-aos-once="true"
>
  <AnimatedLogo
    src='https://static.vecteezy.com/system/resources/previews/022/227/364/non_2x/openai-chatgpt-logo-icon-free-png.png'
    alt="Logo"
    data-aos="zoom-in"
    data-aos-duration="800" 
    data-aos-delay="400"
    data-aos-once="true"
  />
</Box>

<Box 
        sx={{ 
          textAlign: 'center',
          py: 6,
          background: 'linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.6))',
          borderRadius: '16px',
          my: 4,
          mx: 2
        }}
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <Container>
          <Typography 
            variant="h4" 
            component="h2" 
            gutterBottom 
            sx={{ mb: 4 }}
          >
            As Seen On
          </Typography>
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={4} md={3}>
              <PulsingCard>
                <Box 
                  component="a" 
                  href="https://memecoinseason.net/p/apeout" 
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',
                    textDecoration: 'none'
                  }}
                >
                  <img
                    src="https://memecoinseason.net/assets/logo/logo.png"
                    alt="Claude AI"
                    style={{ 
                      width: '80px', 
                      height: '80px',
                      marginBottom: '1rem'
                    }}
                  />
                  <Typography sx={{color: 'white'}} variant="subtitle1">
                    memecoinseason.net
                  </Typography>
                </Box>
              </PulsingCard>
            </Grid>
            {/* OpenAI */}
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://www.mintme.com/build/images/WEB.8a500667..svg"
              alt="OpenAI"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              MintMe
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>
      {/* OpenAI */}
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://pbs.twimg.com/profile_images/1659521244465602560/3Dpnso6C_400x400.jpg"
              alt="OpenAI"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              CryptHub
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://pbs.twimg.com/profile_images/1876593111419224065/5bVlhohW_400x400.png"
              alt="OpenAI"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              DexTools
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://play-lh.googleusercontent.com/cS10a-HBUpLz-pamX_bMQ8iH8Aj8g5a1IkPc2ERMSsNMeczHBYKg4gmDvnIS3qWpFg"
              alt="OpenAI"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              DexScreener
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://github.com/apeoutmeme/resources/blob/43745db806d12b37349b4407bb414630b8921fab/assets/Hackathon-2025-Solana-assets/hackathon.png?raw=true"
              alt="OpenAI"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              Solana AI Hackathon (SendAI)
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://github.com/apeoutmeme/resources/blob/43745db806d12b37349b4407bb414630b8921fab/assets/raydium.ico?raw=true"
              alt="OpenAI"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              Raydium
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>
          </Grid>
        </Container>
</Box>
<ErrorBoundary fallback={<div>Error loading powered by section</div>}>
        <Suspense fallback={<LoadingFallback />}>
          <PoweredBySection />
        </Suspense>
      </ErrorBoundary>

      <AnimatedSection>
        <RotatingBackground />
        <Container>
          <Grid container spacing={6}>
            <Grid item xs={12} data-aos="fade-down">
              <Typography variant="h3" component="h2" gutterBottom textAlign="center">
                AI-Powered Trading Experience
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} data-aos="zoom-in" data-aos-delay="200">
              <PulsingCard>
                <Box className="icon-wrapper">
                <img src="https://github.com/apeoutmeme/resources/blob/main/assets/badges/banana-alchemist.png?raw=true" alt="Bot" style={{ width: '48px', height: '48px' }} />
                </Box>
                <Typography sx={{color: 'white'}} variant="h5" gutterBottom>
                  Smart Trading Bots
                </Typography>
                <Typography sx={{color: 'white'}}>
                  Automated strategies that execute trades with precision and efficiency
                </Typography>
                <GlowingButton 
                  variant="contained" 
                  color="primary"
                  href="https://apeout.notion.site/16595c4b66bd80a8866fca86f11d9ee2?v=16595c4b66bd809fab47000c93c737ee"
                  target="_blank"
                >
                 Docs
                </GlowingButton>
              </PulsingCard>
            </Grid>
            <Grid item xs={12} md={4} data-aos="zoom-in" data-aos-delay="400">
              <PulsingCard>
                <Box className="icon-wrapper">
                  <Assessment sx={{ fontSize: 48, color: 'white' }} />
                </Box>
                <Typography sx={{color: 'white'}} variant="h5" gutterBottom>
                  Market Analysis
                </Typography>
               
                <Typography sx={{color: 'white'}}>
                  Real-time market data and AI-driven insights for informed trading
                </Typography>
                <GlowingButton 
                  variant="contained" 
                  color="primary"
                  href="/trending-tokens"
                  target="_blank"
                >
                  Try Now
                </GlowingButton>
              </PulsingCard>
            </Grid>
            <Grid item xs={12} md={4} data-aos="zoom-in" data-aos-delay="600">
              <PulsingCard>
                <Box className="icon-wrapper">
                <img src="https://github.com/apeoutmeme/resources/blob/06c61ea5960379266633d31924dd99f979e000d5/assets/strats/alpha_seekers.webp?raw=true" alt="Bot" style={{ width: '48px', height: '48px' }} />
                </Box>
                <Typography sx={{color: 'white'}} variant="h5" gutterBottom>
                  AI Agents
                </Typography>
                <Typography sx={{color: 'white'}}>
                  Automated social management and trading assistance
                </Typography>
                <GlowingButton 
                  variant="contained" 
                  color="primary"
                  href="/ai-agents"
                  target="_blank"
                >
                  Try Now
                </GlowingButton>
              </PulsingCard>
            </Grid>
          </Grid>
        </Container>
      </AnimatedSection>

      {/* Staking Section */}
      <ErrorBoundary fallback={<div>Error loading staking section</div>}>
        <Suspense fallback={<LoadingFallback />}>
          <StakingSection />
        </Suspense>
      </ErrorBoundary>

      {/* Launchpad Section */}
      <AnimatedSection>
        <RotatingBackground />
        <Container>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} data-aos="zoom-in-right" data-aos-duration="1500">
            <Box
  sx={{
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%', // Maintains 16:9 aspect ratio more reliably
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 4px 30px rgba(59, 130, 246, 0.3)',
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'transform 0.3s ease-in-out',
    }
  }}
>
  <img 
    src="https://github.com/apeoutmeme/resources/blob/25d45eafb420c43e12415d24d88abd4939b17f90/assets/common/14.png?raw=true" 
    alt="Launchpad"
    loading="lazy" // Add lazy loading
  />
</Box>
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} data-aos="fade-left" data-aos-duration="1500">
              <Typography variant="h3" component="h2" gutterBottom>
                Lootboxes
              </Typography>
              <Typography variant="h6" gutterBottom>
                Open Lootboxes to upgrade your bots and trading strategies
              </Typography>
              <GlowingButton 
                variant="contained" 
                size="large"
                startIcon={<Rocket />}
                href="/lootbox"
                target="_blank"
                sx={{ mt: 4 }}
              >
                Open Lootboxes
              </GlowingButton>
            </Grid>
            
          </Grid>
        </Container>
      </AnimatedSection>
      <AnimatedSection>
        <RotatingBackground />
        <Container>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} data-aos="zoom-in-right" data-aos-duration="1500">
            <Box sx={{ position: 'relative' }}>
            <Box
  sx={{
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%', // Maintains 16:9 aspect ratio more reliably
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 4px 30px rgba(59, 130, 246, 0.3)',
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'transform 0.3s ease-in-out',
    }
  }}
>
  <img 
    src="https://github.com/apeoutmeme/resources/blob/25d45eafb420c43e12415d24d88abd4939b17f90/assets/AIHardware/meme%20(2).png?raw=true" 
    alt="Launchpad"
    loading="lazy" // Add lazy loading
  />
</Box>
  <FloatingIcon sx={{ position: 'absolute', top: -20, left: -20 }}>
    <Rocket />
  </FloatingIcon>
</Box>
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} data-aos="fade-left" data-aos-duration="1500">
              <Typography variant="h3" component="h2" gutterBottom>
              Banana Bits Lab
              </Typography>
              <Typography variant="h6" gutterBottom>
              Building the Future of AI Hardware 🤖
              </Typography>
              <GlowingButton 
                variant="contained" 
                size="large"
                startIcon={<Rocket />}
                href="/hardware-projects"
                target="_blank"
                sx={{ mt: 4 }}
              >Get Physical AI Agents
                
              </GlowingButton>
            </Grid>
            
          </Grid>
        </Container>
      </AnimatedSection>

      {/* YouTube Video Section */}
      <ErrorBoundary fallback={<div>Error loading video section</div>}>
        <Suspense fallback={<LoadingFallback />}>
          <VideoSection />
        </Suspense>
      </ErrorBoundary>

      <AnimatedSection>
        <RotatingBackground />
        <Container>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} data-aos="zoom-in-right" data-aos-duration="1500">
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: 0,
                  paddingBottom: '56.25%',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  boxShadow: '0 4px 30px rgba(59, 130, 246, 0.3)',
                  '& img': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease-in-out',
                  }
                }}
              >
                <img 
                  src="https://github.com/apeoutmeme/resources/blob/25d45eafb420c43e12415d24d88abd4939b17f90/assets/bg.webp?raw=true" 
                  alt="Governance"
                  loading="lazy"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} data-aos="fade-left" data-aos-duration="1500">
              <Typography variant="h3" component="h2" gutterBottom>
                Governance
              </Typography>
              <Typography variant="h6" gutterBottom>
                Participate in shaping the future of our platform through decentralized governance
              </Typography>
              <GlowingButton 
                variant="contained" 
                size="large"
                startIcon={<Token />}
                href="/governance"
                target="_blank"
                sx={{ mt: 4 }}
              >
                Vote Now
              </GlowingButton>
            </Grid>
          </Grid>
        </Container>
      </AnimatedSection>

      {/* Social Footer */}
      <ErrorBoundary fallback={<div>Error loading footer</div>}>
        <Suspense fallback={<LoadingFallback />}>
          <FooterSection />
        </Suspense>
      </ErrorBoundary>
    </Box>
  );
};
export default LandingPage;