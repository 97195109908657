// src/components/GatedContent.js
import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PlayCircle, Description, TableChart } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import VideoModal from '../components/modals/VideoModal';
import { toast } from 'react-toastify';
import wojakVideo from '../assets/wojakbuysbanana.mp4';
import dexPaidVideo from '../assets/dexpaid.mp4';
import aiVideo from '../assets/ai-apeout.mp4';
import { useWallet } from '@solana/wallet-adapter-react';
import santaImg from '../assets/bananabill/christmas.webp'
import placeholderImg from '../assets/bananabill/logo.png'
import { Home } from '@mui/icons-material';
import PdfModal from '../components/modals/PdfModal';
import rinegatePdf from '../assets/0xrinegade2.pdf'
import optimalTimesPdf from '../assets/pdfs/optimal-times-to-run-a-pump-catching-bot.pdf'
import optimalTwitterPostingTimesPdf from '../assets/pdfs/optimal-twitter-posting-times-for-capturing-the-global-crypto-market.pdf'
import ExcelModal from '../components/modals/ExcelModal';
import tradingDataExcel from '../assets/pdfs/APEOUT.xlsx';
import tradingDataExcel2 from '../assets/pdfs/Token_Vesting_Allocation.csv';


const StyledContentBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#1a1a1a',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  margin: theme.spacing(4),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.2s',
  marginBottom: theme.spacing(3),
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[8]
  }
}));

const StyledCardMedia = styled(CardMedia)({
  height: 200,
  backgroundSize: 'cover'
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: 'auto',
  width: '100%',
  padding: theme.spacing(1.5),
  '&:hover': {
    backgroundColor: theme.palette.primary.dark
  }
}));

const ContentCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const episodes = [
  {
    id: 1,
    title: 'Banana Bill Meets Santa Clause 🎅🍌',
    description: 'One snowy December night, Banana Bill hears a clatter in the jungle. It’s not apes partying—it’s Santa Clause, delivering gifts to loyal $BNNA holders! 🎁“Ho ho ho! Who’s been a diamond-handed ape this year?” Santa asks. Bill steps forward, proudly showing his wallet filled with hodled $BNNA. Santa rewards him with a golden banana and whispers, “The Blockchain Jungle holds big surprises next year. Keep holding.”Banana Bill knows it’s going to be the best December yet.',
    thumbnail: 'https://github.com/apeoutmeme/resources/blob/04679be522d6cd823ad8691d4a87509269aa3369/assets/banana-bill-covers/bbills.png?raw=true',
    videoSrc: 'https://youtube.com/shorts/1pHgnynm2QQ?feature=share'
  },
  {
    id: 2,
    title: 'The Blockchain Solstice 🌌🍌',
    description: 'On the longest night of the year, the jungle gathers to celebrate the Blockchain Solstice, a festival of trading and prosperity. Banana Bill leads the charge, sharing wisdom about automation tools, like Auto-TP/SL and Auto-Ape. Fruits and apes alike exchange stories of their trades, toast to burned tokens, and set their sights on new goals for the year ahead. The stars above twinkle like memecoins, reminding everyone that the moon is closer than they think.',
    thumbnail: 'https://github.com/apeoutmeme/resources/blob/04679be522d6cd823ad8691d4a87509269aa3369/assets/banana-bill-covers/billsgreatcoinadventure.png?raw=true',
    videoSrc: dexPaidVideo
  },
  {
    id: 3,
    title: 'The Gift of Automation 🎄🍌',
    description: 'Banana Bill spends December teaching the jungle animals about ApeOut’s automation tools. “It’s the season of giving,” he says, gifting his friends the knowledge of Auto-Bump and Auto-Ape.As they test the tools, they realize the true gift isn’t just wealth—it’s the freedom to trade smarter and focus on what matters most. Bill smiles, knowing he’s helped make the Blockchain Jungle a better place.',
    thumbnail: 'https://github.com/apeoutmeme/resources/blob/04679be522d6cd823ad8691d4a87509269aa3369/assets/banana-bill-covers/cryptocaper.png?raw=true',
    videoSrc: aiVideo
  },
];
const NavContainer = styled(Box)({
    position: 'absolute',
    top: '20px',
    left: '20px',
    zIndex: 10,
  });

const GatedContent = ({ tradingWallet, isPremium }) => {
  const [selectedVideo, setSelectedVideo] = React.useState(null);
  const [bnnaBalance, setBnnaBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { publicKey } = useWallet();
  const [isNavigating, setIsNavigating] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [selectedExcelFile, setSelectedExcelFile] = useState(null);


  // Create a navigation handler
  const handleHomeNavigation = () => {
    setIsNavigating(true);
    window.location.href = '/'; // This will force a full page refresh
  };


  const fetchBnnaBalance = async () => {
    if (!publicKey) return;
    console.log('Fetching BNNA balance for:', publicKey.toString());
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/token-balance/${publicKey.toString()}/DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump`
      );
      const data = await response.json();
      
      if (data.success) {
        setBnnaBalance(data.balance);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching BNNA balance:', error);
      toast.error('Error checking BNNA balance');
      setIsLoading(false);
    }
  };


  useEffect(() => {
    console.log('useEffect triggered');
    console.log('publicKey:', publicKey);
    if (publicKey) {
      fetchBnnaBalance();
    }
  }, [publicKey]);

  if (isLoading) {
    return (
      <Box sx={{ 
        minHeight: '60vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: '#1a1a1a',
        color: 'white'
      }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (!publicKey || bnnaBalance <= 0) {
    return (
      <Box 
        sx={{ 
          minHeight: '60vh', 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          bgcolor: '#1a1a1a',
          color: 'white',
          textAlign: 'center',
          p: 4
        }}
      >
        <Typography variant="h4" gutterBottom>
          Access Restricted
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          You need to hold BNNA tokens to access this exclusive content
        </Typography>
        <Button 
          variant="contained" 
          onClick={() => window.location.href = 'https://dexscreener.com/solana/g3rhsoewmmvembdkbec6sfwu91rbmkdyeztwdnmawjxb'}
          sx={{
            background: 'linear-gradient(45deg, #FF6B6B 30%, #FF8E53 90%)',
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
          }}
        >
          Get BNNA Tokens
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: '#1a1a1a', minHeight: '100vh', p: 4 }}>
        <NavContainer>
      <Button
        onClick={handleHomeNavigation}
        disabled={isNavigating}
        variant="contained"
        startIcon={<Home />}
        sx={{
          background: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(10px)',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.2)',
          },
        }}
      >
        Back Home
      </Button>
    </NavContainer>
      <Typography 
        variant="h3" 
        sx={{ 
          color: 'white', 
          textAlign: 'center', 
          mb: 6,
          fontWeight: 'bold'
        }}
      >
        Exclusive Content
      </Typography>
      <Typography variant="body1" sx={{ color: 'white', textAlign: 'center', mb: 6 }}>
        Welcome to the Banana Bill's exclusive content. Here you can watch the latest UNRELEASED videos and learn about the latest news and updates.
      </Typography>

      <Grid container spacing={4}>
        {episodes.map((episode) => (
          <Grid item xs={12} md={4} key={episode.id}>
            <ContentCard>
              <CardMedia
                component="img"
                height="200"
                image={episode.thumbnail}
                alt={episode.title}
              />
              <CardContent sx={{ color: 'white' }}>
                <Typography variant="h6" gutterBottom>
                  {episode.title}
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  {episode.description}
                </Typography>
                <Button
                  startIcon={<PlayCircle />}
                  onClick={() => setSelectedVideo(episode.videoSrc)}
                  sx={{
                    color: 'white',
                    borderColor: 'white',
                    '&:hover': {
                      borderColor: 'primary.main',
                      color: 'primary.main',
                    }
                  }}
                >
                  Watch Now
                </Button>
                
              </CardContent>
            </ContentCard>
          </Grid>
        ))}
      </Grid>

      <StyledContentBox>
      <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <ContentCard sx={{ marginBottom: '20px' }}>

        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: 'white' }}>
        <Box className="w-full h-48 mb-4 overflow-hidden rounded-t-lg">
  <img 
    src=""
    alt="Content thumbnail"
    className="w-full h-full object-cover"
  />
</Box>
              
              <Typography sx={{ 
    mb: 2,
    fontWeight: 'bold',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }} variant="h6" gutterBottom>
                Exclusive Documentation: $0xRinegade - Serial Rugger, $RIN, $CHAN, $SVMAI EXPOSED
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Learn about the serial rugger $0xRinegade, $RIN, $CHAN, $SVMAI, and how to avoid them.
              </Typography>
              <Button
                startIcon={<Description />}
                onClick={() => setSelectedPdf(rinegatePdf)}
                className="w-full justify-center py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-colors duration-200"

                sx={{
                  color: 'white',
                  borderColor: 'white',
                  '&:hover': {
                    borderColor: 'primary.main',
                    color: 'primary.main',
                  }
                }}
              >
                View PDF
              </Button>
            </CardContent>
          </ContentCard>
          <ContentCard sx={{ marginBottom: '20px' }}>
            <CardContent sx={{ color: 'white' }}>
              <Typography sx={{ 
    mb: 2,
    fontWeight: 'bold',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }} variant="h6" gutterBottom>
                Exclusive Documentation: Optimal Twitter Posting Times for Capturing the Global Crypto Market
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Learn the optimal times to post on Twitter to maximize engagement with the global crypto community, backed by data analysis and market research.
              </Typography>
              <Button
                startIcon={<Description />}
                onClick={() => setSelectedPdf(optimalTwitterPostingTimesPdf)}
                sx={{
                  color: 'white',
                  borderColor: 'white',
                  '&:hover': {
                    borderColor: 'primary.main',
                    color: 'primary.main',
                  }
                }}
              >
                View PDF
              </Button>
            </CardContent>
          </ContentCard>
          <ContentCard sx={{ marginBottom: '20px' }}>
            <CardContent sx={{ color: 'white' }}>
              <Typography sx={{ 
    mb: 2,
    fontWeight: 'bold',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }} variant="h6" gutterBottom>
                Exclusive Documentation: Optimal Times to Run a Pump-Catching
                Bot
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Learn the optimal times to run a pump-catching bot to maximize profits, backed by data analysis and market research.
              </Typography>
              <Button
                startIcon={<Description />}
                onClick={() => setSelectedPdf(optimalTimesPdf)}
                sx={{
                  color: 'white',
                  borderColor: 'white',
                  '&:hover': {
                    borderColor: 'primary.main',
                    color: 'primary.main',
                  }
                }}
              >
                View PDF
              </Button>
            </CardContent>
            
          </ContentCard>
          <ContentCard sx={{ marginBottom: '20px' }}>
            <CardContent sx={{ color: 'white' }}>
              <Typography sx={{ 
    mb: 2,
    fontWeight: 'bold',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }} variant="h6" gutterBottom>
                Press Release: ApeOut Meme Token $BNNA
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Learn about the ApeOut Meme Token $BNNA and how it's changing the game.
              </Typography>
              <Button
                startIcon={<TableChart />}
                onClick={() => setSelectedExcelFile(tradingDataExcel)}
                sx={{
                  color: 'white',
                  borderColor: 'white',
                  '&:hover': {
                    borderColor: 'primary.main',
                    color: 'primary.main',
                  }
                }}
              >
                View Spreadsheet
              </Button>
            </CardContent>
            
          </ContentCard>
          <ContentCard sx={{ marginBottom: '20px' }}>
  <CardContent sx={{ color: 'white' }}>
    <Typography sx={{ 
    mb: 2,
    fontWeight: 'bold',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }} variant="h6" gutterBottom>
      Token Vesting Allocation
    </Typography>
    <Typography variant="body2" sx={{ mb: 2 }}>
      Access detailed information about token vesting schedules and allocation data.
    </Typography>
    <Button
      startIcon={<TableChart />}
      onClick={() => setSelectedExcelFile(tradingDataExcel2)}
      sx={{
        color: 'white',
        borderColor: 'white',
        '&:hover': {
          borderColor: 'primary.main',
          color: 'primary.main',
        }
      }}
    >
      Download Vesting Data
    </Button>
  </CardContent>
</ContentCard>
        </Grid>
      </Grid>
      </StyledContentBox>


      <PdfModal
        open={Boolean(selectedPdf)}
        onClose={() => setSelectedPdf(null)}
        pdfUrl={selectedPdf}
      />

      <VideoModal
        open={Boolean(selectedVideo)}
        onClose={() => setSelectedVideo(null)}
        videoSrc={selectedVideo}
      />
      <ExcelModal
        open={Boolean(selectedExcelFile)}
        onClose={() => setSelectedExcelFile(null)}
        fileUrl={selectedExcelFile}
      />

    </Box>
  );
};

export default GatedContent;