import React from 'react';
import { Card, CardContent, Typography, Button, Box, Link } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FaTiktok } from 'react-icons/fa';
import { styled } from '@mui/material/styles';
import { Receipt } from '@mui/icons-material';
import { VideogameAsset } from '@mui/icons-material';
import { Book } from '@mui/icons-material';
import { IconButton } from '@mui/material'; 


const SocialFooter = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0.7))',
  backdropFilter: 'blur(10px)',
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(3),
  zIndex: 1000,
}));
const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-3px)',
    color: '#3b82f6',
  },
}));
const PawchainPage = () => {
  const services = [
    {
      title: 'Scanner',
      description: 'Explore transactions and tokens on Pawchain',
      url: 'https://scanner.pawchain.net/',
      buttonText: 'Open Scanner'
    },
    {
      title: 'Swap',
      description: 'Trade tokens on Pawchain DEX',
      url: 'https://swap.pawchain.net/',
      buttonText: 'Start Trading'
    },
    {
      title: 'Wallet',
      description: 'Manage your Pawchain assets',
      url: 'https://wallet.pawchain.net/',
      buttonText: 'Access Wallet'
    },
    {
      title: 'Validator',
      description: 'Become a Pawchain validator',
      url: 'https://wallet.pawchain.net/validator',
      buttonText: 'Become Validator'
    }
  ];

  return (
    <>
    <div className="app-container" style={{ backgroundColor: '#f5f8ff' }}>
      <Box 
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
          color: '#1a237e',
          minHeight: '100vh'
        }}
      >
        <Box 
  sx={{
    width: '100%',
    maxWidth: '1200px',
    mb: 4,
    borderRadius: '16px',
    overflow: 'hidden',
    boxShadow: '0 4px 6px rgba(26, 35, 126, 0.1)',
  }}
>
  <Box
    component="img"
    src="https://pbs.twimg.com/profile_images/1806777459100254208/Xg1slPzk_400x400.jpg"  // Using the placeholder API as specified
    alt="Pawchain Ecosystem Hero"
    sx={{
      width: '100%',
      height: '300px',
      objectFit: 'cover',
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'scale(1.02)'
      }
    }}
  />
</Box>
        <Typography variant="h3" component="h1" sx={{ 
          mb: 4,
          fontWeight: 'bold',
          background: 'linear-gradient(45deg, #1a237e 30%, #3f51b5 90%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}>
          Pawchain Ecosystem
        </Typography>

        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, 
          gap: 3, 
          width: '100%', 
          maxWidth: '1200px' 
        }}>
          {services.map((service, index) => (
            <Card 
              key={index} 
              sx={{ 
                bgcolor: 'white',
                color: '#1a237e',
                transition: 'all 0.3s ease',
                boxShadow: '0 4px 6px rgba(26, 35, 126, 0.1)',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 8px 12px rgba(26, 35, 126, 0.2)'
                }
              }}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography variant="h5" component="h2" sx={{ 
                  mb: 2,
                  color: '#1a237e',
                  fontWeight: 600
                }}>
                  {service.title}
                </Typography>
                <Typography sx={{ mb: 3, color: '#3f51b5' }}>
                  {service.description}
                </Typography>
                <Button 
                  variant="contained" 
                  component={Link}
                  href={service.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    bgcolor: '#3f51b5',
                    '&:hover': {
                      bgcolor: '#1a237e'
                    }
                  }}
                >
                  {service.buttonText}
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>

        {/* Add after the services grid Box and before Quick Links Card */}
<Card sx={{ 
  bgcolor: 'white',
  color: '#1a237e',
  width: '100%',
  maxWidth: '1200px',
  mt: 4,
  boxShadow: '0 4px 6px rgba(26, 35, 126, 0.1)',
  '&:hover': {
    boxShadow: '0 8px 12px rgba(26, 35, 126, 0.2)'
  }
}}>
  <CardContent>
    <Typography variant="h4" component="h2" sx={{ 
      mb: 3,
      fontWeight: 'bold',
      color: '#1a237e'
    }}>
      🎯 Multi-Chain Airdrop Qualification
    </Typography>

    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" sx={{ 
        color: '#1a237e',
        mb: 2,
        fontWeight: 600
      }}>
        Step 1: Connect Your Pawchain Wallet
      </Typography>
      <Typography sx={{ color: '#3f51b5', mb: 2 }}>
        First, connect your MetaMask wallet to verify your Pawchain assets and activity.
      </Typography>
      <Button 
        variant="contained"
        onClick={() => window.ethereum && window.ethereum.request({ method: 'eth_requestAccounts' })}
        sx={{
          bgcolor: '#3f51b5',
          mb: 3,
          '&:hover': {
            bgcolor: '#1a237e'
          }
        }}
      >
        Connect MetaMask
      </Button>
    </Box>

    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" sx={{ 
        color: '#1a237e',
        mb: 2,
        fontWeight: 600
      }}>
        Step 2: Create Solana Trading Wallet
      </Typography>
      <Typography sx={{ color: '#3f51b5', mb: 2 }}>
        Visit our Solana app to create your trading wallet and expand your cross-chain presence.
      </Typography>
      <Button 
        variant="contained"
        component={Link}
        href="/app"
        sx={{
          bgcolor: '#3f51b5',
          mb: 3,
          '&:hover': {
            bgcolor: '#1a237e'
          }
        }}
      >
        Create Solana Wallet
      </Button>
    </Box>

    <Box sx={{
      bgcolor: 'rgba(63, 81, 181, 0.1)',
      p: 2,
      borderRadius: 1,
      border: '1px solid #3f51b5'
    }}>
      <Typography variant="h6" sx={{ 
        color: '#1a237e',
        mb: 2,
        fontWeight: 600
      }}>
        🎁 Airdrop Benefits
      </Typography>
      <Box component="ul" sx={{ 
        color: '#3f51b5',
        ml: 2,
        '& > li': { mb: 1 }
      }}>
        <li>Early adopter bonus rewards</li>
        <li>Multi-chain trading capabilities</li>
        <li>Access to exclusive features</li>
        <li>Priority access to future launches</li>
      </Box>
      <Typography sx={{ 
        color: '#1a237e',
        mt: 2,
        fontStyle: 'italic',
        fontWeight: 500
      }}>
        Don't miss out! Complete both steps to ensure your eligibility for our upcoming multi-chain airdrop.
      </Typography>
    </Box>
  </CardContent>
</Card>

        <Card sx={{ 
          bgcolor: 'white',
          color: '#1a237e',
          width: '100%',
          maxWidth: '1200px',
          mt: 4,
          boxShadow: '0 4px 6px rgba(26, 35, 126, 0.1)'
        }}>
          <CardContent>
            <Typography variant="h6" component="h3" sx={{ 
              mb: 2,
              fontWeight: 600,
              color: '#1a237e'
            }}>
              Quick Links & Information
            </Typography>
            <Typography component="div" sx={{ mb: 2, color: '#3f51b5' }}>
              • Official Website: <Link href="https://www.pawchain.net/" target="_blank" rel="noopener noreferrer" sx={{ color: '#3f51b5', '&:hover': { color: '#1a237e' } }}>pawchain.net</Link>
            </Typography>
            <Typography component="div" sx={{ mb: 2, color: '#3f51b5' }}>
              • Token Contract: <Box component="span" sx={{ fontFamily: 'monospace', color: '#1a237e' }}>pph64y5Fsuq4GHrlIclu9v4CuFbHodDhukqrnBJElzv3czn2</Box>
            </Typography>
            <Typography component="div" sx={{ color: '#3f51b5' }}>
              • Wallet Address Example: <Box component="span" sx={{ fontFamily: 'monospace', color: '#1a237e' }}>pawxeIJlgFqD63e0mvryHGm64n9s724627vDF5HHlDm7c6m4zdln0p</Box>
            </Typography>
          </CardContent>
        </Card>

        <Typography 
          className="disclaimer"
          sx={{ 
            mt: 4,
            mb: 11,
            maxWidth: '1200px', 
            width: '100%',
            color: '#3f51b5',
            bgcolor: 'rgba(63, 81, 181, 0.1)',
            p: 2,
            borderRadius: 1,
            border: '1px solid #3f51b5'
          }}
        >
          Always verify URLs and contract addresses before interacting with any blockchain services.
        </Typography>
      </Box>
      
    </div>
    <SocialFooter>
    <SocialIconButton
    href="https://x.com/apeoutmeme"
    target="blank"
    aria-label="Twitter"
    >
    <TwitterIcon />
    </SocialIconButton>
    <SocialIconButton
    href="https://t.me/apeoutmeme
    "
    target="blank"
    aria-label="Telegram"
    >
    <TelegramIcon />
    </SocialIconButton>
    <SocialIconButton
    href="https://github.com/apeoutmeme/apeoutdotfun-
    "
    target="blank"
    aria-label="GitHub"
    >
    <GitHubIcon />
    </SocialIconButton>
    <SocialIconButton
    href="https://www.youtube.com/shorts/f4OeszM6ukE"
    target="blank"
    aria-label="YouTube"
    >
    
    <YouTubeIcon />
    </SocialIconButton>
    <SocialIconButton
    href="https://apeout.meme/whitepaper"
    target="blank"
    aria-label="Whitepaper"
    >
    <Receipt />
    </SocialIconButton>
    
    <SocialIconButton
    href="https://medium.com/@apeoutcrypto"
    target="blank"
    aria-label="Whitepaper"
    >
    <Book />
    </SocialIconButton>
    <SocialIconButton
      href="https://tiktok.com/@apeoutmeme" // Add your TikTok URL
      target="_blank"
      rel="noopener noreferrer"
      aria-label="TikTok"
    >
      <FaTiktok />
    </SocialIconButton>
    
    </SocialFooter>
    </>
  );
};

export default PawchainPage;