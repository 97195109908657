import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Container,
  Link,
  styled
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const TwitterButton = styled(Button)({
    background: '#1DA1F2',
    color: 'white',
    padding: '12px 24px',
    borderRadius: '8px',
    '&:hover': {
      background: '#1A8CD8',
    },
    display: 'flex',
    gap: '8px',
    alignItems: 'center'
  });


// Create theme with Cronos colors
const theme = createTheme({
  palette: {
    primary: {
      main: '#002D74', // Cronos Blue
      light: '#1199FA',
      dark: '#001B44',
    },
    secondary: {
      main: '#1199FA', // Cronos Light Blue
      light: '#4DB8FF',
      dark: '#0066CC',
    },
    background: {
      default: '#0A0B0D',
      paper: 'rgba(0, 45, 116, 0.9)',
    },
  },
});

const StyledContainer = styled(Container)({
  minHeight: '100vh',
  background: 'linear-gradient(to bottom, #111827, #000000)',
  padding: '32px 16px'
});

const StyledCard = styled(Card)({
  background: 'linear-gradient(135deg, rgba(17, 153, 250, 0.1), rgba(0, 45, 116, 0.2))',
  backdropFilter: 'blur(16px)',
  border: '1px solid rgba(17, 153, 250, 0.2)',
  borderRadius: '16px',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 8px 24px rgba(17, 153, 250, 0.15)',
  }
});

const StyledButton = styled(Button)({
  background: 'linear-gradient(45deg, #1199FA 30%, #002D74 90%)',
  borderRadius: '8px',
  border: 0,
  color: 'white',
  padding: '12px 24px',
  marginTop: '8px',
  fontWeight: 'bold',
  '&:hover': {
    background: 'linear-gradient(45deg, #002D74 30%, #1199FA 90%)',
  }
});

const TelegramButton = styled(Button)({
  background: '#229ED9',
  color: 'white',
  padding: '12px 24px',
  borderRadius: '8px',
  '&:hover': {
    background: '#1E8BC3',
  },
  display: 'flex',
  gap: '8px',
  alignItems: 'center'
});

const CronosDashboard = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [account, setAccount] = useState('');
  
  const nftProjects = [
    {
      name: "Crooks Of Cronos",
      description: "Hold 1 Cronos Crook  NFT to qualify for the airdrop allocation",
      image: "https://ugc.production.linktr.ee/7jryKCWlTVeQx9CE3ECo_3uY74Gdkc7B4QodL?io=true&size=avatar-v3_0",
      required: 1
    },
    {
      name: "CroBo Cop NFTs",
      description: "Hold 1 Crobadge NFTs to qualify for the airdrop allocation",
      image: "https://pbs.twimg.com/profile_images/1672604650623889409/zbCN6_68_400x400.jpg",
      required: 2
    },
    // {
    //   name: "Cronos Bears",
    //   description: "Hold 3 Cronos Bears NFTs to qualify for the premium airdrop tier",
    //   image: "/api/placeholder/400/320",
    //   required: 3
    // }
  ];

  const connectWallet = async () => {
    if (!window.ethereum) {
      toast.error('Please install a Cronos-compatible wallet!');
      return;
    }

    try {
      const accounts = await window.ethereum.request({ 
        method: 'eth_requestAccounts' 
      });
      
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x19' }], // Cronos chainId
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId: '0x19',
              chainName: 'Cronos Mainnet',
              nativeCurrency: {
                name: 'CRO',
                symbol: 'CRO',
                decimals: 18
              },
              rpcUrls: ['https://evm.cronos.org'],
              blockExplorerUrls: ['https://cronoscan.com/']
            }]
          });
        }
      }

      setAccount(accounts[0]);
      setIsConnected(true);
      
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to connect wallet');
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.request({ method: 'eth_accounts' })
        .then(accounts => {
          if (accounts.length > 0) {
            setAccount(accounts[0]);
            setIsConnected(true);
          }
        });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <StyledContainer maxWidth="xl">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <Typography variant="h3" component="h1" sx={{ color: 'white', fontWeight: 'bold' }}>
            ApeOut Cronos
          </Typography>
          
          <Button
            variant="contained"
            onClick={connectWallet}
            sx={{
              background: isConnected 
                ? 'linear-gradient(45deg, #1199FA 30%, #002D74 90%)'
                : 'linear-gradient(45deg, #1199FA 30%, #002D74 90%)',
              color: 'white',
              p: '12px 24px',
              borderRadius: '12px',
              '&:hover': {
                background: 'linear-gradient(45deg, #002D74 30%, #1199FA 90%)'
              }
            }}
          >
            {isConnected 
              ? `${account.slice(0, 6)}...${account.slice(-4)}` 
              : 'Connect Wallet'}
          </Button>
        </Box>

        {/* Telegram Bot Section */}
        <StyledCard sx={{ mb: 4, p: 3 }}>
          <Typography variant="h5" sx={{ color: 'white', mb: 2 }}>
            ApeOut Cronos Trading Bot
          </Typography>
          <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 3 }}>
            Get instant access to our Cronos trading bot on Telegram. Track new tokens, set up alerts, and execute trades directly from your chat.
          </Typography>
          <TelegramButton
            component={Link}
            href="https://t.me/apeout_cronos_bot"
            target="_blank"
            rel="noopener noreferrer"
          >
            📱 Join our Telegram Bot
          </TelegramButton>
        </StyledCard>

        <StyledCard sx={{ mb: 4, p: 3 }}>
  <Typography variant="h5" sx={{ color: 'white', mb: 2 }}>
    In Partnership With
  </Typography>
  <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 3 }}>
    Proud to be partnering with leading Cronos ecosystem projects
  </Typography>
  <TwitterButton
    component={Link}
    href="https://x.com/CroCryptoClub"
    target="_blank"
    rel="noopener noreferrer"
  >
    🐦 @CroCryptoClub
  </TwitterButton>
</StyledCard>

        {/* NFT Requirements Section */}
        <Typography variant="h4" sx={{ color: 'white', mb: 3 }}>
          $BNNA Airdrop Requirements
        </Typography>
        
        <Grid container spacing={3}>
          {nftProjects.map((project, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <StyledCard>
                <CardMedia
                  component="img"
                  height="200"
                  image={project.image}
                  alt={project.name}
                />
                <CardContent>
                  <Typography variant="h6" sx={{ color: 'white', mb: 1 }}>
                    {project.name}
                  </Typography>
                  <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 2 }}>
                    {project.description}
                  </Typography>
                  <Typography sx={{ color: '#1199FA', fontWeight: 'bold' }}>
                    Required: {project.required} NFT{project.required > 1 ? 's' : ''}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
        {/* Airdrop Info Section */}
<StyledCard sx={{ mb: 4, p: 3 }}>
  <Typography variant="h4" sx={{ color: 'white', mb: 2 }}>
    Massive Community Airdrop 🎉
  </Typography>
  
  <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 3 }}>
    We're launching the largest airdrop campaign in Cronos history for our early community supporters!
  </Typography>

  <Typography variant="h6" sx={{ color: '#1199FA', mb: 2 }}>
    Eligible Communities
  </Typography>
  
  <Box component="ul" sx={{ color: 'rgba(255, 255, 255, 0.7)', ml: 3, mb: 3 }}>
    <li>Crooks of Cronos NFT Holders</li>
    <li>cGOLD Token Holders</li>
    <li>CroBadge NFT Holders</li>
    <li>Early ApeOut Cronos Bot Users</li>
  </Box>

  <Typography variant="h6" sx={{ color: '#1199FA', mb: 2 }}>
    Airdrop Tokens
  </Typography>

  <Grid container spacing={2} sx={{ mb: 3 }}>
    <Grid item xs={12} md={6}>
      <Box sx={{ 
        p: 2, 
        border: '1px solid rgba(17, 153, 250, 0.3)',
        borderRadius: '8px',
        background: 'rgba(17, 153, 250, 0.1)'
      }}>
        <Typography sx={{ color: 'white', fontWeight: 'bold', mb: 1 }}>
          $BNNA Token
        </Typography>
        <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
          The primary utility token for ApeOut's trading ecosystem
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={12} md={6}>
      <Box sx={{ 
        p: 2, 
        border: '1px solid rgba(17, 153, 250, 0.3)',
        borderRadius: '8px',
        background: 'rgba(17, 153, 250, 0.1)'
      }}>
        <Typography sx={{ color: 'white', fontWeight: 'bold', mb: 1 }}>
          $GAPE Token
        </Typography>
        <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
          Governance token for platform decisions and premium features
        </Typography>
      </Box>
    </Grid>
  </Grid>

  <Typography sx={{ 
    color: 'rgba(255, 255, 255, 0.9)',
    fontStyle: 'italic',
    p: 2,
    borderLeft: '4px solid #1199FA'
  }}>
    Snapshot date and claim details will be announced soon. Make sure you're holding the required tokens/NFTs in your wallet to qualify!
  </Typography>
</StyledCard>

        {/* Additional Info Section */}
        <StyledCard sx={{ mt: 4, p: 3 }}>
          <Typography variant="h5" sx={{ color: 'white', mb: 2 }}>
            Why Cronos?
          </Typography>
          <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 2 }}>
            Cronos offers fast transactions, low fees, and a growing DeFi ecosystem. Our integration brings ApeOut's powerful trading tools to the Cronos blockchain, enabling seamless trading experiences for CRC-20 tokens.
          </Typography>
          <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            Join our growing community of Cronos traders and take advantage of:
          </Typography>
          <Box component="ul" sx={{ color: 'rgba(255, 255, 255, 0.7)', ml: 3 }}>
            <li>Automated trading strategies</li>
            <li>Real-time price alerts</li>
            <li>New token notifications</li>
            <li>Community-driven insights</li>
          </Box>
        </StyledCard>

        <ToastContainer position="bottom-right" />
      </StyledContainer>
    </ThemeProvider>
  );
};

export default CronosDashboard;