// Enhanced request handler with rate limiting and queuing
const enhancedRequestHandler = {
    queue: [],
    processing: false,
    rateLimits: new Map(),
    backoffTimes: new Map(),
    
    // Configure rate limits per endpoint
    config: {
      defaultLimit: 2, // requests per interval
      defaultInterval: 1000, // 1 second
      maxRetries: 3,
      baseBackoff: 1000, // 1 second
    },
  
    // Calculate backoff time
    getBackoffTime(endpoint) {
      const attempts = this.backoffTimes.get(endpoint) || 0;
      return Math.min(this.config.baseBackoff * Math.pow(2, attempts), 16000);
    },
  
    // Check if request is allowed
    canMakeRequest(endpoint) {
      const now = Date.now();
      const limit = this.rateLimits.get(endpoint) || {
        count: 0,
        resetTime: now + this.config.defaultInterval
      };
  
      if (now > limit.resetTime) {
        limit.count = 0;
        limit.resetTime = now + this.config.defaultInterval;
      }
  
      return limit.count < this.config.defaultLimit;
    },
  
    // Add request to queue
    async enqueue(endpoint, requestFn) {
      return new Promise((resolve, reject) => {
        this.queue.push({
          endpoint,
          requestFn,
          resolve,
          reject,
          attempts: 0
        });
        
        if (!this.processing) {
          this.processQueue();
        }
      });
    },
  
    // Process queue
    async processQueue() {
      if (this.processing || this.queue.length === 0) return;
      this.processing = true;
  
      while (this.queue.length > 0) {
        const request = this.queue[0];
        
        if (this.canMakeRequest(request.endpoint)) {
          try {
            // Update rate limit counters
            const limit = this.rateLimits.get(request.endpoint) || {
              count: 0,
              resetTime: Date.now() + this.config.defaultInterval
            };
            limit.count++;
            this.rateLimits.set(request.endpoint, limit);
  
            // Execute request
            const result = await request.requestFn();
            request.resolve(result);
            this.queue.shift();
            this.backoffTimes.delete(request.endpoint);
          } catch (error) {
            request.attempts++;
            
            if (request.attempts >= this.config.maxRetries) {
              request.reject(error);
              this.queue.shift();
            } else {
              // Move to end of queue with backoff
              this.queue.shift();
              const backoff = this.getBackoffTime(request.endpoint);
              this.backoffTimes.set(request.endpoint, request.attempts);
              setTimeout(() => {
                this.queue.push(request);
              }, backoff);
            }
          }
        }
  
        // Add delay between requests
        await new Promise(resolve => setTimeout(resolve, 500));
      }
  
      this.processing = false;
    },
  
    // Helper method to wrap API calls
    async makeRequest(endpoint, requestFn) {
      return this.enqueue(endpoint, async () => {
        try {
          return await requestFn();
        } catch (error) {
          if (error.message.includes('429')) {
            throw new Error('Rate limit exceeded');
          }
          throw error;
        }
      });
    }
  };
  
  export default enhancedRequestHandler;