import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  CardMedia, 
  Button, 
  TextField,
  Grid,
  Paper,
  Chip,
  Modal
} from '@mui/material';

import CryptoKickstarterStats from '../components/features/banana-bits/CryptoKickstarterStats';
import CryptoKickstarterModal from '../components/features/banana-bits/CryptoKickstarterModal';
import { Home } from '@mui/icons-material';


const HeroSection = () => (
  <Box
    sx={{
      mb: 6,
      p: 4,
      borderRadius: '20px',
      background: 'linear-gradient(145deg, rgba(42, 72, 42, 0.9) 0%, rgba(26, 47, 24, 0.9) 100%)',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(156, 255, 87, 0.1)',
      boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
    }}
  >
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} md={6}>
        <Typography variant="h1" sx={{ color: '#9cff57', fontFamily: 'Poppins, sans-serif', fontWeight: 800, mb: 2, textShadow: '2px 2px 4px rgba(0,0,0,0.3)' }}>Banana Bits Lab</Typography>
        <Typography
          variant="h3"
          sx={{
            color: '#9cff57',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 800,
            mb: 2,
            textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
          }}
        >
          Building the Future of AI Hardware 🤖
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: '#e0e0e0',
            mb: 3,
            lineHeight: 1.6,
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          We're combining the power of Raspberry Pi and Arduino with Python to create innovative AI-driven hardware solutions for the crypto community.
        </Typography>
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="body1"
            sx={{
              color: '#9cff57',
              mb: 2,
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            🔧 Tech Stack:
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            {['Python', 'Raspberry Pi', 'Arduino', 'TensorFlow', 'Computer Vision'].map((tech) => (
              <Chip
                key={tech}
                label={tech}
                sx={{
                  background: 'rgba(156, 255, 87, 0.1)',
                  color: '#9cff57',
                  border: '1px solid rgba(156, 255, 87, 0.3)',
                  '&:hover': {
                    background: 'rgba(156, 255, 87, 0.2)',
                  },
                }}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <Button
            variant="contained"
            href="/roadmap"
            sx={{
              background: 'linear-gradient(45deg, #9cff57 30%, #66bb6a 90%)',
              color: '#0d1810',
              fontWeight: 600,
              padding: '12px 30px',
              borderRadius: '25px',
              fontSize: '1.1rem',
              '&:hover': {
                background: 'linear-gradient(45deg, #66bb6a 30%, #9cff57 90%)',
                transform: 'scale(1.05)',
                transition: 'all 0.2s ease-in-out',
              },
            }}
          >
            🎁 Support Our Projects
          </Button>
          <Button
            variant="outlined"
            href="https://github.com/apeoutmeme"
            target="_blank"
            sx={{
              color: '#9cff57',
              borderColor: '#9cff57',
              padding: '12px 30px',
              borderRadius: '25px',
              fontSize: '1.1rem',
              '&:hover': {
                borderColor: '#66bb6a',
                background: 'rgba(156, 255, 87, 0.1)',
              },
            }}
          >
            💻 View on GitHub
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'linear-gradient(45deg, rgba(156, 255, 87, 0.1), transparent)',
              borderRadius: '20px',
              zIndex: 1,
            },
          }}
        >
          <Box
            component="img"
            src="https://github.com/apeoutmeme/resources/blob/25d45eafb420c43e12415d24d88abd4939b17f90/assets/AIHardware/meme%20(2).png?raw=true"
            alt="AI Hardware Prototype"
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: '20px',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 20,
              left: 20,
              background: 'rgba(13, 24, 16, 0.9)',
              padding: '15px',
              borderRadius: '15px',
              backdropFilter: 'blur(10px)',
              zIndex: 2,
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: '#9cff57', fontFamily: 'Poppins, sans-serif' }}
            >
              🔥 Latest Prototype: AI Trading Display v2.0
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

const projectOptions = [
  {
    id: 1,
    title: "Physical AI Trading Robot",
    description: "A desktop robot with LED panel showing real-time trading signals from the ApeOut AI platform. Features light animations and sound effects for successful trades and snipes. Integrates directly with our trading API to provide visual feedback for your trading activities.",
    imageUrl: "https://github.com/apeoutmeme/resources/blob/25d45eafb420c43e12415d24d88abd4939b17f90/assets/AIHardware/meme%20(2).png?raw=true",
    votes: 0,
    integration: "Raspberry Pi pulls trading data via APIs and triggers LED animations/sounds for events",
    benefits: "Visual trade monitoring, instant notifications, interactive experience",
    pledged: "1,000,000 $BNNA",
      goal: "2,000,000 $BNNA",
      backers: 320,
  },
  {
    id: 2,
    title: "Price Ticker Display",
    description: "A sleek desktop gadget displaying live crypto prices, trending memecoins, and BNNA token status. Features an e-ink or LCD display for clear visibility and low power consumption. Real-time updates keep you informed of market movements.",
    imageUrl: "https://github.com/apeoutmeme/resources/blob/25d45eafb420c43e12415d24d88abd4939b17f90/assets/AIHardware/meme%20(3).png?raw=true",
    votes: 0,
    integration: "Connects to platform API for real-time token data display",
    benefits: "At-a-glance market updates, energy efficient, always-on monitoring",
    pledged: "750,000 $BNNA",
      goal: "1,500,000 $BNNA",
      backers: 200,
  },
  {
    id: 3,
    title: "Trading Sentiment Monitor",
    description: "Advanced sentiment analysis display showing 'To the Moon' or 'HODL' levels based on AI-driven social media analysis. Monitors Twitter and Telegram trends to gauge market sentiment in real-time.",
    imageUrl: "https://github.com/apeoutmeme/resources/blob/25d45eafb420c43e12415d24d88abd4939b17f90/assets/AIHardware/meme%20(4).png?raw=true",
    votes: 0,
    integration: "Processes social media sentiment through AI algorithms",
    benefits: "Enhanced market insight, visual trend analysis, improved trading decisions",
    pledged: "750,000 $BNNA",
      goal: "1,500,000 $BNNA",
      backers: 6,
  },
//   {
//     id: 4,
//     title: "Automated Airdrop Manager",
//     description: "Streamlined airdrop management system handling wallet verification and token distribution. Perfect for managing Top 100 Club incentives and community rewards with automated scheduling.",
//     imageUrl: "",
//     votes: 0,
//     integration: "Manages wallet verification and distribution logistics",
//     benefits: "Simplified airdrop management, automated distribution, enhanced accuracy"
//   },
//   {
//     id: 5,
//     title: "AI-Driven Token Mining Simulator",
//     description: "Interactive gaming setup that simulates token mining and trading decisions. Connects with our lootbox mechanics and AI agent rewards system for an engaging, educational experience.",
//     imageUrl: "",
//     votes: 0,
//     integration: "Runs simulations connected to main platform",
//     benefits: "Gamified learning, interactive experience, risk-free practice"
//   },
//   {
//     id: 6,
//     title: "Shill Counter & Engagement Tracker",
//     description: "Physical leaderboard display showing top community shillers and engagement metrics. Tracks and displays social media activity across Twitter, Telegram, and Discord in real-time.",
//     imageUrl: "",
//     votes: 0,
//     integration: "Aggregates metrics from multiple social platforms",
//     benefits: "Community engagement boost, competitive atmosphere, transparent tracking"
//   },
//   {
//     id: 7,
//     title: "Secure Wallet Kiosk",
//     description: "Public/private kiosk interface for secure wallet balance viewing and transaction history. Perfect for events and meetups, providing a tangible connection to digital assets.",
//     imageUrl: "",
//     votes: 0,
//     integration: "Secure bridge between wallets and offline interface",
//     benefits: "Enhanced security, physical presence, community trust"
//   },
//   {
//     id: 8,
//     title: "Automated Rug Check Device",
//     description: "Real-time token safety verification device using RugCheck API integration. Features LED indicators and display screen showing detailed safety metrics before investing.",
//     imageUrl: "",
//     votes: 0,
//     integration: "Automated token verification via RugCheck API",
//     benefits: "Instant safety checks, risk reduction, visual alerts"
//   }
];

const AIHardwareProjects = () => {
  const [projects, setProjects] = useState(projectOptions);
  const [suggestion, setSuggestion] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [pledgeAmount, setPledgeAmount] = useState('');
  const [walletConnected, setWalletConnected] = useState(false);
const [walletAddress, setWalletAddress] = useState('');
const [isNavigating, setIsNavigating] = useState(false);


const handleHomeNavigation = () => {
    setIsNavigating(true);
    window.location.href = '/'; // This will force a full page refresh
  };

  const handleVote = (projectId) => {
    setProjects(projects.map(project => 
      project.id === projectId 
        ? { ...project, votes: project.votes + 1 }
        : project
    ));
  };
  const handleOpen = (project) => {
    setSelectedProject(project);
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
    setPledgeAmount('');
  };
  const handlePledge = () => {
    console.log(`Pledged ${pledgeAmount} $BNNA to ${selectedProject.title}`);
    handleClose();
  };
  const handleSuggestion = (e) => {
    e.preventDefault();
  if (!walletConnected) {
    alert('Please connect your wallet first');
    return;
  }
    console.log('New suggestion:', suggestion);
    setSuggestion('');
  };
  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ 
          method: 'eth_requestAccounts' 
        });
        setWalletAddress(accounts[0]);
        setWalletConnected(true);
      } catch (error) {
        console.error('Error connecting wallet:', error);
      }
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    script.async = true;
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);



  return (
    <Box sx={{ 
        p: 3, 
        maxWidth: 1200, 
        margin: '0 auto',
        background: 'linear-gradient(135deg, #1a2f18 0%, #0d1810 100%)',
        minHeight: '100vh',
        borderRadius: '16px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)'
      }}>
        <Button
        onClick={handleHomeNavigation}
        disabled={isNavigating}
        variant="contained"
        startIcon={<Home />}
        sx={{
          background: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(10px)',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.2)',
          },
        }}
      >
        Back Home
      </Button>

      <HeroSection />
      <CryptoKickstarterStats />

      <Typography variant="h4" sx={{ 
        mb: 4, 
        textAlign: 'center',
        color: '#9cff57',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 700,
        textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
        fontSize: '2.5rem'
      }}>
        ApeOut AI Hardware Projects
      </Typography>

      <Grid container spacing={3}>
        {projects.map((project) => (
          <Grid item xs={12} md={6} key={project.id}>
            <Card sx={{ background: '#2c2c2c', color: 'white', boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)' }}>
              <CardMedia
                component="img"
                height="200"
                image={project.imageUrl}
                alt={project.title}
                sx={{ objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" sx={{ color: '#FFD700' }}>{project.title}</Typography>
                <Typography variant="body2" sx={{ color: '#ffffff', mb: 2 }}>{project.description}</Typography>
                <Typography variant="body2" sx={{ color: '#90caf9', mb: 1 }}>
                  {project.pledged} pledged of {project.goal}
                </Typography>
                <Typography variant="body2" sx={{ color: '#81c784', mb: 2 }}>
                  {project.backers} backers
                </Typography>
                <Button 
                  variant="contained"
                  onClick={() => handleOpen(project)}
                  sx={{
                    background: 'linear-gradient(45deg, #FFD700 30%, #FFA500 90%)',
                    color: 'black',
                    '&:hover': { background: 'linear-gradient(45deg, #FFA500 30%, #FFD700 90%)' }
                  }}
                >
                  Back this Project
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal for Backing a Project */}
      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: '#FEFFEB',
            p: 4,
            borderRadius: '12px',
            boxShadow: 24,
            textAlign: 'center'
          }}
        >
          {selectedProject && (
            <>
              <Typography variant="h5" sx={{ fontWeight: 700, color: '#C59E57' }}>
                Back {selectedProject.title}
              </Typography>
              <Typography variant="body1" sx={{ color: '#423D36', my: 2 }}>
                Enter your pledge amount in $BNNA:
              </Typography>

              <TextField
                type="number"
                variant="outlined"
                fullWidth
                value={pledgeAmount}
                onChange={(e) => setPledgeAmount(e.target.value)}
                sx={{ mb: 3 }}
              />

              <Button
                variant="contained"
                sx={{
                  background: 'linear-gradient(45deg, #C59E57 30%, #9B7940 90%)',
                  color: '#0d1810',
                  fontWeight: 600,
                  padding: '10px 20px',
                  borderRadius: '25px',
                  '&:hover': { background: 'linear-gradient(45deg, #9B7940 30%, #C59E57 90%)' }
                }}
                onClick={handlePledge}
                disabled={!pledgeAmount}
              >
                Confirm Pledge
              </Button>
            </>
          )}
        </Box>
      </Modal>

      <Paper sx={{ 
    mt: 4, 
    p: 4,
    background: 'linear-gradient(145deg, rgba(42, 72, 42, 0.9) 0%, rgba(26, 47, 24, 0.9) 100%)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(156, 255, 87, 0.1)',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
    borderRadius: '20px',
  }}>
        <Typography variant="h6" sx={{ 
      mb: 3,
      color: '#9cff57',
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 600,
      textShadow: '2px 2px 4px rgba(0,0,0,0.3)'
    }}>
          Suggest a New Hardware Project
        </Typography>
        {!walletConnected ? (
  <Button 
    onClick={connectWallet}
    variant="outlined"
    sx={{ 
      mb: 2,
      borderColor: '#9cff57',
      color: '#9cff57',
      '&:hover': {
        borderColor: '#7acc45',
        backgroundColor: 'rgba(156, 255, 87, 0.1)'
      }
    }}
  >
    Connect Wallet
  </Button>
) : (
  <Typography 
    variant="body2" 
    sx={{ 
      mb: 2,
      color: '#9cff57',
      fontFamily: 'Poppins, sans-serif'
    }}
  >
    Connected: {walletAddress.slice(0, 6)}...{walletAddress.slice(-4)}
  </Typography>
)}
        <form onSubmit={handleSuggestion}>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={suggestion}
            onChange={(e) => setSuggestion(e.target.value)}
            placeholder="Describe your hardware project idea..."
            sx={{ mb: 2, color: 'white' }}
          />
          <Button sx={{
              background: 'linear-gradient(45deg, #9cff57 30%, #66bb6a 90%)',
              color: '#0d1810',
              fontWeight: 600,
              padding: '12px 30px',
              borderRadius: '25px',
              fontSize: '1.1rem',
              '&:hover': {
                background: 'linear-gradient(45deg, #66bb6a 30%, #9cff57 90%)',
                transform: 'scale(1.05)',
                transition: 'all 0.2s ease-in-out',
              },
            }} type="submit" variant="contained">
            Submit Suggestion
          </Button>
        </form>
      </Paper>

      <Box sx={{ 
  position: 'relative',
  width: '100%',
  maxWidth: '605px',
  margin: '0 auto',
  '& iframe': {
    borderRadius: '12px',
  }
}}>
  <blockquote className="tiktok-embed" 
    cite="https://www.tiktok.com/@genogrand.eth/video/7465162067402575146" 
    data-video-id="7465162067402575146" 
    style={{ maxWidth: '605px', minWidth: '325px' }}
  > 
    <section> 
      <a target="_blank" title="@genogrand.eth" href="https://www.tiktok.com/@genogrand.eth?refer=embed">@genogrand.eth</a> 
      🚀 Unboxing the Future of AI Trading! 🤖💡 @apeoutmeme Introducing our Raspberry Pi-powered AI trading bot, merging hardware & automation for next-level crypto trading! 🔥🚀 🍌 Powered by $BNNA – More than a meme, it's the future of decentralized AI trading! 🎁 Kickstarter-style distribution coming soon! $BNNA holders get first access! Stay tuned. 👀💰 
      <a title="aitrading" target="_blank" href="https://www.tiktok.com/tag/aitrading?refer=embed">#AITrading</a> 
      <a title="cryptoinnovation" target="_blank" href="https://www.tiktok.com/tag/cryptoinnovation?refer=embed">#CryptoInnovation</a> 
      <a title="bnna" target="_blank" href="https://www.tiktok.com/tag/bnna?refer=embed">#BNNA</a> 
      <a title="apeout" target="_blank" href="https://www.tiktok.com/tag/apeout?refer=embed">#ApeOut</a> 
      <a title="automatedtrading" target="_blank" href="https://www.tiktok.com/tag/automatedtrading?refer=embed">#AutomatedTrading</a> 
      <a title="fyp" target="_blank" href="https://www.tiktok.com/tag/fyp?refer=embed">#fyp</a> 
      <a title="foryou" target="_blank" href="https://www.tiktok.com/tag/foryou?refer=embed">#foryou</a> 
      <a title="memecoins" target="_blank" href="https://www.tiktok.com/tag/memecoins?refer=embed">#memecoins</a> 
      <a title="bitcoin" target="_blank" href="https://www.tiktok.com/tag/bitcoin?refer=embed">#bitcoin</a> 
      <a title="solana" target="_blank" href="https://www.tiktok.com/tag/solana?refer=embed">#solana</a> 
      <a target="_blank" title="♬ Rock - Stepz" href="https://www.tiktok.com/music/Rock-7423291709603514385?refer=embed">♬ Rock - Stepz</a> 
    </section> 
  </blockquote>
</Box>
    </Box>
  );
};

export default AIHardwareProjects;