import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';

// Agent personality types and their distributions
const AGENT_TYPES = {
  PROFESSIONAL: { weight: 0.10, risk: 0.3, tradingStyle: 'conservative' },
  BUSINESS_OWNER: { weight: 0.20, risk: 0.5, tradingStyle: 'moderate' },
  TRADER: { weight: 0.40, risk: 0.8, tradingStyle: 'aggressive' },
  STUDENT: { weight: 0.10, risk: 0.7, tradingStyle: 'experimental' },
  RETIREE: { weight: 0.05, risk: 0.2, tradingStyle: 'very_conservative' },
  TECH_ENTHUSIAST: { weight: 0.10, risk: 0.6, tradingStyle: 'innovative' },
  INFLUENCER: { weight: 0.05, risk: 0.9, tradingStyle: 'momentum' }
};

// Trading behaviors and strategies
const TRADING_STRATEGIES = {
  conservative: {
    maxRiskPerTrade: 0.02,
    stopLoss: 0.05,
    takeProfit: 0.10,
    tradeFrequency: 'low'
  },
  moderate: {
    maxRiskPerTrade: 0.05,
    stopLoss: 0.10,
    takeProfit: 0.20,
    tradeFrequency: 'medium'
  },
  aggressive: {
    maxRiskPerTrade: 0.10,
    stopLoss: 0.15,
    takeProfit: 0.30,
    tradeFrequency: 'high'
  },
  experimental: {
    maxRiskPerTrade: 0.15,
    stopLoss: 0.20,
    takeProfit: 0.40,
    tradeFrequency: 'very_high'
  },
  very_conservative: {
    maxRiskPerTrade: 0.01,
    stopLoss: 0.03,
    takeProfit: 0.08,
    tradeFrequency: 'very_low'
  },
  innovative: {
    maxRiskPerTrade: 0.08,
    stopLoss: 0.12,
    takeProfit: 0.25,
    tradeFrequency: 'medium_high'
  },
  momentum: {
    maxRiskPerTrade: 0.12,
    stopLoss: 0.18,
    takeProfit: 0.35,
    tradeFrequency: 'very_high'
  }
};

// Message templates for agent communication
const MESSAGE_TEMPLATES = {
  entrySignal: [
    "Looking at {token}. Volume spike detected.",
    "Technical analysis suggests {token} is ready for a move.",
    "Accumulation pattern forming on {token}.",
    "Whale wallet tracking shows interest in {token}.",
  ],
  exitSignal: [
    "Taking profits on {token}.",
    "Hit my target on {token}. Securing gains.",
    "Risk levels increasing for {token}. Consider exit.",
    "Volume dropping on {token}. Time to move on.",
  ],
  analysis: [
    "Market structure for {token} showing strength.",
    "Key support level established at {price}.",
    "Resistance zone approaching at {price}.",
    "Volume profile indicates accumulation phase.",
  ]
};

class AgentSwarmCoordinator {
  constructor(numAgents = 500) {
    this.agents = [];
    this.chatMessages = [];
    this.numAgents = numAgents;
    this.tokenData = new Map();
    this.initializeAgents();
  }

  generateRandomWallet() {
    // Simulate wallet creation (replace with actual Solana wallet creation in production)
    return {
      publicKey: `${Math.random().toString(36).substring(2, 15)}`,
      balance: Math.random() * 100 + 10, // Random balance between 10-110 SOL
    };
  }

  getRandomPersona() {
    const rand = Math.random();
    let cumulative = 0;
    
    for (const [type, data] of Object.entries(AGENT_TYPES)) {
      cumulative += data.weight;
      if (rand <= cumulative) {
        return {
          type,
          ...data,
        };
      }
    }
  }

  initializeAgents() {
    for (let i = 0; i < this.numAgents; i++) {
      const wallet = this.generateRandomWallet();
      const persona = this.getRandomPersona();
      const strategy = TRADING_STRATEGIES[persona.tradingStyle];

      this.agents.push({
        id: `agent-${i}`,
        wallet,
        persona,
        strategy,
        holdings: new Map(),
        tradingHistory: [],
        messageHistory: [],
        lastAction: Date.now(),
      });
    }
  }

  // Simulate agent decision making
  agentDecision(agent, marketData) {
    const { strategy, persona } = agent;
    const decisionFactors = {
      price: Math.random(), // Simplified price factor
      volume: Math.random(), // Simplified volume factor
      momentum: Math.random(), // Simplified momentum factor
      sentiment: Math.random(), // Simplified sentiment factor
    };

    // Weight factors based on persona
    const weightedScore = 
      (decisionFactors.price * persona.risk) +
      (decisionFactors.volume * strategy.maxRiskPerTrade) +
      (decisionFactors.momentum * (persona.risk * 0.5)) +
      (decisionFactors.sentiment * (1 - persona.risk));

    return {
      shouldTrade: weightedScore > 0.7,
      confidence: weightedScore,
      suggestedSize: agent.wallet.balance * strategy.maxRiskPerTrade
    };
  }

  // Generate agent message
  generateAgentMessage(agent, type, data) {
    const templates = MESSAGE_TEMPLATES[type];
    const template = templates[Math.floor(Math.random() * templates.length)];
    const message = template.replace(/{(\w+)}/g, (match, key) => data[key] || match);

    return {
      agentId: agent.id,
      persona: agent.persona.type,
      message,
      timestamp: Date.now(),
      type
    };
  }

  // Simulate market impact
  simulateMarketImpact(token, totalBuyVolume) {
    const currentData = this.tokenData.get(token) || {
      price: 1,
      volume: 0,
      marketCap: 1000000
    };

    const priceImpact = Math.log(1 + (totalBuyVolume / currentData.marketCap)) * 0.5;
    const newPrice = currentData.price * (1 + priceImpact);

    this.tokenData.set(token, {
      ...currentData,
      price: newPrice,
      volume: currentData.volume + totalBuyVolume
    });

    return newPrice;
  }

  // Run simulation step
  async simulationStep(token) {
    const activeAgents = this.agents.filter(agent => 
      Date.now() - agent.lastAction > this.getAgentActionDelay(agent)
    );

    const decisions = activeAgents.map(agent => {
      const decision = this.agentDecision(agent, this.tokenData.get(token));
      if (decision.shouldTrade) {
        const message = this.generateAgentMessage(agent, 'entrySignal', { token });
        this.chatMessages.push(message);
        
        // Update agent state
        agent.lastAction = Date.now();
        agent.tradingHistory.push({
          action: 'buy',
          token,
          amount: decision.suggestedSize,
          price: this.tokenData.get(token).price,
          timestamp: Date.now()
        });
      }
      return decision;
    });

    // Simulate market impact
    const totalBuyVolume = decisions
      .filter(d => d.shouldTrade)
      .reduce((sum, d) => sum + d.suggestedSize, 0);

    const newPrice = this.simulateMarketImpact(token, totalBuyVolume);

    return {
      price: newPrice,
      numTraders: decisions.filter(d => d.shouldTrade).length,
      volume: totalBuyVolume
    };
  }

  getAgentActionDelay(agent) {
    const baseDelay = 5000; // 5 seconds base delay
    const frequencyMultiplier = {
      very_low: 5,
      low: 4,
      medium: 3,
      medium_high: 2,
      high: 1.5,
      very_high: 1
    }[agent.strategy.tradeFrequency];

    return baseDelay * frequencyMultiplier;
  }

  // Generate simulation report
  generateReport() {
    const report = {
      totalAgents: this.agents.length,
      personaDistribution: {},
      tradingVolume: 0,
      messageCount: this.chatMessages.length,
      topTraders: [],
      priceMovement: [],
    };

    // Calculate persona distribution
    this.agents.forEach(agent => {
      report.personaDistribution[agent.persona.type] = 
        (report.personaDistribution[agent.persona.type] || 0) + 1;
    });

    // Calculate trading volume and identify top traders
    this.agents.forEach(agent => {
      const agentVolume = agent.tradingHistory.reduce(
        (sum, trade) => sum + trade.amount, 0
      );
      report.tradingVolume += agentVolume;
      report.topTraders.push({
        agentId: agent.id,
        persona: agent.persona.type,
        volume: agentVolume
      });
    });

    // Sort top traders by volume
    report.topTraders.sort((a, b) => b.volume - a.volume);
    report.topTraders = report.topTraders.slice(0, 10);

    return report;
  }
}

export default AgentSwarmCoordinator;