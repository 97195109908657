import React from 'react';
import { Box, Typography, Button, Paper, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CheckCircle } from '@mui/icons-material';

const APIAccessCompletePage = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(to bottom, #111827, #000000)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: 4,
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={24}
          sx={{
            p: 4,
            background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.9), rgba(31, 41, 55, 0.9))',
            backdropFilter: 'blur(16px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <CheckCircle 
            sx={{ 
              fontSize: 80, 
              color: '#4ade80',
              mb: 2 
            }} 
          />
          
          <Typography
            variant="h4"
            sx={{
              color: '#fbbf24',
              fontWeight: 'bold',
              mb: 2,
            }}
          >
            Registration Complete!
          </Typography>
          
          <Typography
            sx={{
              color: 'white',
              mb: 4,
              opacity: 0.9,
            }}
          >
            Thank you for registering for early API access. We'll be in touch soon!
          </Typography>

          <Button
            variant="contained"
            onClick={() => navigate('/app')}
            sx={{
              py: 1.5,
              px: 4,
              background: 'linear-gradient(to right, #4ade80, #22c55e)',
              '&:hover': {
                background: 'linear-gradient(to right, #22c55e, #16a34a)',
              },
            }}
          >
            Return to App
          </Button>
        </Paper>
      </Container>
    </Box>
  );
};

export default APIAccessCompletePage;