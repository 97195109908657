import { toast } from 'react-toastify';
import { bs58 } from 'bs58';
import { VersionedTransaction } from '@solana/web3.js';
import { Keypair } from '@solana/web3.js';
export const tradingService = {
  isProcessing: false,

  async handleBuy(coin, amount, tradingWallet, options = {}) {
    if (this.isProcessing) {
      console.log('Transaction already processing');
      return false;
    }

    const {
      slippage = 15,
      priorityFee = 0.0001,
      pool = 'raydium',
      onSuccess,
      onError
    } = options;

    try {
      this.isProcessing = true;
      
      if (!tradingWallet?.apiKey) {
        throw new Error("Trading wallet not properly initialized");
      }

      const response = await fetch(`https://pumpportal.fun/api/trade?api-key=${tradingWallet.apiKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'buy',
          mint: coin,
          amount: amount,
          denominatedInSol: 'true',
          slippage: Number(slippage),
          priorityFee: Number(priorityFee),
          pool: pool,
          publicKey: tradingWallet.walletPublicKey,
        }),
      });

      if (!response.ok) {
        throw new Error(`Trade API error: ${response.status}`);
      }

      const result = await response.json();
      
      if (!result.signature) {
        throw new Error(result.errors || 'Transaction failed');
      }

      if (onSuccess) {
        await onSuccess(result);
      }

      return true;

    } catch (error) {
      console.error('Error processing buy:', error);
      if (onError) {
        onError(error);
      }
      return false;
    } finally {
      this.isProcessing = false;
    }
  },

  async handleSell(coin, amount, tradingWallet, options = {}) {
    if (this.isProcessing) {
      console.log('Transaction already processing');
      return false;
    }

    const {
      slippage = 15,
      priorityFee = 0.0001,
      pool = 'raydium',
      onSuccess,
      onError
    } = options;

    try {
      this.isProcessing = true;

      if (!tradingWallet?.apiKey) {
        throw new Error("Trading wallet not properly initialized");
      }

      const response = await fetch(`https://pumpportal.fun/api/trade?api-key=${tradingWallet.apiKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'sell',
          mint: coin,
          amount: amount,
          denominatedInSol: 'true',
          slippage: Number(slippage),
          priorityFee: Number(priorityFee),
          pool: pool,
          publicKey: tradingWallet.walletPublicKey,
        }),
      });

      if (!response.ok) {
        throw new Error(`Trade API error: ${response.status}`);
      }

      const result = await response.json();
      
      if (!result.signature) {
        throw new Error(result.errors || 'Transaction failed');
      }

      if (onSuccess) {
        await onSuccess(result);
      }

      return true;

    } catch (error) {
      console.error('Error processing sell:', error);
      if (onError) {
        onError(error);
      }
      return false;
    } finally {
      this.isProcessing = false;
    }
  },

  async handleAutoTPSLSell(coin, amount, tradingWallet, options = {}) {
    if (this.isProcessing) {
      console.log('Transaction already processing');
      return false;
    }

    const {
      slippage = 15,
      priorityFee = 0.001,
      onSuccess,
      onError
    } = options;

    try {
      this.isProcessing = true;
      let decryptedKey = null;

      const verifyResponse = await fetch(`${process.env.REACT_APP_API_URL}api/decrypt-key`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer true`
        },
        body: JSON.stringify({
          encryptedKey: tradingWallet.privateKey,
          walletAddress: tradingWallet.walletPublicKey
        })
      });

      if (!verifyResponse.ok) {
        throw new Error('Failed to verify trading wallet access');
      }

      const { decryptedKey: key } = await verifyResponse.json();
      decryptedKey = key;

      const bundledTxArgs = [{
        publicKey: tradingWallet.walletPublicKey,
        action: "sell",
        mint: coin,
        denominatedInSol: "true",
        amount: amount,
        slippage: Number(slippage),
        priorityFee: Number(priorityFee),
        pool: "raydium"
      }];

      const response = await fetch(`https://pumpportal.fun/api/trade-local`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bundledTxArgs)
      });

      if (!response.ok) {
        throw new Error(`API error: ${response.statusText}`);
      }

      const transactions = await response.json();
      
      let encodedSignedTransactions = [];
      let signatures = [];

      // Sign each transaction
      for (let i = 0; i < bundledTxArgs.length; i++) {
        const tx = VersionedTransaction.deserialize(
          new Uint8Array(bs58.decode(transactions[i]))
        );
        const tradingKeypair = Keypair.fromSecretKey(bs58.decode(decryptedKey));
        tx.sign([tradingKeypair]);
        encodedSignedTransactions.push(bs58.encode(tx.serialize()));
        signatures.push(bs58.encode(tx.signatures[0]));
      }

      // Submit to Jito
      const jitoResponse = await fetch(`https://mainnet.block-engine.jito.wtf/api/v1/bundles`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          jsonrpc: "2.0",
          id: 1,
          method: "sendBundle",
          params: [encodedSignedTransactions]
        })
      });

      if (!jitoResponse.ok) {
        throw new Error('Jito bundle submission failed');
      }

      if (onSuccess) {
        await onSuccess({ signature: signatures[0] });
      }

      return true;

    } catch (error) {
      console.error('Error processing auto TP/SL sell:', error);
      if (onError) {
        onError(error);
      }
      return false;
    } finally {
      this.isProcessing = false;
    }
  }
};