import React, { useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const TimelineContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(13, 17, 28, 0.85)',
  backdropFilter: 'blur(10px)',
  borderRadius: '12px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  padding: '16px',
  height: '500px',
  overflow: 'hidden'
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  gap: '20px',
  padding: '20px',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '4px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.3)',
    },
  },
}));

const CelebrityMonitor = () => {
  const accounts = [
    { id: 'elonmusk', name: 'Elon Musk' },
    { id: 'cz_binance', name: 'CZ Binance' },
    { id: 'VitalikButerin', name: 'Vitalik Buterin' },
    { id: 'realDonaldTrump', name: 'Donald Trump' }
  ];

  useEffect(() => {
    // Load Twitter widget script
    const script = document.createElement('script');
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    script.charset = "utf-8";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box sx={{ width: '100%', maxWidth: '100%', margin: '0 auto' }}>
      <Typography variant="h6" sx={{ 
        color: '#FEFFEB',
        marginBottom: '16px',
        padding: '0 20px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      }}>
        Celebrity Token Mentions
      </Typography>

      <GridContainer container wrap="nowrap">
        {accounts.map((account) => (
          <Grid item key={account.id} sx={{ minWidth: '350px' }}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                color: '#4ade80', 
                marginBottom: '8px',
                textAlign: 'center'
              }}
            >
              {account.name}
            </Typography>
            <TimelineContainer>
              <a
                className="twitter-timeline"
                data-theme="dark"
                data-chrome="noheader nofooter noborders transparent"
                data-tweet-limit="5"
                data-width="100%"
                data-height="450"
                href={`https://twitter.com/${account.id}?ref_src=twsrc%5Etfw`}
              >
                Loading tweets from {account.name}...
              </a>
            </TimelineContainer>
          </Grid>
        ))}
      </GridContainer>
    </Box>
  );
};

export default CelebrityMonitor;