import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const LoadingContainer = styled(Box)(({ theme }) => ({
  height: '50vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  background: '#121212',
  color: 'white',
}));

const LoadingState = ({ message = 'Loading...' }) => {
  return (
    <LoadingContainer>
      <CircularProgress color="primary" />
      <Typography variant="h6">{message}</Typography>
    </LoadingContainer>
  );
};

export default LoadingState;