// src/components/LootBoxOpener.js
import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, Typography, IconButton, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, Lock } from '@mui/icons-material';
import { keyframes } from '@mui/system';
import { useWallet } from '@solana/wallet-adapter-react';
const glow = keyframes`
  0% { box-shadow: 0 0 5px #ffd700; }
  50% { box-shadow: 0 0 20px #ffd700, 0 0 30px #ffd700; }
  100% { box-shadow: 0 0 5px #ffd700; }
`;

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const moveBackground = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const ContentBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: '20px',
  padding: theme.spacing(4),
  maxWidth: '600px',
  width: '90%',
  border: '1px solid rgba(255, 215, 0, 0.3)',
  animation: `${glow} 2s infinite`,
  background: `
    linear-gradient(
      45deg,
      rgba(139, 0, 0, 0.8),
      rgba(0, 100, 0, 0.8),
      rgba(139, 0, 0, 0.8)
    )
  `,
  backgroundSize: '200% 200%',
  animation: `${moveBackground} 10s ease infinite, ${glow} 2s infinite`,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `
      radial-gradient(
        circle at center,
        transparent 0%,
        rgba(0, 0, 0, 0.7) 100%
      )
    `,
    borderRadius: 'inherit',
    pointerEvents: 'none',
  },
  zIndex: 1300 // Add this to ensure the modal appears above other elements
}));

const VideoContainer = styled(Box)({
  width: '100%',
  aspectRatio: '16/9',
  borderRadius: '10px',
  overflow: 'hidden',
  marginBottom: '20px',
  position: 'relative', // Add this
});

const StyledVideo = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'contain', // Change from 'cover' to 'contain'
  position: 'absolute', // Add this
  top: '50%', // Add this
  left: '50%', // Add this
  transform: 'translate(-50%, -50%)', // Add this
  backgroundColor: 'black', // Add this
});


const OpenButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FFD700 30%, #FFA500 90%)',
  border: 0,
  borderRadius: '25px',
  boxShadow: '0 3px 5px 2px rgba(255, 215, 0, .3)',
  color: 'black',
  padding: '15px 45px',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  '&:hover': {
    background: 'linear-gradient(45deg, #FFA500 30%, #FFD700 90%)',
    transform: 'scale(1.05)',
    transition: 'transform 0.2s ease-in-out',
  },
}));

const ItemDisplay = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '400px',
  backgroundColor: 'rgba(0, 0, 0, 0.95)',
  border: '2px solid rgba(255, 215, 0, 0.5)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  textAlign: 'center',
  color: 'white',
  zIndex: 1000,
  animation: `${fadeIn} 0.5s ease-out`,
}));

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -40%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
`;

const RarityBadge = styled(Box)(({ rarity }) => {
  const colors = {
    COMMON: '#898989',
    UNCOMMON: '#4CAF50',
    RARE: '#2196F3',
    LEGENDARY: '#FFD700'
  };
  
  return {
    backgroundColor: colors[rarity] || colors.COMMON,
    color: 'white',
    padding: '4px 12px',
    borderRadius: '12px',
    display: 'inline-block',
    margin: '8px 0',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '0.8rem'
  };
});

const API_URL = process.env.REACT_APP_API_URL || 'https://apeout-server-42e2c984147f.herokuapp.com';


const LootBoxOpener = ({ 
  isOpen, 
  onClose, 
  idleVideo, // URL for the spinning lootbox video
  openingVideo, // URL for the opening animation video
  onOpenComplete, // Callback for when opening animation completes
  isLocked = true, // Optional prop to disable opening if user doesn't meet requirements
  onDataFetched
}) => {
  const [isOpening, setIsOpening] = useState(false);
  const [hasOpened, setHasOpened] = useState(false);
  const [lootboxCount, setLootboxCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastItem, setLastItem] = useState(null);
  const [showItem, setShowItem] = useState(false);

  const { publicKey } = useWallet();
  const walletAddress = publicKey ? publicKey.toBase58() : null;
  const [isAnimating, setIsAnimating] = useState(false);

  
  useEffect(() => {
    console.log('Wallet address:', walletAddress);
    if (walletAddress) {
      fetchLootboxCount();
    }
  }, [walletAddress]);

  const handleClose = () => {
    setShowItem(false);
    setLastItem(null);
    onClose();
  };
  const fetchLootboxCount = async () => {
    console.log('Fetching lootbox count for wallet:', walletAddress);
    try {
      setIsLoading(true);
      console.log('Fetching lootbox count for wallet:', walletAddress);
      const response = await fetch(`${API_URL}api/lootboxes/${walletAddress}`);
      const data = await response.json();
      console.log('Lootbox count data:', data);
      
      
      if (data.success) {
        setLootboxCount(data.lootboxes.quantity);
        if (data.success) {
          onDataFetched?.(data);
        }
      } else {
        setError(data.error);
      }
    } catch (error) {
      setError('Failed to fetch lootbox count');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpen = async () => {
    if (lootboxCount === 0 ) return;
    
    setIsAnimating(true);
    setIsOpening(true);
    setShowItem(false);
    
    try {

      const response = await fetch(`${API_URL}api/lootboxes/open`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ walletAddress })
      });
      
      const data = await response.json();
      
      if (data.success) {
        setLastItem(data.item);
        setLootboxCount(data.remainingLootboxes);
        // Don't set hasOpened here - wait for animation to complete
      } else {
        setError(data.error);
        setIsAnimating(false);
        setIsOpening(false);
      }
    } catch (error) {
      setError('Failed to open lootbox');
      setIsAnimating(false);
      setIsOpening(false);
    }
  };
  useEffect(() => {
    const video = document.querySelector('video');
    if (video) {
      video.load();
      
      // Force video reload when source changes
      const handleSourceChange = () => {
        video.load();
        video.play().catch(error => {
          console.error('Video playback error:', error);
        });
      };
  
      video.addEventListener('error', (e) => {
        console.error('Video error:', e);
      });
  
      // Handle source changes
      return () => {
        video.removeEventListener('error', handleSourceChange);
      };
    }
  }, [isOpening, idleVideo, openingVideo]);
  
  // Add error handling for video playback
  const handleVideoError = (error) => {
    console.error('Video playback error:', error);
    setError('Failed to play video. Please try again.');
    setIsOpening(false);
    setIsAnimating(false);
  };

  const handleVideoEnd = () => {
    setIsOpening(false);
    if (lastItem) {
      setHasOpened(true);
      setShowItem(true);
      if (onOpenComplete) {
        onOpenComplete(lastItem);
      }
    }
    setIsOpening(false);
    setIsAnimating(false);
  };
  console.log('MOUNTED');

  return (
    <StyledModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="lootbox-modal"
    >
      <ContentBox>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
            zIndex: 1100,
          }}
        >
          <Close />
        </IconButton>

        <Typography 
          variant="h4" 
          sx={{ 
            color: 'white', 
            textAlign: 'center', 
            mb: 3,
            textShadow: '0 0 10px rgba(255, 215, 0, 0.5)',
          }}
        >
          {!walletAddress ? 'Connect Wallet' : 'Mystery Loot Box'}
        </Typography>

        {!showItem && (
          <>
            <VideoContainer>
            <StyledVideo
              onError={handleVideoError}

    autoPlay
    muted={!isOpening}
    loop={!isOpening}
    key={isOpening ? 'opening' : 'idle'}
    onEnded={handleVideoEnd}
    playsInline // Add this
    webkit-playsinline="true" // Add this
    preload="auto" // Add this
    controlsList="nodownload" // Add this
  >
                <source 
                  src={isOpening ? openingVideo : idleVideo} 
                  type="video/mp4" 
                />
                    {/* Add fallback content */}
    Your browser does not support the video tag.
              </StyledVideo>
            </VideoContainer>

            <Box sx={{ textAlign: 'center' }}>
              {!isLoading && (
                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: 'white',
                    mb: 2 
                  }}
                >
                  Available Lootboxes: {lootboxCount}
                </Typography>
              )}

              <OpenButton
                onClick={handleOpen}
                disabled={lootboxCount === 0 || isOpening}
                startIcon={lootboxCount === 0 ? <Lock /> : null}
              >
                {isOpening ? 'Opening...' : 
                 lootboxCount === 0 ? 'No Lootboxes Available' :
                 'Open Lootbox'}
              </OpenButton>
            </Box>
          </>
        )}

        {showItem && lastItem && (
          <ItemDisplay elevation={3}>
            <RarityBadge rarity={lastItem.rarity}>
              {lastItem.rarity}
            </RarityBadge>
            
            <Box sx={{ my: 2 }}>
              <img 
                src={lastItem.imageUrl} 
                alt={lastItem.name}
                style={{ 
                  width: '200px',
                  height: '200px',
                  objectFit: 'contain',
                  margin: '0 auto'
                }}
              />
            </Box>
            
            <Typography variant="h5" sx={{ mb: 1, color: 'white', fontWeight: 'bold' }}>
              {lastItem.name}
            </Typography>
            
            <Typography variant="body1" sx={{ mb: 2, color: 'rgba(255,255,255,0.8)' }}>
              {lastItem.description}
            </Typography>
            
            {lastItem.attributes && (
              <Box sx={{ mt: 2, textAlign: 'left' }}>
                <Typography variant="subtitle2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                  Features:
                </Typography>
                <Box component="ul" sx={{ m: 0, pl: 2 }}>
                  {lastItem.attributes.features.map((feature, index) => (
                    <Typography 
                      key={index} 
                      component="li" 
                      sx={{ color: 'rgba(255,255,255,0.9)' }}
                    >
                      {feature}
                    </Typography>
                  ))}
                </Box>
              </Box>
            )}
            
            <Button
              variant="contained"
              onClick={handleOpen}
              disabled={lootboxCount === 0}
              sx={{ 
                mt: 3,
                background: 'linear-gradient(45deg, #FFD700 30%, #FFA500 90%)',
                color: 'black',
                '&:hover': {
                  background: 'linear-gradient(45deg, #FFA500 30%, #FFD700 90%)',
                }
              }}
            >
              {lootboxCount > 0 ? 'Open Another' : 'No More Lootboxes'}
            </Button>
          </ItemDisplay>
        )}

        {error && (
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'error.main', 
              mt: 2,
              textAlign: 'center'
            }}
          >
            {error}
          </Typography>
        )}
      </ContentBox>
      
    </StyledModal>
    
  );
};

export default LootBoxOpener;