import React, { useRef, useEffect, useState } from 'react';
import '../../styles/CoinSwiper.css';



const CoinCard = ({ coin, onFavorite, onBuy, isFavorite, onSell, updateTokenData }) => {
     // Add a null check at the beginning of the component
  if (!coin) {
    return null; // or return a placeholder component
  }

  const pumpFunUrl = `https://pump.fun/${coin.mint}`;

 return (
    <div className="coin-card">
      <img src={coin.image || 'https://via.placeholder.com/150'} alt={coin.name} className="coin-image" />
      <div className="coin-info">
        <h2>{coin.name || 'Unknown Token'}</h2>
        <p>{coin.description || 'No description available'}</p>
        {coin.isPlaceholder && (
            <button
              className="fetch-details-button"
              onClick={() => updateTokenData(coin.mint)}
            >
              Fetch Details
            </button>
          )}
        {coin.marketCap && <p>Market Cap: {coin.marketCap.toFixed(2)}</p>}
        {coin.usdMarketCap && <p>USD Market Cap: ${coin.usdMarketCap.toFixed(2)}</p>}
        {coin.replyCount && <p>Reply Count: {coin.replyCount}</p>}
        {coin.lastReply && <p>Last Reply: {new Date(coin.lastReply).toLocaleString()}</p>}
        <a href={pumpFunUrl} target="_blank" rel="noopener noreferrer" className="pump-fun-link">View on pump.fun</a>

      </div>
      <div className="coin-actions">
        <button className={`action-button favorite ${isFavorite ? 'active' : ''}`} onClick={() => onFavorite(coin)}>
          ❤️
        </button>
        <button className="action-button buy ape" onClick={() => onBuy(coin, 0.0001)}>🦍➕</button>
        <button className="action-button buy snipe" onClick={() => onSell(coin, 10000)}>🎯</button>
      </div>
    </div>
  );
}

const CoinSwiper = ({ coins, onFavorite, onBuy, favorites, isLoading, hasMoreTokens, onLoadMore, onSell, updateTokenData  }) => {
    const containerRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
  
    // useEffect(() => {
    //   const handleScroll = () => {
    //     const container = containerRef.current;
    //     if (!container) return;
  
    //     const { scrollTop, scrollHeight, clientHeight } = container;
    //     if (scrollHeight - scrollTop <= clientHeight * 1.5) {
    //       onScroll();
    //     }
  
    //     const newIndex = Math.round(scrollTop / clientHeight);
    //     if (newIndex !== currentIndex) {
    //       setCurrentIndex(newIndex);
    //     }
    //   };
  
    //   const container = containerRef.current;
    //   if (container) {
    //     container.addEventListener('scroll', handleScroll);
    //     return () => container.removeEventListener('scroll', handleScroll);
    //   }
    // }, [currentIndex, onScroll]);
  
    if (!coins || coins.length === 0) {
      return <div className="no-coins">No coins available. Try fetching new tokens.</div>;
    }
  
    return (
        <div className="swiper-container" ref={containerRef}>
          {coins.map((coin, index) => (
            <div className="coin-slide" key={`${coin.mint}-${index}`}>
              <CoinCard 
                coin={coin} 
                onFavorite={onFavorite} 
                onBuy={onBuy}
                isFavorite={favorites.includes(coin)}
                onSell={onSell}
                updateTokenData={updateTokenData}
              />
            </div>
          ))}
          {isLoading && <div className="loading">Loading...</div>}
          {!isLoading && hasMoreTokens && (
            <button className="load-more-button" onClick={onLoadMore}>
              Load More
            </button>
          )}
        </div>
      );
  };

export default CoinSwiper;