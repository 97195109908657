import React, { useState, useEffect, useRef } from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Button, 
  Box, 
  LinearProgress,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AgentSwarmCoordinator from './AgentSwarmCoordinator';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
  color: 'white',
  borderRadius: '16px',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)'
}));

const StyledProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  '& .MuiLinearProgress-bar': {
    background: 'linear-gradient(90deg, #4f46e5, #3b82f6)'
  }
}));

const MessageBox = styled(Box)(({ theme }) => ({
  padding: '12px',
  marginBottom: '8px',
  borderRadius: '8px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)'
}));

const SwarmSimulation = () => {
  const [isSimulating, setIsSimulating] = useState(false);
  const [messages, setMessages] = useState([]);
  const [simulationStats, setSimulationStats] = useState(null);
  const [simulationProgress, setSimulationProgress] = useState(0);
  const coordinatorRef = useRef(null);
  const simulationInterval = useRef(null);

  useEffect(() => {
    coordinatorRef.current = new AgentSwarmCoordinator(500);
    return () => {
      if (simulationInterval.current) {
        clearInterval(simulationInterval.current);
      }
    };
  }, []);

  const startSimulation = () => {
    if (!coordinatorRef.current) return;
    
    setIsSimulating(true);
    const targetToken = {
      address: "MOCK_TOKEN_ADDRESS",
      name: "MOCK_TOKEN",
      initialPrice: 1
    };

    coordinatorRef.current.tokenData.set(targetToken.address, {
      price: targetToken.initialPrice,
      volume: 0,
      marketCap: 1000000
    });

    simulationInterval.current = setInterval(async () => {
      const stepResult = await coordinatorRef.current.simulationStep(targetToken.address);
      
      setMessages(prev => [
        ...coordinatorRef.current.chatMessages.slice(-100)
      ]);

      setSimulationProgress(prev => (prev + 1) % 100);

      setSimulationStats({
        currentPrice: stepResult.price,
        totalVolume: stepResult.volume,
        activeTraders: stepResult.numTraders,
        messageCount: coordinatorRef.current.chatMessages.length
      });
    }, 1000);
  };

  const stopSimulation = () => {
    if (simulationInterval.current) {
      clearInterval(simulationInterval.current);
    }
    setIsSimulating(false);
    setSimulationProgress(0);
    
    const report = coordinatorRef.current.generateReport();
    console.log('Simulation Report:', report);
  };

  return (
    <Box sx={{ p: 3 }}>
      <StyledCard sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" sx={{ mb: 3 }}>
            Token Pump Simulation
          </Typography>
          
          <Button
            variant="contained"
            onClick={isSimulating ? stopSimulation : startSimulation}
            startIcon={isSimulating ? <StopIcon /> : <PlayArrowIcon />}
            sx={{
              mb: 3,
              background: isSimulating ? 
                'linear-gradient(135deg, #ef4444, #dc2626)' :
                'linear-gradient(135deg, #4f46e5, #3b82f6)',
              '&:hover': {
                background: isSimulating ?
                  'linear-gradient(135deg, #dc2626, #ef4444)' :
                  'linear-gradient(135deg, #3b82f6, #4f46e5)'
              }
            }}
          >
            {isSimulating ? "Stop Simulation" : "Start Simulation"}
          </Button>

          {isSimulating && (
            <StyledProgressBar 
              variant="determinate" 
              value={simulationProgress} 
              sx={{ mb: 3 }}
            />
          )}

          {simulationStats && (
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={12} sm={6} md={3}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="subtitle2">Current Price</Typography>
                    <Typography variant="h5">
                      ${simulationStats.currentPrice.toFixed(4)}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="subtitle2">Total Volume</Typography>
                    <Typography variant="h5">
                      ${simulationStats.totalVolume.toLocaleString()}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="subtitle2">Active Traders</Typography>
                    <Typography variant="h5">
                      {simulationStats.activeTraders}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="subtitle2">Messages</Typography>
                    <Typography variant="h5">
                      {simulationStats.messageCount}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            </Grid>
          )}

          <StyledCard>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Agent Chat Feed
              </Typography>
              <Box sx={{ 
                height: '400px', 
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '8px'
                },
                '&::-webkit-scrollbar-track': {
                  background: 'rgba(255, 255, 255, 0.1)',
                  borderRadius: '4px'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '4px',
                  '&:hover': {
                    background: 'rgba(255, 255, 255, 0.3)'
                  }
                }
              }}>
                {messages.map((msg, index) => (
                  <MessageBox key={index}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                      {msg.persona} Agent
                    </Typography>
                    <Typography variant="body2" sx={{ opacity: 0.8 }}>
                      {msg.message}
                    </Typography>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                      {new Date(msg.timestamp).toLocaleTimeString()}
                    </Typography>
                  </MessageBox>
                ))}
              </Box>
            </CardContent>
          </StyledCard>
        </CardContent>
      </StyledCard>
    </Box>
  );
};

export default SwarmSimulation;