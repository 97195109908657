import React, { useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { Box, Typography, Button, Paper, Fade } from '@mui/material';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { styled } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import { toast } from 'react-toastify';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.85)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: '15px',
  padding: theme.spacing(4),
  maxWidth: '600px',
  margin: '0 auto',
  color: '#fff',
  backdropFilter: 'blur(10px)',
}));

const SecretButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF0000 30%, #FF8E53 90%)',
  border: '2px solid rgba(255, 255, 255, 0.1)',
  borderRadius: '25px',
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  padding: '10px 30px',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF8E53 30%, #FF0000 90%)',
  },
}));

const GapeGate = ({ children, requiredBalance = 1000 }) => {
  const { publicKey, connected } = useWallet();
  const [isChecking, setIsChecking] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [gapeBalance, setGapeBalance] = useState(0);

  const checkAccess = async () => {
    if (!connected || !publicKey) {
      toast.error('Please connect your wallet first');
      return;
    }

    setIsChecking(true);
    try {
      const response = await fetch(
        `/api/token-balance/${publicKey.toString()}/4FM5bXZS4qnMK4Vdn4yRM4mANUVd97xYKZ2yKxziJRkd`
      );
      const data = await response.json();

      if (data.success) {
        const balance = data.balance || 0;
        setGapeBalance(balance);
        if (balance >= requiredBalance) {
          setHasAccess(true);
          toast.success('Access granted! Welcome to the governance portal.');
        } else {
          toast.error(`Insufficient $GAPE balance. Required: ${requiredBalance.toLocaleString()}`);
        }
      }
    } catch (error) {
      console.error('Error checking access:', error);
      toast.error('Failed to verify access');
    } finally {
      setIsChecking(false);
    }
  };

  if (hasAccess) {
    return children;
  }

  return (
    <Fade in={true} timeout={1000}>
      <Box sx={{ p: 4, minHeight: '100vh', background: 'linear-gradient(to bottom, #000000, #1a1a1a)' }}>
        <StyledPaper elevation={24}>
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <LockIcon sx={{ fontSize: 60, color: '#FF0000', mb: 2 }} />
            <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold' }}>
              RESTRICTED ACCESS
            </Typography>
            <Typography variant="body1" sx={{ mb: 3, color: 'rgba(255, 255, 255, 0.7)' }}>
              This area contains classified governance protocols.
              Only verified $GAPE holders can access this secure zone.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <WalletMultiButton />
            
            {connected && (
              <>
                <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.5)' }}>
                  Current $GAPE Balance: {gapeBalance.toLocaleString()}
                </Typography>
                <SecretButton
                  onClick={checkAccess}
                  disabled={isChecking}
                  startIcon={<LockIcon />}
                >
                  {isChecking ? 'VERIFYING ACCESS...' : 'VERIFY ACCESS'}
                </SecretButton>
              </>
            )}

            <Typography variant="body2" sx={{ mt: 2, color: 'rgba(255, 255, 255, 0.5)' }}>
              Required: {requiredBalance.toLocaleString()} $GAPE
            </Typography>
          </Box>
        </StyledPaper>
      </Box>
    </Fade>
  );
};

export default GapeGate;