

const BalanceCache = {
    data: new Map(),
    timestamp: new Map(),
    TTL: 30000,
    
    get(key) {
      const cachedData = this.data.get(key);
      const cachedTime = this.timestamp.get(key);
      
      if (cachedData && cachedTime && (Date.now() - cachedTime < this.TTL)) {
        return cachedData;
      }
      return null;
    },
    
    set(key, value) {
      this.data.set(key, value);
      this.timestamp.set(key, Date.now());
    },
    
    clear(key) {
      this.data.delete(key);
      this.timestamp.delete(key);
    }
  };
  
  export default BalanceCache;

