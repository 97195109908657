import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  CircularProgress, 
  Stack,
  IconButton,
  Tooltip,
  Paper,
  Modal,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { Transaction } from '@solana/web3.js';
import { ContentCopy, Launch, Receipt, Wallet, TrendingUp } from '@mui/icons-material';
import { toast } from 'react-toastify';
import SectionDivider from './common/SectionDivider';
import DemoAutoApeModal from './modals/DemoAutoApeModal';

const themeConfigs = {
  default: {
    primary: '#3b82f6',
    secondary: '#2563eb',
    gradient: 'linear-gradient(45deg, #3b82f6, #2563eb)',
    borderAccent: 'rgba(59, 130, 246, 0.3)'
  },
  doge: {
    primary: '#ff9a00',
    secondary: '#ff5e03',
    gradient: 'linear-gradient(45deg, #ff9a00, #ff5e03)',
    borderAccent: 'rgba(255, 154, 0, 0.3)'
  },
  pepe: {
    primary: '#00ff87',
    secondary: '#60efff',
    gradient: 'linear-gradient(45deg, #00ff87, #60efff)',
    borderAccent: 'rgba(0, 255, 135, 0.3)'
  }
};

const profileIcons = {
  dogs: ['🐕', '🐶', '🐕‍🦺', '🐩'],
  cats: ['🐱', '😺', '😸', '😻'],
  other: ['🐸', '🦊', '🐯', '🐼']
};

const BNNA_TOKEN_MINT = "DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump";

const StyledPaper = styled(Paper)(({ theme, themeName = 'default' }) => ({
  background: 'rgba(0, 0, 0, 0.6)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: '#fff',
  border: `1px solid ${themeConfigs[themeName].borderAccent}`,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '1px',
    background: `linear-gradient(90deg, transparent, ${themeConfigs[themeName].primary}40, transparent)`,
  },
}));

const ThemeBackground = styled(Box)(({ themeName = 'default' }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '150%',
  height: '150%',
  opacity: 0.2,
  pointerEvents: 'none',
  zIndex: 0,
  background: themeName === 'doge' 
    ? 'radial-gradient(circle at center, #ff9a00 0%, transparent 70%)'
    : themeName === 'pepe'
    ? 'radial-gradient(circle at center, #00ff87 0%, transparent 70%)'
    : 'radial-gradient(circle at center, #3b82f6 0%, transparent 70%)',
  filter: 'blur(60px)',
  animation: 'pulse 4s ease-in-out infinite',
  '@keyframes pulse': {
    '0%': { transform: 'translate(-50%, -50%) scale(1)' },
    '50%': { transform: 'translate(-50%, -50%) scale(1.1)' },
    '100%': { transform: 'translate(-50%, -50%) scale(1)' },
  },
}));

const StyledBalanceCard = styled(Box)(({ theme, themeName = 'default' }) => ({
  background: 'rgba(0, 0, 0, 0.2)',
  borderRadius: '12px',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  border: `1px solid ${themeConfigs[themeName].borderAccent}`,
}));

const ActionTile = styled(Box)(({ theme, themeName = 'default' }) => ({
  background: 'rgba(0, 0, 0, 0.3)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  border: `1px solid ${themeConfigs[themeName].borderAccent}`,
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  minWidth: '200px',
  '&:hover': {
    transform: 'translateY(-4px)',
    background: 'rgba(0, 0, 0, 0.4)',
    borderColor: themeConfigs[themeName].primary,
  },
}));

const StatsWidget = styled(Box)(({ theme, themeName = 'default' }) => ({
  background: 'rgba(0, 0, 0, 0.2)',
  borderRadius: '12px',
  padding: theme.spacing(2),
  border: `1px solid ${themeConfigs[themeName].borderAccent}`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const TransferCard = styled(Box)(({ theme, themeName = 'default' }) => ({
  background: 'rgba(0, 0, 0, 0.2)',
  borderRadius: '12px',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  border: `1px solid ${themeConfigs[themeName].borderAccent}`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}));

const AuthFlow = ({ 
  setTradingWallet, 
  tradingWallet, 
  fetchUserData,
  handleBuy,
  handleSell,
  ownedTokens = [],
  setTradeSettings,
  tradeSettings,
  tokenPurchaseHistory = [],
  publicKey
}) => {
  const { connected, publicKey: walletPublicKey } = useWallet();
  const [isCreatingWallet, setIsCreatingWallet] = useState(false);
  const [isCheckingBalance, setIsCheckingBalance] = useState(false);
  const [hasCheckedBalance, setHasCheckedBalance] = useState(false);
  const [tradingWalletBalance, setTradingWalletBalance] = useState(0);
  const [bnnaBalance, setBnnaBalance] = useState(0);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [currentTheme, setCurrentTheme] = useState('default');
  const [selectedIcon, setSelectedIcon] = useState('🐕');
  const [openIconModal, setOpenIconModal] = useState(false);
  const [connectedWalletBalance, setConnectedWalletBalance] = useState(0);
  const [transferAmount, setTransferAmount] = useState('');
  const [isTransferring, setIsTransferring] = useState(false);

  const checkWalletBalance = async () => {
    if (!connected || !walletPublicKey) {
      toast.error('Please connect your wallet first');
      return;
    }

    setIsCheckingBalance(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/${walletPublicKey.toString()}`);
      
      if (response.ok) {
        const userData = await response.json();
        if (userData?.tradingWallet) {
          setTradingWallet(userData.tradingWallet);
          // Fetch balance for trading wallet
          const balanceResponse = await fetch(`${process.env.REACT_APP_API_URL}api/sol-balance/${userData.tradingWallet.walletPublicKey}`);
          const balanceData = await balanceResponse.json();
          setTradingWalletBalance(balanceData.balance);

          // Fetch BNNA balance
          const tokenResponse = await fetch(`${process.env.REACT_APP_API_URL}api/user_balances/${walletPublicKey.toString()}`);
          const tokenData = await tokenResponse.json();
          if (tokenData.success && tokenData.tokens) {
            const bnnaToken = tokenData.tokens.find(token => token.mint === BNNA_TOKEN_MINT);
            setBnnaBalance(bnnaToken ? bnnaToken.balance : 0);
          }
        }
      }
      setHasCheckedBalance(true);
    } catch (error) {
      console.error('Error checking wallet:', error);
      toast.error('Failed to check wallet balance');
    } finally {
      setIsCheckingBalance(false);
    }
  };

  const createTradingWallet = async () => {
    if (!connected || !walletPublicKey) {
      toast.error('Please connect your wallet first');
      return;
    }

    setIsCreatingWallet(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/create-trading-wallet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          walletAddress: walletPublicKey.toString()
        })
      });

      if (!response.ok) throw new Error('Failed to create trading wallet');

      const data = await response.json();
      setTradingWallet(data.tradingWallet);
      toast.success('Trading wallet created successfully!');
      await fetchUserData();
    } catch (error) {
      console.error('Error creating wallet:', error);
      toast.error('Failed to create trading wallet');
    } finally {
      setIsCreatingWallet(false);
    }
  };

  const formatBalance = (balance) => {
    return parseFloat(balance).toFixed(4);
  };
  const determineTier = (bnnaBalance) => {
    if (bnnaBalance >= 5000000) return { name: 'Elite Tier', color: '#b71c1c', icon: '💎' };
    if (bnnaBalance >= 1000000) return { name: 'Pro Tier', color: '#1a237e', icon: '🚀' };
    return { name: 'Basic Tier', color: '#1565c0', icon: '⭐' };
  };

  const handleOpenIconModal = () => setOpenIconModal(true);
  const handleCloseIconModal = () => setOpenIconModal(false);

  const fetchConnectedWalletBalance = async () => {
    if (!walletPublicKey) return;
    console.log('fetching connected wallet balance');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/sol-balance/${walletPublicKey}`);
      const data = await response.json();
      setConnectedWalletBalance(data.balance);
    } catch (error) {
      console.error('Error fetching connected wallet balance:', error);
    }
  };

  const handleTransfer = async () => {
    if (!walletPublicKey || !tradingWallet?.walletPublicKey) {
      toast.error('Please connect your wallet and create a trading wallet first');
      return;
    }

    if (!transferAmount || isNaN(transferAmount) || parseFloat(transferAmount) <= 0) {
      toast.error('Please enter a valid amount');
      return;
    }

    setIsTransferring(true);
    try {
      // Get the transaction from our API
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/send-presale`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          amount: parseFloat(transferAmount),
          recipientAddress: tradingWallet.walletPublicKey,
          senderAddress: walletPublicKey.toString()
        })
      });

      const data = await response.json();
      if (!data.success) throw new Error(data.error);

      // Sign and send the transaction
      const signedTx = await window.solana.signTransaction(
        Transaction.from(Buffer.from(data.transaction, 'base64'))
      );

      // Send the signed transaction to be processed
      const confirmResponse = await fetch(`${process.env.REACT_APP_API_URL}api/confirm-presale`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          signedTransaction: signedTx.serialize().toString('base64')
        })
      });

      const confirmData = await confirmResponse.json();
      if (!confirmData.success) throw new Error(confirmData.error);

      toast.success('Transfer successful!');
      // Refresh balances
      fetchConnectedWalletBalance();
      checkWalletBalance();
      setTransferAmount('');
    } catch (error) {
      console.error('Transfer error:', error);
      toast.error(error.message || 'Transfer failed');
    } finally {
      setIsTransferring(false);
    }
  };

  useEffect(() => {
    if (walletPublicKey) {
      fetchConnectedWalletBalance();
    }
  }, [walletPublicKey]);

  return (
    <Box sx={{ 
      maxWidth: 600, 
      mx: 'auto', 
      p: 3, 
      position: 'relative',
      minHeight: '80vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }}>
      <ThemeBackground themeName={currentTheme} />
      <StyledPaper elevation={24} themeName={currentTheme}>
        <Stack spacing={3} alignItems="center">
          <Box sx={{ 
            display: 'flex',
            gap: 1,
            mb: 2,
            justifyContent: 'center'
          }}>
            {Object.keys(themeConfigs).map((theme) => (
              <IconButton
                key={theme}
                onClick={() => setCurrentTheme(theme)}
                sx={{
                  border: '2px solid',
                  borderColor: currentTheme === theme ? 
                    themeConfigs[theme].primary : 
                    'rgba(255, 255, 255, 0.1)',
                  borderRadius: '12px',
                  padding: '8px',
                  transition: 'all 0.3s ease',
                  transform: currentTheme === theme ? 'scale(1.1)' : 'scale(1)',
                  '&:hover': {
                    transform: 'scale(1.1)',
                    borderColor: themeConfigs[theme].primary
                  }
                }}
              >
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    background: themeConfigs[theme].gradient,
                  }}
                />
              </IconButton>
            ))}
          </Box>
          <WalletMultiButton />

          {connected && !hasCheckedBalance && (
            <ActionTile
              themeName={currentTheme}
              onClick={checkWalletBalance}
              sx={{
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              {isCheckingBalance ? (
                <CircularProgress size={40} sx={{ color: themeConfigs[currentTheme].primary }} />
              ) : (
                <>
                  <Wallet sx={{ fontSize: 40, color: themeConfigs[currentTheme].primary }} />
                  <Typography variant="h6" sx={{ color: '#fff', textAlign: 'center' }}>
                    Check Wallet Status
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'center' }}>
                    View your wallet details and balances
                  </Typography>
                </>
              )}
            </ActionTile>
          )}

          {connected && hasCheckedBalance && !tradingWallet && (
            <ActionTile
              themeName={currentTheme}
              onClick={createTradingWallet}
              sx={{
                background: `linear-gradient(135deg, rgba(0,0,0,0.4), rgba(34,197,94,0.2))`,
              }}
            >
              {isCreatingWallet ? (
                <CircularProgress size={40} sx={{ color: '#22c55e' }} />
              ) : (
                <>
                  <Box sx={{ 
                    fontSize: 40, 
                    background: 'linear-gradient(to right, #22c55e, #10b981)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}>
                    🚀
                  </Box>
                  <Typography variant="h6" sx={{ color: '#fff', textAlign: 'center' }}>
                    Create Trading Wallet
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'center' }}>
                    Set up your trading account to get started
                  </Typography>
                </>
              )}
            </ActionTile>
          )}

          {connected && tradingWallet && (
            <Box sx={{ width: '100%' }}>
              <SectionDivider 
                title="Wallet Information" 
                icon={<Wallet sx={{ color: '#fbbf24' }} />} 
              />
              <StyledBalanceCard themeName={currentTheme}>
                <Typography variant="subtitle1" sx={{ 
                  color: '#4ade80',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <img src="https://pbs.twimg.com/profile_images/1472933274209107976/6u-LQfjG_400x400.jpg" alt="SOL" style={{ width: 20, height: 20 }} />
                  Connected Wallet: {formatBalance(connectedWalletBalance)} SOL
                </Typography>
              </StyledBalanceCard>

              <TransferCard themeName={currentTheme}>
                <Typography variant="subtitle2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  Transfer SOL to Trading Wallet
                </Typography>
                <Box sx={{ 
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center'
                }}>
                  <TextField
                    size="small"
                    value={transferAmount}
                    onChange={(e) => setTransferAmount(e.target.value)}
                    placeholder="Amount in SOL"
                    type="number"
                    disabled={isTransferring}
                    InputProps={{
                      startAdornment: (
                        <Box component="span" sx={{ mr: 1 }}>
                          <img src="https://pbs.twimg.com/profile_images/1472933274209107976/6u-LQfjG_400x400.jpg" alt="SOL" style={{ width: 16, height: 16 }} />
                        </Box>
                      ),
                    }}
                    sx={{
                      flex: 1,
                      '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        '& fieldset': {
                          borderColor: 'rgba(255, 255, 255, 0.2)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'rgba(255, 255, 255, 0.3)',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: themeConfigs[currentTheme].primary,
                        },
                      },
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={handleTransfer}
                    disabled={isTransferring || !transferAmount}
                    sx={{
                      background: themeConfigs[currentTheme].gradient,
                      '&:hover': {
                        background: themeConfigs[currentTheme].gradient,
                        opacity: 0.9,
                      },
                    }}
                  >
                    {isTransferring ? (
                      <CircularProgress size={24} sx={{ color: '#fff' }} />
                    ) : (
                      'Transfer'
                    )}
                  </Button>
                </Box>
              </TransferCard>

              <Box sx={{ height: 16 }} /> {/* Spacer */}
              
              <StyledBalanceCard themeName={currentTheme}>
                <Typography variant="subtitle1" sx={{ 
                  color: '#4ade80',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <img src="https://pbs.twimg.com/profile_images/1472933274209107976/6u-LQfjG_400x400.jpg" alt="SOL" style={{ width: 20, height: 20 }} />
                  Trading Wallet: {formatBalance(tradingWalletBalance)} SOL
                </Typography>

                <Typography variant="subtitle1" sx={{ 
                  color: '#4ade80',
                  mt: 1,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <img src="https://pbs.twimg.com/profile_images/1867966329254199296/1hUYs3ad_400x400.jpg" alt="BNNA" style={{ width: 20, height: 20, borderRadius: '50%' }} />
                  BNNA Balance: {formatBalance(bnnaBalance)}
                </Typography>

                <Box sx={{ 
                  mt: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                  pt: 2
                }}>
                  <Typography sx={{ 
                    color: determineTier(bnnaBalance).color,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    fontSize: '1.1rem',
                    fontWeight: 'bold'
                  }}>
                    {determineTier(bnnaBalance).icon} {determineTier(bnnaBalance).name}
                  </Typography>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => window.open('/tier-structure', '_blank')}
                    sx={{
                      color: 'rgba(255, 255, 255, 0.7)',
                      '&:hover': {
                        color: '#fff',
                        background: 'rgba(255, 255, 255, 0.1)'
                      }
                    }}
                  >
                    View Tiers
                  </Button>
                </Box>
              </StyledBalanceCard>

              <Box sx={{ mt: 4 }}>
                <SectionDivider 
                  title="Market Stats" 
                  icon={<TrendingUp sx={{ color: '#22c55e' }} />} 
                />
                
                <Box sx={{ 
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))',
                  gap: 2,
                  mt: 2
                }}>
                  <StatsWidget themeName={currentTheme}>
                    <Typography variant="subtitle2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      24h Top Gainer
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box sx={{ 
                        width: 24, 
                        height: 24, 
                        borderRadius: '50%',
                        background: '#111',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '0.8rem'
                      }}>
                        🚀
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ color: '#fff' }}>
                          $PEPE
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#22c55e' }}>
                          +142.5%
                        </Typography>
                      </Box>
                    </Box>
                  </StatsWidget>

                  <StatsWidget themeName={currentTheme}>
                    <Typography variant="subtitle2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      Trending
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box sx={{ 
                        width: 24, 
                        height: 24, 
                        borderRadius: '50%',
                        background: '#111',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '0.8rem'
                      }}>
                        💎
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ color: '#fff' }}>
                          $DOGE
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#22c55e' }}>
                          High Volume
                        </Typography>
                      </Box>
                    </Box>
                  </StatsWidget>

                  <StatsWidget themeName={currentTheme}>
                    <Typography variant="subtitle2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      Market Sentiment
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box sx={{ 
                        width: 24, 
                        height: 24, 
                        borderRadius: '50%',
                        background: '#111',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '0.8rem'
                      }}>
                        📈
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ color: '#fff' }}>
                          Bullish
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#22c55e' }}>
                          87% Positive
                        </Typography>
                      </Box>
                    </Box>
                  </StatsWidget>

                  <StatsWidget themeName={currentTheme}>
                    <Typography variant="subtitle2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      New Listings
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box sx={{ 
                        width: 24, 
                        height: 24, 
                        borderRadius: '50%',
                        background: '#111',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '0.8rem'
                      }}>
                        ⭐
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ color: '#fff' }}>
                          12 New Tokens
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#22c55e' }}>
                          Last 24h
                        </Typography>
                      </Box>
                    </Box>
                  </StatsWidget>
                </Box>
              </Box>

              <Box sx={{ mt: 4 }}>
                <SectionDivider 
                  title="Profile Settings" 
                  icon={<Launch sx={{ color: '#60a5fa' }} />} 
                />
                
                <ActionTile themeName={currentTheme} onClick={handleOpenIconModal}>
                  <Box sx={{ 
                    width: 60,
                    height: 60,
                    borderRadius: '12px',
                    background: themeConfigs[currentTheme].gradient,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '2rem'
                  }}>
                    {selectedIcon}
                  </Box>
                  <Typography variant="h6" sx={{ color: '#fff' }}>
                    Change Profile Icon
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'center' }}>
                    Customize your profile appearance
                  </Typography>
                </ActionTile>

                <Box sx={{ 
                  mt: 2,
                  p: 2,
                  background: 'rgba(0, 0, 0, 0.2)',
                  borderRadius: '12px',
                  border: `1px solid ${themeConfigs[currentTheme].borderAccent}`
                }}>
                  <Typography variant="subtitle2" sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 1 }}>
                    Trading Wallet Address
                  </Typography>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 1,
                    background: 'rgba(0, 0, 0, 0.3)',
                    p: 1,
                    borderRadius: '8px'
                  }}>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: '#fff',
                        flex: 1,
                        fontFamily: 'monospace'
                      }}
                    >
                      {tradingWallet?.walletPublicKey}
                    </Typography>
                    <Tooltip title="Copy Address">
                      <IconButton 
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(tradingWallet?.walletPublicKey);
                          toast.success('Address copied to clipboard!');
                        }}
                        sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                      >
                        <ContentCopy fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ mt: 4 }}>
                <SectionDivider 
                  title="Resources & Tools" 
                  icon={<Launch sx={{ color: '#60a5fa' }} />} 
                />
                
                <Box sx={{ 
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                  gap: 2,
                  mt: 2
                }}>
                  <ActionTile
                    themeName={currentTheme}
                    onClick={() => window.open('https://t.me/apeoutofficialbot', '_blank')}
                  >
                    <img src="https://pbs.twimg.com/profile_images/1867966329254199296/1hUYs3ad_400x400.jpg" alt="" width="40" height="40" />
                    <Typography variant="h6" sx={{ color: '#fff' }}>Trading Bot</Typography>
                    <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'center' }}>
                      Access automated trading features
                    </Typography>
                  </ActionTile>

                  <ActionTile
                    themeName={currentTheme}
                    onClick={() => setShowDemoModal(true)}
                  >
                    <Launch sx={{ fontSize: 40, color: themeConfigs[currentTheme].primary }} />
                    <Typography variant="h6" sx={{ color: '#fff' }}>Auto-Ape Tool</Typography>
                    <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'center' }}>
                      Configure automated trading
                    </Typography>
                  </ActionTile>

                  <ActionTile
                    themeName={currentTheme}
                    onClick={() => window.open('https://apeout.notion.site/16595c4b66bd80a8866fca86f11d9ee2', '_blank')}
                  >
                    <Receipt sx={{ fontSize: 40, color: themeConfigs[currentTheme].primary }} />
                    <Typography variant="h6" sx={{ color: '#fff' }}>API Docs</Typography>
                    <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'center' }}>
                      Developer documentation
                    </Typography>
                  </ActionTile>

                  <ActionTile
                    themeName={currentTheme}
                    onClick={() => window.open('https://t.me/apeoutmeme', '_blank')}
                  >
                    <img src="https://pbs.twimg.com/profile_images/1867966329254199296/1hUYs3ad_400x400.jpg" alt="" width="40" height="40" />
                    <Typography variant="h6" sx={{ color: '#fff' }}>Community</Typography>
                    <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'center' }}>
                      Join our community
                    </Typography>
                  </ActionTile>
                </Box>

                <Typography 
                  variant="caption" 
                  sx={{ 
                    display: 'block',
                    textAlign: 'center',
                    mt: 3,
                    color: 'rgba(255, 255, 255, 0.5)'
                  }}
                >
                  Need help? Join our community or contact degens@apeout.fun
                </Typography>
              </Box>
            </Box>
          )}
        </Stack>
        
        <DemoAutoApeModal 
          open={showDemoModal} 
          onClose={() => {
            console.log('Closing modal from AuthFlow');
            setShowDemoModal(false);
          }}
          tradingWallet={tradingWallet}
          handleBuy={handleBuy}
          handleSell={handleSell}
          ownedTokensData={ownedTokens}
          setTradeSettings={setTradeSettings}
          tradeSettings={tradeSettings}
          tokenPurchases={tokenPurchaseHistory}
          publicKey={walletPublicKey || publicKey}
          currentTheme={currentTheme}
        />
      </StyledPaper>

      <Modal
        open={openIconModal}
        onClose={handleCloseIconModal}
        aria-labelledby="icon-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'auto',
          maxWidth: '90%',
          bgcolor: 'rgba(17, 24, 39, 0.95)',
          border: `1px solid ${themeConfigs[currentTheme].borderAccent}`,
          borderRadius: '16px',
          boxShadow: 24,
          p: 4,
          backdropFilter: 'blur(10px)',
        }}>
          <Typography variant="h6" sx={{ 
            color: '#fff',
            mb: 3,
            textAlign: 'center'
          }}>
            Choose Your Profile Icon
          </Typography>
          
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            gap: 3
          }}>
            {Object.entries(profileIcons).map(([category, icons]) => (
              <Box key={category}>
                <Typography variant="subtitle2" sx={{ 
                  color: 'rgba(255, 255, 255, 0.7)',
                  mb: 1,
                  textTransform: 'capitalize'
                }}>
                  {category}
                </Typography>
                <Box sx={{ 
                  display: 'flex',
                  gap: 1,
                  flexWrap: 'wrap'
                }}>
                  {icons.map((icon) => (
                    <IconButton
                      key={icon}
                      onClick={() => {
                        setSelectedIcon(icon);
                        handleCloseIconModal();
                      }}
                      sx={{
                        width: 50,
                        height: 50,
                        border: '2px solid',
                        borderColor: selectedIcon === icon ? 
                          themeConfigs[currentTheme].primary : 
                          'rgba(255, 255, 255, 0.1)',
                        borderRadius: '12px',
                        fontSize: '2rem',
                        transition: 'all 0.2s ease',
                        transform: selectedIcon === icon ? 'scale(1.1)' : 'scale(1)',
                        '&:hover': {
                          transform: 'scale(1.1)',
                          borderColor: themeConfigs[currentTheme].primary
                        }
                      }}
                    >
                      {icon}
                    </IconButton>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AuthFlow;

