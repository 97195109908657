import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, CircularProgress, Button } from '@mui/material';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import AutoTPSLButton from '../components/trading/AutoTPSLButton';
import { useWallet } from '@solana/wallet-adapter-react';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { tradingService } from '../utils/tradingService';

const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  background: 'linear-gradient(to bottom, #111827, #000000)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3)
}));

const AutoTPSLPage = ({
  tradingWallet,
  isPremium
}) => {
  const { publicKey, connected } = useWallet();
  const [isLoading, setIsLoading] = useState(true);
  const [hasExistingWallet, setHasExistingWallet] = useState(false);
  const [localTradingWallet, setLocalTradingWallet] = useState(tradingWallet);
  const isFetching = React.useRef(false);
  const navigate = useNavigate();

  const checkWallet = async () => {
    if (isFetching.current || !publicKey) return;
    
    try {
      isFetching.current = true;
      setIsLoading(true);
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/${publicKey.toString()}`);
      
      if (!response.ok) {
        if (response.status === 404) {
          setHasExistingWallet(false);
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const existingUser = await response.json();
      
      if (existingUser?.tradingWallet) {
        const verifyResponse = await fetch(`${process.env.REACT_APP_API_URL}api/decrypt-key`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer true`
          },
          body: JSON.stringify({
            encryptedKey: existingUser.tradingWallet.privateKey,
            walletAddress: existingUser.tradingWallet.walletPublicKey
          })
        });
        
        if (!verifyResponse.ok) {
          throw new Error('Failed to verify trading wallet access');
        }
        
        setHasExistingWallet(true);
        setLocalTradingWallet(existingUser.tradingWallet);
      } else {
        setHasExistingWallet(false);
      }
    } catch (error) {
      console.error('Error checking wallet:', error);
      toast.error('Failed to check wallet status');
    } finally {
      setIsLoading(false);
      isFetching.current = false;
    }
  };

  useEffect(() => {
    if (connected && publicKey) {
      checkWallet();
    } else {
      setIsLoading(false);
    }
  }, [connected, publicKey]);

  const handleCreateWallet = () => {
    navigate('/'); // Navigate to main dashboard
  };

  const handleBuy = async (coin, amount, options) => {
    return tradingService.handleBuy(coin, amount, tradingWallet, {
      ...options,
      onSuccess: async (result) => {
        toast.success('Buy successful!');
        // Add any additional success handling
      },
      onError: (error) => {
        toast.error(`Buy failed: ${error.message}`);
      }
    });
  };

  const handleSell = async (coin, amount, options) => {
    return tradingService.handleSell(coin, amount, tradingWallet, {
      ...options,
      onSuccess: async (result) => {
        toast.success('Sell successful!');
        // Add any additional success handling
      },
      onError: (error) => {
        toast.error(`Sell failed: ${error.message}`);
      }
    });
  };

  const handleAutoTPSLSell = async (coin, amount, options) => {
    return tradingService.handleAutoTPSLSell(coin, amount, tradingWallet, {
      ...options,
      onSuccess: async (result) => {
        toast.success('Auto TP/SL sell successful!');
        // Add any additional success handling
      },
      onError: (error) => {
        toast.error(`Auto TP/SL sell failed: ${error.message}`);
      }
    });
  };

  const renderContent = () => {
    if (!connected) {
      return (
        <Typography sx={{ color: 'white', opacity: 0.7 }}>
          Please connect your wallet to use Auto TP/SL
        </Typography>
      );
    }

    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <CircularProgress size={24} sx={{ color: 'white' }} />
          <Typography sx={{ color: 'white' }}>
            Checking wallet status...
          </Typography>
        </Box>
      );
    }

    if (!hasExistingWallet) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ color: 'white', opacity: 0.7, mb: 2 }}>
            Please create a trading wallet first
          </Typography>
          <Button 
            variant="contained" 
            onClick={handleCreateWallet}
            sx={{
              background: 'linear-gradient(to right, #3b82f6, #1d4ed8)',
              '&:hover': {
                background: 'linear-gradient(to right, #2563eb, #1e40af)',
              }
            }}
          >
            Create Trading Wallet
          </Button>
        </Box>
      );
    }

    return (
      <AutoTPSLButton
        publicKey={publicKey}
        tradingWallet={localTradingWallet}
        handleBuy={handleBuy}
        handleSell={handleSell}
        handleAutoTPSLSell={handleAutoTPSLSell}
        isPremium={isPremium}
      />
    );
  };

  return (
    <StyledContainer maxWidth="lg">
      <Typography variant="h4" sx={{ color: 'white', mb: 3 }}>
        Auto Take Profit / Stop Loss
      </Typography>
      
      <Box sx={{ mb: 3 }}>
        <WalletMultiButton />
      </Box>
      

      {renderContent()}
    </StyledContainer>
  );
};

export default AutoTPSLPage;