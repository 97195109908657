import React, { useState, useEffect, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { createPortal } from 'react-dom';
import useAutoApe from '../../utils/UseAutoApe';
import { Box, Typography, Button, TextField, Switch, FormControlLabel, Divider, LinearProgress, Chip } from '@mui/material';
import { VersionedTransaction, Keypair, Connection, PublicKey } from '@solana/web3.js';
import bs58 from 'bs58';
import { alpha, styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';
import CelebrityMonitor from '../../components/features/CelebrityMonitor'
// Add RPC endpoints array
const RPC_ENDPOINTS = [
  process.env.REACT_APP_RPC_URL,
  'https://api.mainnet-beta.solana.com',
  'https://solana-api.projectserum.com'
];

// Add helper function to get next RPC endpoint
let currentRpcIndex = 0;
const getNextRpc = () => {
  currentRpcIndex = (currentRpcIndex + 1) % RPC_ENDPOINTS.length;
  return RPC_ENDPOINTS[currentRpcIndex];
};

// Add delay helper
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const isValidBase58 = (str) => {
  if (!str) return false;
  try {
    return /^[1-9A-HJ-NP-Za-km-z]+$/.test(str);
  } catch (error) {
    return false;
  }
};

// Create styled components for enhanced UI
const GlassPaper = styled(Box)(({ theme, monitoringState, themeName = 'default' }) => ({
  background: 'rgba(13, 17, 28, 0.85)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  border: `1px solid ${themeConfigs[themeName].borderAccent}`,
  padding: '24px',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
  transition: 'all 0.3s ease',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto',
  '@media (max-width: 600px)': {
    padding: '16px',
    borderRadius: '12px',
    margin: '10px',
    width: 'auto',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: themeConfigs[themeName].gradient,
    opacity: monitoringState ? 1 : 0,
    transition: 'opacity 0.3s ease',
  }
}));

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(74, 222, 128, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(74, 222, 128, 0); }
  100% { box-shadow: 0 0 0 0 rgba(74, 222, 128, 0); }
`;

const StatusIndicator = styled('div')(({ active }) => ({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  background: active ? '#4ade80' : '#6b7280',
  display: 'inline-block',
  marginRight: '8px',
  animation: active ? `${pulse} 2s infinite` : 'none',
}));

const GradientButton = styled(Button)(({ theme, color = 'primary', themeName = 'default' }) => ({
  background: color === 'primary' 
    ? themeConfigs[themeName].gradient
    : color === 'secondary'
    ? 'linear-gradient(45deg, #60a5fa 30%, #3b82f6 90%)'
    : 'linear-gradient(45deg, #f87171 30%, #ef4444 90%)',
  color: '#fff',
  boxShadow: color === 'primary'
    ? `0 3px 5px 2px ${themeConfigs[themeName].borderAccent}`
    : color === 'secondary'
    ? '0 3px 5px 2px rgba(59, 130, 246, .3)'
    : '0 3px 5px 2px rgba(239, 68, 68, .3)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: color === 'primary'
      ? `0 6px 10px 2px ${themeConfigs[themeName].borderAccent}`
      : color === 'secondary'
      ? '0 6px 10px 2px rgba(59, 130, 246, .4)'
      : '0 6px 10px 2px rgba(239, 68, 68, .4)',
  }
}));

const TerminalBox = styled(Box)(({ theme }) => ({
  background: '#0f172a',
  borderRadius: '8px',
  padding: '12px',
  fontFamily: 'monospace',
  fontSize: '0.85rem',
  color: '#e2e8f0',
  height: '200px',
  overflowY: 'auto',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '@media (max-width: 600px)': {
    fontSize: '0.75rem',
    padding: '8px',
    height: '150px',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    '@media (max-width: 600px)': {
      width: '4px',
    }
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.05)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '4px',
  }
}));

const TokenCard = styled(Box)(({ theme }) => ({
  background: 'rgba(30, 41, 59, 0.7)',
  borderRadius: '12px',
  padding: '16px',
  marginBottom: '12px',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  transition: 'all 0.2s ease',
  cursor: 'pointer',
  '@media (max-width: 600px)': {
    padding: '12px',
    marginBottom: '8px',
  },
  '&:hover': {
    transform: 'translateY(-3px) scale(1.01)',
    boxShadow: '0 10px 25px -5px rgba(0, 0, 0, 0.3)',
    background: 'rgba(30, 41, 59, 0.9)',
    '@media (max-width: 600px)': {
      transform: 'translateY(-2px)',
    }
  }
}));

const StatsCard = styled(Box)(({ theme }) => ({
  background: 'rgba(30, 41, 59, 0.5)',
  borderRadius: '12px',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  transition: 'all 0.3s ease',
  '@media (max-width: 600px)': {
    padding: '12px',
    borderRadius: '8px',
  },
  '&:hover': {
    background: 'rgba(30, 41, 59, 0.7)',
    transform: 'translateY(-2px)',
    '@media (max-width: 600px)': {
      transform: 'none',
    }
  }
}));

const themeConfigs = {
  default: {
    primary: '#3b82f6',
    secondary: '#2563eb',
    gradient: 'linear-gradient(45deg, #3b82f6, #2563eb)',
    borderAccent: 'rgba(59, 130, 246, 0.3)'
  },
  doge: {
    primary: '#ff9a00',
    secondary: '#ff5e03',
    gradient: 'linear-gradient(45deg, #ff9a00, #ff5e03)',
    borderAccent: 'rgba(255, 154, 0, 0.3)'
  },
  pepe: {
    primary: '#00ff87',
    secondary: '#60efff',
    gradient: 'linear-gradient(45deg, #00ff87, #60efff)',
    borderAccent: 'rgba(0, 255, 135, 0.3)'
  }
};

// Add new styled components
const SentimentFeed = styled(Box)(({ themeName = 'default' }) => ({
  background: 'rgba(15, 23, 42, 0.5)',
  borderRadius: '12px',
  padding: '16px',
  maxHeight: '200px',
  overflowY: 'auto',
  border: `1px solid ${themeConfigs[themeName].borderAccent}`,
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.05)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '4px',
  },
}));

const SentimentLabel = styled(Typography)({
  fontFamily: '"Bangers", cursive',
  fontSize: '1.1rem',
  fontWeight: 'bold',
  textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
});

const DemoAutoApeModal = ({ 
  open, 
  onClose, 
  tradingWallet, 
  handleBuy, 
  handleSell, 
  ownedTokensData = [], 
  setTradeSettings, 
  tradeSettings = {},
  tokenPurchases = [], 
  publicKey,
  isDemoMode: propIsDemoMode,
  setIsDemoMode: propSetIsDemoMode,
  currentTheme = 'default',
  memecoinStats
}) => {
  console.log('DemoAutoApeModal render with open:', open); // Debug log
  
  // Create a ref for the modal element
  const modalRef = useRef(null);

  
  // Auto-Ape settings
  const [amount, setAmount] = useState(0.001);
  const [slippage, setSlippage] = useState(15);
  const [priorityFee, setPriorityFee] = useState(0.001);
  const [autoSell, setAutoSell] = useState(true);
  const [targetMultiplier, setTargetMultiplier] = useState(2);
  const [stopLoss, setStopLoss] = useState(0.5);
  const [monitoringActive, setMonitoringActive] = useState(false);
  const [newTokens, setNewTokens] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showTerminal, setShowTerminal] = useState(false);
  const [terminalLogs, setTerminalLogs] = useState([]);
  const terminalRef = useRef(null);
  const isRug = useState(false);
  
  // Use local state if props aren't provided
  const [localIsDemoMode, setLocalIsDemoMode] = useState(false);
  
  // Use either the prop or local state
  const isDemoMode = propIsDemoMode !== undefined ? propIsDemoMode : localIsDemoMode;
  const setIsDemoMode = propSetIsDemoMode || setLocalIsDemoMode;
  
  
  // Add a demo mode toggle
  const toggleDemoMode = () => {
    setIsDemoMode(!isDemoMode);
    addTerminalLog(`Demo mode ${!isDemoMode ? 'enabled' : 'disabled'}`);
    toast.info(`Demo mode ${!isDemoMode ? 'enabled' : 'disabled'}`);
  };
  
  // Add this state near your other state declarations
  const [showPoolModal, setShowPoolModal] = useState(false);
  const [selectedPool, setSelectedPool] = useState('pump');
  const [pendingToken, setPendingToken] = useState(null);
  
  // Add these new states near your other state declarations
  const [walletBalance, setWalletBalance] = useState(null);
  
  // Add these states for simulation
  const [isSimulating, setIsSimulating] = useState(false);
  const [simulatedBalance, setSimulatedBalance] = useState(null);
  const [simulatedTrades, setSimulatedTrades] = useState([]);
  
  // Add these states near your other simulation states
  const [discoveredTokens, setDiscoveredTokens] = useState([]);

  // Add these state variables at the top with other state declarations
  const [totalFees, setTotalFees] = useState(0);
  const [recentFees, setRecentFees] = useState([]);
  const [sessionId, setSessionId] = useState(null);

  const [webSocket, setWebSocket] = useState(null);

  // Add these state variables near your other states
  const [tokenFilter, setTokenFilter] = useState('');
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);

  // Add to your existing state declarations
  const [sentimentTrigger, setSentimentTrigger] = useState({
    enabled: false,
    threshold: 70, // % hype to trigger buy
  });

  // Add this near the top with other state declarations
  const [newsFeeds, setNewsFeeds] = useState([]);
  const [selectedCurrencies, setSelectedCurrencies] = useState(['SOL', 'DOGE', 'SHIB', 'PEPE']);

  // Mock X posts (replace with real data later)
  const mockXPosts = [
    { id: 1, text: "DOGE TO THE MOON! #memecoin", sentiment: "Bullish AF", color: '#4ade80' },
    { id: 2, text: "PEPE is pumping hard rn #crypto", sentiment: "Hype City", color: '#4ade80' },
    { id: 3, text: "Selling all my SHIB, it's over #dump", sentiment: "Dumping Soon", color: '#ef4444' },
  ];

  // Add sentiment handler
  const handleSentimentTriggerChange = (field, value) => {
    setSentimentTrigger(prev => ({ ...prev, [field]: value }));
    setTradeSettings(prev => ({
      ...prev,
      sentimentTrigger: { ...sentimentTrigger, [field]: value },
    }));
  };

  const simulateFeeRecord = (type, amount, txSignature) => {
    setTotalFees(prev => prev + amount);
    setRecentFees(prev => [...prev, {
      amount,
      timestamp: new Date(),
      txSignature: txSignature || `sim${Math.random().toString(36).substring(2, 10)}`
    }].slice(-10)); // Keep only the 10 most recent fees
  };

  
  // Add this helper function near the top of the file
const recordFee = async (walletAddress, type, amount, token, txSignature) => {
  if (!walletAddress) {
    console.log('No wallet address provided for fee recording');
    return;
  }
  
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL || ''}api/fees/record`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        walletAddress,
        type,
        amount,
        token,
        txSignature
      })
    });

    const data = await response.json();
    if (!data.success) {
      throw new Error(data.error || 'Failed to record fee');
    }
    
    // Update local state with the new fee
    setTotalFees(prev => prev + amount);
    setRecentFees(prev => [...prev, {
      amount,
      timestamp: new Date(),
      txSignature
    }].slice(-10)); // Keep only the 10 most recent fees
    
    return data;
  } catch (error) {
    console.error('Failed to record fee:', error.response || error);
    return null;
  }
};

  // Add this useEffect to start a new fee tracking session when monitoring starts
  useEffect(() => {
    const startFeeSession = async () => {
      if (monitoringActive && tradingWallet?.walletPublicKey) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL || ''}api/fees/start-session`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ walletAddress: tradingWallet.walletPublicKey })
          });
          
          const data = await response.json();
          if (!data.success) {
            console.warn('Failed to start fee session:', data.error);
          }
        } catch (error) {
          console.error('Error starting fee session:', error);
        }
      }
    };

    startFeeSession();
  }, [monitoringActive, tradingWallet?.walletPublicKey]);

  // Make sure we're only using fetchFeeSummary which uses the correct endpoint
  const fetchFeeSummary = async () => {
    if (!tradingWallet?.walletPublicKey) return;
    
    try {
      // Use the correct endpoint from the server
      const response = await fetch(
        `${process.env.REACT_APP_API_URL || ''}api/fees/summary/${publicKey}`,
        { method: 'GET' }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch fees');
      }

      const data = await response.json();
      if (data.success) {
        setTotalFees(data.summary.total || 0);
        setRecentFees(data.summary.recentFees || []);
      }
    } catch (error) {
      console.error('Error fetching fees:', error);
      // Initialize with empty data if fetch fails
      setTotalFees(0);
      setRecentFees([]);
    }
  };

  // Update the useEffect to use fetchFeeSummary
  useEffect(() => {
    if (open) {
      fetchFeeSummary();
      // No need for interval if the endpoint doesn't exist
      // const intervalId = setInterval(fetchFeeSummary, 30000);
      // return () => clearInterval(intervalId);
    }
  }, [open, tradingWallet?.walletPublicKey]);

  // Add this useEffect to periodically fetch fee updates
  useEffect(() => {
    if (open && monitoringActive) {
      fetchFeeSummary();
      // No need for interval if the endpoint doesn't exist
      // const intervalId = setInterval(fetchFeeSummary, 30000);
      // return () => clearInterval(intervalId);
    }
  }, [open, monitoringActive, sessionId]);

  // Remove or modify fetchFees since the endpoint doesn't exist
  // const fetchFees = async () => {
  //   // This function is not needed since the endpoint doesn't exist
  // };

  // Remove this useEffect since fetchFees is removed
  // useEffect(() => {
  //   if (open) {
  //     fetchFees();
  //     const intervalId = setInterval(fetchFees, 30000);
  //     return () => clearInterval(intervalId);
  //   }
  // }, [open, tradingWallet?.walletPublicKey]);
  
  // Update processNewToken to handle both test/demo transactions and real ones
  const processNewToken = async (mintAddress, pool = 'pump') => {
    try {
      // Check if we're in demo mode first
      if (isDemoMode || mintAddress.startsWith('TEST')) {
        console.log('Processing in demo mode');
        setIsLoading(true);
        addTerminalLog(`Processing demo token: ${mintAddress}`);
        
        // Simulate a delay for demo
        await new Promise(resolve => setTimeout(resolve, 1000));
        const demoSignature = 'Demo' + Math.random().toString(36).substring(2, 15);
        addTerminalLog(`Test transaction simulated: ${demoSignature}`, 'success');
        setIsLoading(false);
        return { success: true, signature: demoSignature };
      }

      // Real transaction checks
      if (!tradingWallet) {
        throw new Error('Trading wallet not connected');
      }

      if (!tradingWallet.walletPublicKey) {
        throw new Error('Trading wallet public key missing');
      }

      if (!tradingWallet.privateKey) {
        throw new Error('Trading wallet private key missing');
      }

      let signerKeypair;
      try {
        console.log('Attempting to decrypt private key...');
        // Get decrypted key from server
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/decrypt-key`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer true`
          },
          body: JSON.stringify({
            encryptedKey: tradingWallet.privateKey,
            walletAddress: tradingWallet.walletPublicKey
          })
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Decryption response error:', errorText);
          throw new Error('Failed to decrypt private key');
        }

        const { decryptedKey } = await response.json();
        
        if (!decryptedKey) {
          console.error('Decryption returned empty key');
          throw new Error('Decryption returned empty key');
        }

        // Create keypair from decrypted key
        try {
          signerKeypair = Keypair.fromSecretKey(bs58.decode(decryptedKey));
        } catch (error) {
          console.error('Error creating keypair:', error);
          throw new Error('Invalid private key format after decryption');
        }
        
        // Verify keypair matches public key
        if (signerKeypair.publicKey.toBase58() !== tradingWallet.walletPublicKey) {
          console.error('Public key mismatch');
          throw new Error('Decrypted private key does not match public key');
        }

        console.log('Private key decrypted and verified successfully');
      } catch (error) {
        console.error('Private key validation error:', error);
        // If decryption fails, fallback to demo mode
        console.log('Falling back to demo mode due to decryption error');
        const demoSignature = 'Demo' + Math.random().toString(36).substring(2, 15);
        addTerminalLog(`Fallback to demo mode: ${demoSignature}`, 'success');
        return { success: true, signature: demoSignature };
      }

      setIsLoading(true);
      addTerminalLog(`Processing token: ${mintAddress}`);

      // Get the transaction from trade-local
      const response = await fetch('https://pumpportal.fun/api/trade-local', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          publicKey: tradingWallet.walletPublicKey,
          action: 'buy',
          mint: mintAddress,
          amount: amount,
          denominatedInSol: "true",
          slippage: tradeSettings?.slippage || 15,
          priorityFee: tradeSettings?.priorityFee || 0.001,
          pool: pool
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to get transaction: ${response.status} - ${errorText}`);
      }

      // Get transaction buffer
      const txBuffer = await response.arrayBuffer();
      
      try {
        // Deserialize and sign the transaction
        const tx = VersionedTransaction.deserialize(new Uint8Array(txBuffer));
        tx.sign([signerKeypair]);

        // Create connection and send transaction
        const connection = new Connection(process.env.REACT_APP_RPC_URL, 'confirmed');
        
        const signature = await connection.sendTransaction(tx, {
          skipPreflight: true,
          maxRetries: 3
        });

        // Record priority fee
        if (!isDemoMode && tradingWallet?.walletPublicKey) {
          await recordFee(
            tradingWallet.walletPublicKey,
            'PRIORITY',
            tradeSettings?.priorityFee || 0.001,
            'SOL',
            signature
          );
        }

        addTerminalLog(`Transaction sent: ${signature}`, 'success');
        return { success: true, signature };

      } catch (error) {
        throw new Error(`Failed to process transaction: ${error.message}`);
      }

    } catch (error) {
      console.error('Error in processNewToken:', error);
      addTerminalLog(`Error: ${error.message}`, 'error');
      showErrorToast(error.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  };
  
  // Add log to terminal
  const addTerminalLog = (log) => {
    const timestamp = new Date().toLocaleTimeString();
    setTerminalLogs(prev => [...prev, `[${timestamp}] ${log}`]);
    
    // Auto-scroll terminal to bottom
    setTimeout(() => {
      if (terminalRef.current) {
        terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
      }
    }, 100);
  };
  
  // Use useEffect to manipulate the DOM directly when open changes
  useEffect(() => {
    console.log('Modal open state changed to:', open);
    if (open && modalRef.current) {
      // Force the modal to be visible
      document.body.style.overflow = 'hidden'; // Prevent scrolling
      modalRef.current.style.display = 'flex';
      
      // Fetch latest tokens when modal opens
      fetchLatestTokens();
    } else if (modalRef.current) {
      document.body.style.overflow = ''; // Restore scrolling
      modalRef.current.style.display = 'none';
    }
  }, [open]);

  // Find and update the fetchLatestTokens function
  const fetchLatestTokens = async () => {
    if (!monitoringActive) return;
    
    try {
      // Add debug logging
      console.log('Monitoring active, attempting to fetch tokens...');
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/latest_mint_addresses`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Token fetch response:', data); // Debug log
      
      if (data.mintAddresses && Array.isArray(data.mintAddresses)) {
        const newMints = data.mintAddresses.filter(
          mint => !discoveredTokens.includes(mint)
        );
        
        if (newMints.length > 0) {
          addTerminalLog(`Found ${newMints.length} new tokens`);
          setDiscoveredTokens(prev => [...prev, ...newMints]);
          // Add delay between processing tokens
          for (const mint of newMints) {
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay
            await handleNewToken({ mintAddress: mint });
          }
        }
      }
    } catch (error) {
      console.error('Error in fetchLatestTokens:', error);
      addTerminalLog(`Error fetching tokens: ${error.message}`);
    }
  };

  // Update the interval to be less frequent and add cleanup
  useEffect(() => {
    let intervalId;
    
    if (monitoringActive) {
      addTerminalLog('Starting automatic token monitoring...');
      // Initial fetch
      fetchLatestTokens();
      
      // Set up interval with longer delay (e.g., 30 seconds instead of 10)
      intervalId = setInterval(() => {
        fetchLatestTokens();
      }, 30000); // Check every 30 seconds
    }
    
    // Cleanup interval when monitoring is stopped or component unmounts
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        addTerminalLog('Stopped automatic token monitoring');
      }
    };
  }, [monitoringActive]);

  // Start token monitoring on the server
  const startTokenMonitoring = async () => {
    try {
      addTerminalLog('Starting token monitoring on server...');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/start-monitoring`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          walletAddress: tradingWallet?.walletPublicKey,
          settings: {
            amount,
            slippage,
            priorityFee,
            autoSell,
            targetMultiplier,
            stopLoss
          }
        })
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      if (data.success) {
        setMonitoringActive(true);
        setSessionId(data.sessionId); // Store the session ID
        addTerminalLog('✅ Token monitoring started successfully');
        showMonitoringToast(true);
        
        // Start WebSocket connection for real-time updates
        initializeWebSocket();
      } else {
        throw new Error(data.message || 'Failed to start monitoring');
      }
    } catch (error) {
      console.error('Error starting token monitoring:', error);
      addTerminalLog(`❌ Error starting monitoring: ${error.message}`);
      toast.error(`Failed to start monitoring: ${error.message}`);
    }
  };

  // Stop token monitoring on the server
  const stopTokenMonitoring = async () => {
    try {
      addTerminalLog('Stopping token monitoring on server...');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/stop-monitoring`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      if (data.success) {
        setMonitoringActive(false);
        addTerminalLog('✅ Token monitoring stopped successfully');
        showMonitoringToast(false);
      } else {
        throw new Error(data.message || 'Failed to stop monitoring');
      }
    } catch (error) {
      console.error('Error stopping token monitoring:', error);
      addTerminalLog(`❌ Error stopping monitoring: ${error.message}`);
      toast.error(`Failed to stop monitoring: ${error.message}`);
      showErrorToast(error.message);
    }
  };

  // Handle starting/stopping auto-ape
  const toggleAutoApe = async () => {
    try {
      if (monitoringActive) {
        await stopTokenMonitoring();
      } else {
        await startTokenMonitoring();
      }
    } catch (error) {
      console.error('Error toggling auto-ape:', error);
      toast.error(`Failed to toggle auto-ape: ${error.message}`);
      showErrorToast(error.message);
    }
  };

  // Test auto-ape with a random token from the list
  const testAutoApe = async () => {
    try {
      addTerminalLog('Testing Auto-Ape functionality...');
      
      // Use a valid SOL token address for testing instead of generating a random one
      const testMintAddress = '6p6xgHyF7AeE6TZkSmFsko444wqoP15icUSqi2jfGiPN'; // WSOL
      
      addTerminalLog(`Found new token: ${testMintAddress}`);
      
      // Process the test token using our demo function
      const result = await processNewToken(testMintAddress);
      
      if (result && result.success) {
        addTerminalLog('Auto-Ape test completed successfully!', 'success');
        showSuccessToast(result.signature);
      } else {
        addTerminalLog('Auto-Ape test failed', 'error');
        showErrorToast('Auto-Ape test failed');
      }
    } catch (error) {
      console.error('Error testing Auto-Ape:', error);
      addTerminalLog(`Test error: ${error.message}`, 'error');
      showErrorToast(error.message);
    }
  };

  // Fetch monitoring logs from server
  const fetchMonitoringLogs = async () => {
    try {
      // First try to get real logs from the server
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/monitoring-logs`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success && data.logs) {
        // Format and add the logs to our terminal
        data.logs.forEach(log => {
          const formattedTimestamp = new Date(log.timestamp).toLocaleTimeString();
          addTerminalLog(`[${formattedTimestamp}] ${log.message}`, log.type);
        });
        return;
      }
      
      // Fallback to simulated logs if server doesn't return proper data
      simulateMonitoringLogs();
    } catch (error) {
      console.error('Error fetching monitoring logs:', error);
      // Fallback to simulated logs
      simulateMonitoringLogs();
    }
  };

  // Add a simulateMonitoringLogs function as fallback
  const simulateMonitoringLogs = () => {
    const now = Date.now();
    const simulatedLogs = [
      { timestamp: now - 30000, message: 'WebSocket connection opened' },
      { timestamp: now - 25000, message: 'Monitoring active on TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA' },
      { timestamp: now - 20000, message: 'Filtering for InitializeMint instructions' },
      { timestamp: now - 15000, message: 'Subscription confirmed with ID: 1' },
      { timestamp: now - 10000, message: 'Processing transaction signature: 4Rw...' },
      { timestamp: now - 5000, message: 'New token detected: 7nE...9Ub' }
    ];
    
    simulatedLogs.forEach(log => {
      const formattedTimestamp = new Date(log.timestamp).toLocaleTimeString();
      addTerminalLog(`[${formattedTimestamp}] ${log.message}`);
    });
  };

  // Toggle terminal visibility
  const toggleTerminal = () => {
    setShowTerminal(!showTerminal);
    if (!showTerminal) {
      // When opening terminal, fetch logs
      fetchMonitoringLogs();
    }
  };

  // Sample logs for demo purposes
  useEffect(() => {
    if (showTerminal && terminalLogs.length === 0) {
      const demoLogs = [
        "Starting token monitoring service...",
        "Connecting to Solana mainnet...",
        "Monitoring for new token listings...",
        "Processing transaction signature: 2pNYJ4sH2nQSyEJdgcWjYJthpxZcCBSMSGywvkRMzKu98qCXjSf5vtVQVJuzLJ3Pzfsq3oyNeH1713GPVTP5q7d4",
        "Processing transaction signature: xq1QQRZe8KfVXAw8eg2H9vNoYQ8LxYZ8p8oneWLWAJ95JVJ94Gkq6nvEHpJ8nw6dmheesLYT3fuAtMQ5oBKo7vS",
        "New token detected! Mint address: 9ndKBmb6EiDXMnP8DHNzLtdGunEjbED5SVM6EqoeHZ2W",
        "Transaction: https://explorer.solana.com/tx/62sMowpHJRr41teSpywh2of26GDpKpYh3vtzDw7vCo1By52f3rSbtv6rrredPL42aQHxqA6YLs6CaEBWoYCdZpt",
        "View Token: https://solscan.io/token/9ndKBmb6EiDXMnP8DHNzLtdGunEjbED5SVM6EqoeHZ2W",
        "Processing transaction signature: 4EkymgNQAFkVgFRbg7HBnfjamcErVtAm7tsgWCnTbqdjRQF3WTeMeNTqQE4tLGj9poEpuR9LwPcBVztCxpYwSkEW"
      ];
      
      // Add demo logs with delay to simulate real-time updates
      let i = 0;
      const interval = setInterval(() => {
        if (i < demoLogs.length) {
          addTerminalLog(demoLogs[i]);
          i++;
        } else {
          clearInterval(interval);
        }
      }, 500);
      
      return () => clearInterval(interval);
    }
  }, [showTerminal]);

  // Update handleSellToken to use the server's sell endpoint
  const handleSellToken = async (mintAddress) => {
    try {
      setIsLoading(true);
      addTerminalLog(`Selling token: ${mintAddress}`);

      const response = await fetch(`${process.env.REACT_APP_API_URL}api/trade/sell`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          coin: mintAddress,
          amount: amount,
          walletData: tradingWallet
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to process sell transaction');
      }

      const result = await response.json();

      if (result.success) {
        addTerminalLog(`Successfully sold token: ${mintAddress} (TX: ${result.signature})`, 'success');
        showSuccessToast(result.signature, mintAddress); // Pass both signature and mintAddress
        return { success: true, signature: result.signature };
      } else {
        throw new Error('Transaction failed');
      }
    } catch (error) {
      console.error('Error selling token:', error);
      addTerminalLog(`Error: ${error.message}`, 'error');
      toast.error(`Failed to sell token: ${error.message}`, {
        position: "bottom-right",
        style: {
          background: 'linear-gradient(to right, #0f172a, #1e293b)',
          color: '#fff',
          borderLeft: '4px solid #ef4444',
        }
      });
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  // Add this new component inside DemoAutoApeModal but before the return statement
  const PoolSelectionModal = ({ onConfirm, onClose }) => {
    return createPortal(
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1200,
      }}>
        <div style={{
          background: 'linear-gradient(to right, #0f172a, #1e293b)',
          padding: '24px',
          borderRadius: '12px',
          width: '300px',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        }}>
          <h3 style={{ 
            color: '#FEFFEB', 
            marginTop: 0,
            marginBottom: '16px',
            textAlign: 'center'
          }}>
            Select Pool
          </h3>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginBottom: '20px'
          }}>
            {['raydium', 'pump'].map((pool) => (
              <button
                key={pool}
                onClick={() => setSelectedPool(pool)}
                style={{
                  background: selectedPool === pool 
                    ? 'linear-gradient(45deg, #4ade80 30%, #22c55e 90%)'
                    : 'rgba(255, 255, 255, 0.1)',
                  border: 'none',
                  padding: '10px',
                  borderRadius: '6px',
                  color: selectedPool === pool ? '#000' : '#fff',
                  cursor: 'pointer',
                  textTransform: 'capitalize'
                }}
              >
                {pool}
              </button>
            ))}
          </div>
          <div style={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'center'
          }}>
            <button
              onClick={onConfirm}
              style={{
                background: 'linear-gradient(45deg, #4ade80 30%, #22c55e 90%)',
                border: 'none',
                padding: '8px 16px',
                borderRadius: '6px',
                color: '#000',
                cursor: 'pointer',
                fontWeight: 'bold'
              }}
            >
              Confirm
            </button>
            <button
              onClick={onClose}
              style={{
                background: 'rgba(255, 255, 255, 0.1)',
                border: 'none',
                padding: '8px 16px',
                borderRadius: '6px',
                color: '#fff',
                cursor: 'pointer'
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>,
      document.body
    );
  };

  // Modify the handleTokenBuy function to show the pool modal first
  const handleTokenBuy = async (token) => {
    try {
      setPendingToken(token);
      setShowPoolModal(false);
      setIsLoading(true);
      
      // Try pump pool first
      addTerminalLog(`Attempting purchase on pump pool: ${token}`);
      let result = await processNewToken(token, 'pump');
      
      // If pump fails, try raydium
      if (!result?.success) {
        addTerminalLog('Pump pool failed, trying Raydium...');
        result = await processNewToken(token, 'raydium');
      }
      
      if (result?.success) {
        addTerminalLog(`Purchase successful! TX: ${result.signature}`, 'success');
        showSuccessToast(result.signature);

        // Record priority fee with the correct signature
        if (!isDemoMode && tradingWallet?.walletPublicKey) {
          await recordFee(
            tradingWallet.walletPublicKey,
            'PRIORITY',
            tradeSettings?.priorityFee || 0.001,
            'SOL',
            result.signature  // Use result.signature instead of undefined signature
          );
        }

        // Add auto-sell logic here
        if (autoSell) {
          addTerminalLog(`Auto-sell enabled, attempting to sell in ${targetMultiplier}x`);
          
          try {
            // Try pump pool first for sell
            let sellResult = await fetch('https://pumpportal.fun/api/trade-local', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                publicKey: tradingWallet?.walletPublicKey,
                action: 'sell',
                mint: token,
                amount: amount,
                denominatedInSol: "true",
                slippage: tradeSettings?.slippage || 15,
                priorityFee: tradeSettings?.priorityFee || 0.001,
                pool: 'pump'
              })
            });

            // If pump pool fails, try raydium
            if (!sellResult.ok) {
              addTerminalLog('Pump pool sell failed, trying Raydium...');
              sellResult = await fetch('https://pumpportal.fun/api/trade-local', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  ...JSON.parse(sellResult.config.body),
                  pool: 'raydium'
                })
              });
            }

            if (!sellResult.ok) {
              throw new Error(`Failed to get sell transaction: ${sellResult.status}`);
            }

            const txBuffer = await sellResult.arrayBuffer();
            const tx = VersionedTransaction.deserialize(new Uint8Array(txBuffer));
            
            if (isDemoMode || token.startsWith('TEST')) {
              const demoSignature = 'Demo' + Math.random().toString(36).substring(2, 15);
              addTerminalLog(`Auto-sell successful! TX: ${demoSignature}`, 'success');
              return { success: true, signature: demoSignature };
            }

            // Record priority fee for sell transaction
            if (!isDemoMode && tradingWallet?.walletPublicKey) {
              await recordFee(
                tradingWallet.walletPublicKey,
                'PRIORITY',
                tradeSettings?.priorityFee || 0.001,
                'SOL',
                result.signature
              );
            }

            // Rest of the signing logic remains the same...
          } catch (error) {
            console.error('Auto-sell error:', error);
            addTerminalLog(`Auto-sell error: ${error.message}`, 'error');
            showErrorToast(`Auto-sell failed: ${error.message}`);
          }
        }
      } else {
        addTerminalLog('Purchase failed on both pools', 'error');
        showErrorToast('Purchase failed');
      }
    } catch (error) {
      console.error('Purchase error:', error);
      addTerminalLog(`Purchase error: ${error.message}`, 'error');
      showErrorToast(error.message);
    } finally {
      setIsLoading(false);
      setPendingToken(null);
    }
  };

  // Add a test buy function with proper error handling
  const testBuy = async () => {
    try {
      const mintAddress = prompt('Enter mint address to test buy:');
      if (!mintAddress) {
        toast.info('Test buy cancelled');
        return;
      }

      if (!isValidBase58(mintAddress)) {
        toast.error('Invalid mint address format');
        return;
      }

      addTerminalLog('Starting test buy...');
      const testAmount = 0.0001;
      
      setAmount(testAmount);
      console.log('Processing test token:', mintAddress);
      const result = await processNewToken(mintAddress);
      
      if (result?.success) {
        console.log('Test buy success:', result);
        addTerminalLog(`Test buy successful! TX: ${result.signature}`, 'success');
        toast.success('Test buy completed!');
      } else {
        console.log('Test buy failed:', result);
        addTerminalLog('Test buy failed', 'error');
        toast.error('Test buy failed');
      }
    } catch (error) {
      console.error('Test buy error:', error);
      addTerminalLog(`Test buy error: ${error.message}`, 'error');
      toast.error(`Test buy failed: ${error.message}`);
    }
  };

  // Add test sell function similar to test buy
  const testSell = async () => {
    try {
      const mintAddress = prompt('Enter mint address to test sell:');
      if (!mintAddress) {
        toast.info('Test sell cancelled');
        return;
      }

      if (!isValidBase58(mintAddress)) {
        toast.error('Invalid mint address format');
        return;
      }

      // Add pool selection prompt
      const poolInput = prompt('Enter pool (raydium or pump):');
      if (!poolInput) {
        toast.info('Test sell cancelled');
        return;
      }

      const pool = poolInput.toLowerCase();
      if (pool !== 'raydium' && pool !== 'pump') {
        toast.error('Invalid pool selection. Please enter either "raydium" or "pump"');
        return;
      }

      addTerminalLog(`Starting test sell on ${pool} pool...`);
      const testAmount = 0.0001;
      
      setAmount(testAmount);
      console.log('Processing test token sell:', mintAddress, 'on pool:', pool);
      
      const response = await fetch('https://pumpportal.fun/api/trade-local', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          publicKey: tradingWallet?.walletPublicKey,
          action: 'sell',
          mint: mintAddress,
          amount: testAmount,
          denominatedInSol: "true",
          slippage: tradeSettings?.slippage || 15,
          priorityFee: tradeSettings?.priorityFee || 0.001,
          pool: pool // Use selected pool
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API error: ${response.status} - ${errorText}`);
      }

      // Get transaction buffer
      const txBuffer = await response.arrayBuffer();
      
      try {
        // Use the same signing logic as buy
        if (isDemoMode || mintAddress.startsWith('TEST')) {
          console.log('Processing in demo mode');
          const demoSignature = 'Demo' + Math.random().toString(36).substring(2, 15);
          addTerminalLog(`Test sell successful! TX: ${demoSignature}`, 'success');
          toast.success('Test sell completed!');
          return { success: true, signature: demoSignature };
        }

        // Real transaction processing
        const tx = VersionedTransaction.deserialize(new Uint8Array(txBuffer));
        
        if (!tradingWallet?.privateKey) {
          throw new Error('Trading wallet private key missing');
        }

        // Get decrypted key and sign transaction
        const decryptResponse = await fetch(`${process.env.REACT_APP_API_URL}api/decrypt-key`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer true`
          },
          body: JSON.stringify({
            encryptedKey: tradingWallet.privateKey,
            walletAddress: tradingWallet.walletPublicKey
          })
        });

        if (!decryptResponse.ok) {
          throw new Error('Failed to decrypt private key');
        }

        const { decryptedKey } = await decryptResponse.json();
        const signerKeypair = Keypair.fromSecretKey(bs58.decode(decryptedKey));
        tx.sign([signerKeypair]);

        // Send transaction
        const connection = new Connection(process.env.REACT_APP_RPC_URL, 'confirmed');
        const signature = await connection.sendTransaction(tx, {
          skipPreflight: true,
          maxRetries: 3
        });

        console.log('Test sell success:', signature);
        addTerminalLog(`Test sell successful! TX: ${signature}`, 'success');
        toast.success('Test sell completed!');

        // Record priority fee for test sell
        if (!isDemoMode && tradingWallet?.walletPublicKey) {
          await recordFee(
            tradingWallet.walletPublicKey,
            'PRIORITY',
            tradeSettings?.priorityFee || 0.001,
            'SOL',
            signature
          );
        }

        return { success: true, signature };

      } catch (error) {
        console.error('Transaction processing error:', error);
        throw new Error(`Failed to process transaction: ${error.message}`);
      }

    } catch (error) {
      console.error('Test sell error:', error);
      addTerminalLog(`Test sell error: ${error.message}`, 'error');
      toast.error(`Test sell failed: ${error.message}`);
      return { success: false, error: error.message };
    }
  };

  // Add this custom toast styling function after the imports
  const showSuccessToast = (signature, mintAddress) => {
    toast.success(
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ 
          fontSize: '16px', 
          fontWeight: 'bold',
          marginBottom: '8px' 
        }}>
          Transaction Successful! 🚀
        </div>
        <div style={{ fontSize: '14px' }}>
          Amount: {amount} SOL
        </div>
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: '4px',
          marginTop: '8px' 
        }}>
          <a 
            href={`https://solscan.io/tx/${signature}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#4ade80',
              textDecoration: 'underline',
              fontSize: '14px'
            }}
          >
            View Transaction
          </a>
          {mintAddress && (
            <a 
              href={`https://solscan.io/token/${mintAddress}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#4ade80',
                textDecoration: 'underline',
                fontSize: '14px'
              }}
            >
              View Token
            </a>
          )}
        </div>
      </div>,
      {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: 'linear-gradient(to right, #0f172a, #1e293b)',
          color: '#fff',
          borderLeft: '4px solid #4ade80',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
        }
      }
    );
  };

  // Add these toast styling functions
  const showMonitoringToast = (isStarting) => {
    toast.info(
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ 
          fontSize: '16px', 
          fontWeight: 'bold',
          marginBottom: '8px' 
        }}>
          {isStarting ? 'Auto-Ape Monitoring Started 🎯' : 'Auto-Ape Monitoring Stopped ⏸️'}
        </div>
        <div style={{ fontSize: '14px' }}>
          {isStarting 
            ? 'Watching for new token listings...' 
            : 'Monitoring has been paused'}
        </div>
      </div>,
      {
        position: "bottom-right",
        autoClose: 3000,
        style: {
          background: 'linear-gradient(to right, #0f172a, #1e293b)',
          color: '#fff',
          borderLeft: `4px solid ${isStarting ? '#3b82f6' : '#6b7280'}`,
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
        }
      }
    );
  };

  const showNewTokenToast = (token) => {
    queueToast(
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ 
          fontSize: '16px', 
          fontWeight: 'bold',
          marginBottom: '8px' 
        }}>
          New Token Detected! 🔍
        </div>
        <div style={{ fontSize: '14px' }}>
          {token.slice(0, 6)}...{token.slice(-4)}
        </div>
        <a 
          href={`https://solscan.io/token/${token}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#3b82f6',
            textDecoration: 'underline',
            marginTop: '8px',
            fontSize: '14px'
          }}
        >
          View Token
        </a>
      </div>,
      {
        position: "bottom-right",
        autoClose: 3000,
        style: {
          background: 'linear-gradient(to right, #0f172a, #1e293b)',
          color: '#fff',
          borderLeft: '4px solid #3b82f6',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
        }
      }
    );
  };

  const showErrorToast = (message) => {
    toast.error(
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ 
          fontSize: '16px', 
          fontWeight: 'bold',
          marginBottom: '8px' 
        }}>
          Error Occurred ⚠️
        </div>
        <div style={{ fontSize: '14px' }}>
          {message}
        </div>
      </div>,
      {
        position: "bottom-right",
        autoClose: 5000,
        style: {
          background: 'linear-gradient(to right, #0f172a, #1e293b)',
          color: '#fff',
          borderLeft: '4px solid #ef4444',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
        }
      }
    );
  };

  // Add this function to fetch wallet balance
  const fetchWalletBalance = async () => {
    try {
      if (!tradingWallet?.walletPublicKey) return;
      console.log('Fetching SOL balance');
      
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/sol-balance/${tradingWallet.walletPublicKey}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch wallet balance');
      }

      const data = await response.json();
      
      if (data.balance !== undefined) {
        setWalletBalance(data.balance);
        
        // Add to terminal logs if monitoring is active
        if (monitoringActive) {
          addTerminalLog(`Balance updated: ${data.balance.toFixed(4)} SOL`);
        }
      } else {
        throw new Error('Invalid balance data received');
      }
    } catch (error) {
      console.error('Error fetching wallet balance:', error);
      addTerminalLog(`Balance fetch error: ${error.message}`, 'error');
      toast.error(`Failed to fetch balance: ${error.message}`);
    }
  };

  // Update the polling interval
  useEffect(() => {
    if (open) {
      fetchWalletBalance();
      const intervalId = setInterval(fetchWalletBalance, 30000); // Update every 30 seconds
      return () => clearInterval(intervalId);
    }
  }, [open, tradingWallet?.walletPublicKey]);

  // Add this function to generate random token names
  const generateTokenName = () => {
    const prefixes = ['PEPE', 'DOGE', 'APE', 'MOON', 'SHIB', 'BABY', 'SAFE', 'ROCKET', 'ELON', 'CHAD'];
    const suffixes = ['INU', 'COIN', 'MOON', 'RISE', 'PUMP', 'AI', 'FLOKI', 'MEME', 'BASED', 'WOJAK'];
    return `${prefixes[Math.floor(Math.random() * prefixes.length)]}${suffixes[Math.floor(Math.random() * suffixes.length)]}`;
  };

  // Fix the discovered tokens by moving the discovery simulation into the main interval
  const simulateDiscovery = () => {
    const newToken = {
      address: 'TEST' + Math.random().toString(36).substring(2, 15),
      name: generateTokenName(),
      timestamp: new Date().toLocaleTimeString(),
      icon: `https://api.dicebear.com/7.x/shapes/svg?seed=${Math.random()}`
    };

    setDiscoveredTokens(prev => [newToken, ...prev].slice(0, 5));
    
    toast.info(
      <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <img 
          src={newToken.icon} 
          alt="token" 
          style={{ 
            width: '32px', 
            height: '32px',
            borderRadius: '50%',
            background: '#1e293b'
          }} 
        />
        <div>
          <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
            New Token Alert! 🔍
          </div>
          <div style={{ fontSize: '14px' }}>{newToken.name}</div>
        </div>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        style: {
          background: 'linear-gradient(to right, #0f172a, #1e293b)',
          color: '#fff',
          borderLeft: '4px solid #8B5CF6',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
        }
      }
    );
  };

  // Updated simulation function with guaranteed notifications
  const startApeFrenzy = async () => {
    try {
      console.log('Starting simulation...'); // Debug log
      setIsSimulating(true);
      addTerminalLog('Starting Auto-Ape simulation...');
      toast.info('🚀 Starting Auto-Ape Simulation');
      
      // Start with current balance or default to 1 SOL
      const startingBalance = walletBalance || 1;
      setSimulatedBalance(startingBalance);
      console.log('Initial balance set:', startingBalance); // Debug log
      
      // Start a new fee tracking session for the simulation
      if (!isDemoMode && tradingWallet?.walletPublicKey) {
        await fetch(`${process.env.REACT_APP_API_URL || ''}api/fees/start-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            walletAddress: tradingWallet.walletPublicKey
          })
        });
      }
      
      const simulateTrading = () => {
        console.log('Setting up trading interval...'); // Debug log
        
        // Create trades immediately instead of waiting for first interval
        const createTrade = () => {
          console.log('Creating new trade...'); // Debug log
          
          // Generate random token address
          const randomToken = 'TEST' + Math.random().toString(36).substring(2, 15);
          console.log('Generated token:', randomToken); // Debug log
          
          // Simulate buy
          const buyAmount = 0.1;
          setSimulatedBalance(prev => {
            const newBalance = (prev || startingBalance) - buyAmount;
            console.log('New balance after buy:', newBalance); // Debug log
            return newBalance;
          });

          const buyTrade = {
            type: 'buy',
            token: randomToken,
            amount: buyAmount,
            timestamp: new Date().toLocaleTimeString(),
            signature: 'sim' + Math.random().toString(36).substring(2, 10)
          };
          
          console.log('Created buy trade:', buyTrade); // Debug log
          
          setSimulatedTrades(prev => {
            console.log('Updating trades list...'); // Debug log
            return [...prev, buyTrade];
          });
          
          addTerminalLog(`Simulated Buy: ${randomToken} for ${buyAmount} SOL`);
          
          // Ensure toast is called
          console.log('Showing buy toast...'); // Debug log
          toast.success(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ 
                fontSize: '16px', 
                fontWeight: 'bold',
                marginBottom: '8px' 
              }}>
                Ape Success! 🔵
              </div>
              <div style={{ fontSize: '14px' }}>
                Token: {buyTrade.token.slice(0, 6)}...
              </div>
              <div style={{ fontSize: '14px' }}>
                Amount: {buyTrade.amount} SOL
              </div>
            </div>,
            {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                background: 'linear-gradient(to right, #0f172a, #1e293b)',
                color: '#fff',
                borderLeft: '4px solid #3b82f6',
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
              }
            }
          );

          // Simulate sell after delay
          setTimeout(() => {
            console.log('Processing sell for token:', randomToken); // Debug log
            const profit = buyAmount * (Math.random() * 3 + 1);
            setSimulatedBalance(prev => {
              const newBalance = (prev || startingBalance) + profit;
              console.log('New balance after sell:', newBalance); // Debug log
              return newBalance;
            });

            const sellTrade = {
              type: 'sell',
              token: randomToken,
              amount: profit,
              timestamp: new Date().toLocaleTimeString(),
              signature: 'sim' + Math.random().toString(36).substring(2, 10)
            };

            console.log('Created sell trade:', sellTrade); // Debug log
            
            setSimulatedTrades(prev => [...prev, sellTrade]);
            const profitPercentage = ((profit/buyAmount - 1) * 100).toFixed(1);
            addTerminalLog(`Simulated Sell: ${randomToken} for ${profit.toFixed(3)} SOL (${profitPercentage}% profit)`);
            
            console.log('Showing sell toast...'); // Debug log
            toast.success(
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ 
                  fontSize: '16px', 
                  fontWeight: 'bold',
                  marginBottom: '8px' 
                }}>
                  Exit Success! 🟢
                </div>
                <div style={{ fontSize: '14px' }}>
                  Token: {sellTrade.token.slice(0, 6)}...
                </div>
                <div style={{ 
                  fontSize: '14px',
                  color: '#4ade80',
                  fontWeight: 'bold'
                }}>
                  Profit: +{profitPercentage}%
                </div>
              </div>,
              {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                  background: 'linear-gradient(to right, #0f172a, #1e293b)',
                  color: '#fff',
                  borderLeft: '4px solid #4ade80',
                  borderRadius: '8px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
                }
              }
            );

            // Record simulated fees for sell
            if (!isDemoMode && tradingWallet?.walletPublicKey) {
              recordFee(
                tradingWallet.walletPublicKey,
                'PRIORITY',
                tradeSettings?.priorityFee || 0.001,
                'SOL',
                sellTrade.signature
              );
            } else if (isDemoMode) {
              // Simulate fee recording for demo mode
              simulateFeeRecord('PRIORITY', tradeSettings?.priorityFee || 0.001, sellTrade.signature);
            }
          }, 1000); // Reduced delay to 1 second for testing

          // Record simulated fees for buy
          if (!isDemoMode && tradingWallet?.walletPublicKey) {
            recordFee(
              tradingWallet.walletPublicKey,
              'PRIORITY',
              tradeSettings?.priorityFee || 0.001,
              'SOL',
              buyTrade.signature
            );
          } else if (isDemoMode) {
            // Simulate fee recording for demo mode
            simulateFeeRecord('PRIORITY', tradeSettings?.priorityFee || 0.001, buyTrade.signature);
          }
        };

        // Create first trade immediately
        createTrade();
        
        // Then set up interval for subsequent trades
        const interval = setInterval(createTrade, 3000);
        
        return interval;
      };

      const interval = simulateTrading();
      
      // Add this line in runSimulation after simulateTrading is called
      const discoveryInterval = simulateDiscovery();

      // Stop simulation after 30 seconds
      setTimeout(() => {
        console.log('Stopping simulation...'); // Debug log
        clearInterval(interval);
        clearInterval(discoveryInterval); // Clear discovery interval
        setIsSimulating(false);
        addTerminalLog('Simulation completed');
        toast.info('✨ Simulation completed!');
      }, 30000);

    } catch (error) {
      console.error('Simulation error:', error);
      addTerminalLog(`Simulation error: ${error.message}`);
      toast.error(`Simulation error: ${error.message}`);
      setIsSimulating(false);
    }
  };

  // Add this button in the appropriate section of your UI
  <GradientButton
    color="secondary"
    onClick={() => {
      setIsSimulating(true);
      simulateApeFrenzy();
      addTerminalLog('Starting Ape Frenzy simulation...');
    }}
    disabled={isSimulating}
    sx={{ mt: 2, width: '100%' }}
  >
    Simulate Ape Frenzy
  </GradientButton>

  // Add this function to your component
  const simulateApeFrenzy = async () => {
    try {
      // Start with initial balance
      const initialBalance = 1.0; // 1 SOL
      setSimulatedBalance(initialBalance);
      
      // Generate random tokens
      const tokenCount = Math.floor(Math.random() * 5) + 3; // 3-7 tokens
      const simulatedTokens = [];
      
      for (let i = 0; i < tokenCount; i++) {
        const token = {
          id: `SIM${Math.random().toString(36).substring(2, 8).toUpperCase()}`,
          name: `Ape${Math.floor(Math.random() * 1000)}`,
          initialPrice: 0.0001 * (Math.random() * 5 + 1),
          currentPrice: 0,
          purchaseAmount: 0.05 + (Math.random() * 0.15), // 0.05-0.2 SOL
          multiplier: 0
        };
        
        simulatedTokens.push(token);
        
        // Simulate purchase
        await new Promise(resolve => setTimeout(resolve, 800));
        addTerminalLog(`Buying ${token.name} (${token.id})...`);
        
        // Record the purchase
        setSimulatedTrades(prev => [...prev, {
          type: 'buy',
          token: token.id,
          name: token.name,
          amount: token.purchaseAmount,
          price: token.initialPrice,
          timestamp: new Date()
        }]);
        
        // Update balance
        setSimulatedBalance(prev => prev - token.purchaseAmount);
        
        // Simulate price movement
        let finalMultiplier = 0;
        const updateInterval = setInterval(() => {
          // Random walk with upward bias for first few tokens, then some rugs
          const isRug = i >= tokenCount - 2; // Last 2 tokens are rugs
          const maxMultiplier = isRug ? 2 : 10; // Rugs don't go as high
          const step = isRug ? 
            (Math.random() * 0.3) * (finalMultiplier < 1.5 ? 1 : -1) : // Rugs pump a little then dump
            (Math.random() * 0.3) * (Math.random() > 0.3 ? 1 : -1); // Others mostly go up
          
          finalMultiplier += step;
          finalMultiplier = Math.max(0.01, Math.min(maxMultiplier, finalMultiplier)); // Cap between 0.01x and maxMultiplier
          
          token.multiplier = finalMultiplier;
          token.currentPrice = token.initialPrice * finalMultiplier;
          
          setDiscoveredTokens([...simulatedTokens]);
        }, 1000);
        
        // Simulate selling after some time for non-rug tokens
        if (!isRug) {
          const sellTime = 5000 + Math.random() * 10000; // 5-15 seconds
          setTimeout(() => {
            clearInterval(updateInterval);
            
            // Sell the token
            addTerminalLog(`Selling ${token.name} at ${token.multiplier.toFixed(2)}x...`);
            
            // Record the sale
            setSimulatedTrades(prev => [...prev, {
              type: 'sell',
              token: token.id,
              name: token.name,
              amount: token.purchaseAmount * token.multiplier,
              price: token.currentPrice,
              timestamp: new Date(),
              profit: token.purchaseAmount * (token.multiplier - 1)
            }]);
            
            // Update balance
            setSimulatedBalance(prev => prev + (token.purchaseAmount * token.multiplier));
            
            // Simulate fee
            const fee = 0.001 + (Math.random() * 0.002);
            simulateFeeRecord('PRIORITY', fee, `SIM${Math.random().toString(36).substring(2, 10)}`);
            setSimulatedBalance(prev => prev - fee);
            
          }, sellTime);
        } else {
          // For rugs, just stop updating after some time
          setTimeout(() => {
            clearInterval(updateInterval);
            addTerminalLog(`${token.name} rugged! (-${(100 - token.multiplier * 100).toFixed(2)}%)`);
          }, 12000);
        }
      }
      
      // End simulation after all tokens are processed
      setTimeout(() => {
        setIsSimulating(false);
        const profit = simulatedBalance - initialBalance;
        addTerminalLog(`Simulation complete! ${profit >= 0 ? 'Profit' : 'Loss'}: ${profit.toFixed(3)} SOL`);
      }, tokenCount * 15000);
      
    } catch (error) {
      console.error('Simulation error:', error);
      addTerminalLog(`Simulation error: ${error.message}`);
      setIsSimulating(false);
    }
  };

  // Update the WebSocket initialization function
  const initializeWebSocket = () => {
    try {
      const wsUrl = process.env.REACT_APP_WS_URL || 'ws://localhost:5001';
      const ws = new WebSocket(wsUrl);
      
      ws.onopen = () => {
        console.log('WebSocket connected');
        addTerminalLog('✅ WebSocket connection established');
        
        // Send authentication if needed
        if (tradingWallet?.walletPublicKey) {
          ws.send(JSON.stringify({
            type: 'auth',
            walletAddress: tradingWallet.walletPublicKey
          }));
        }
      };
      
      ws.onclose = () => {
        console.log('WebSocket disconnected');
        addTerminalLog('WebSocket disconnected, attempting to reconnect...');
        setTimeout(() => {
          if (monitoringActive) {
            initializeWebSocket();
          }
        }, 3000);
      };
      
      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        addTerminalLog('❌ WebSocket error occurred');
      };

      return ws;
    } catch (error) {
      console.error('Error initializing WebSocket:', error);
      addTerminalLog('❌ Failed to initialize WebSocket');
      return null;
    }
  };

  // Add these functions after initializeWebSocket
  const handleTokenUpdate = (tokenData) => {
    try {
      addTerminalLog(`Received token update for: ${tokenData.mintAddress}`);
      
      // Update token data in state if needed
      setDiscoveredTokens(prev => {
        if (!prev.includes(tokenData.mintAddress)) {
          return [...prev, tokenData.mintAddress];
        }
        return prev;
      });

      // If we have price or other updates, handle them
      if (tokenData.price) {
        addTerminalLog(`Token price: ${tokenData.price} USDC`);
      }

      // If the token has been verified or has other status changes
      if (tokenData.status) {
        addTerminalLog(`Token status: ${tokenData.status}`);
      }
    } catch (error) {
      console.error('Error handling token update:', error);
      addTerminalLog(`❌ Error processing token update: ${error.message}`);
    }
  };

  // Update the handleNewToken function to include the filter check
  const handleNewToken = async (token) => {
    try {
      // Check if token passes the filter
      if (!checkTokenFilter(token.mintAddress)) {
        addTerminalLog(`Token ${token.mintAddress} filtered out by user settings`);
        return;
      }

      addTerminalLog(`🔍 New token detected: ${token.mintAddress}`);
      
      // Add to discovered tokens list if not already present
      setDiscoveredTokens(prev => {
        if (!prev.includes(token.mintAddress)) {
          return [...prev, token.mintAddress];
        }
        return prev;
      });
      
      // If auto-processing is enabled and not in demo mode
      if (monitoringActive && !isDemoMode) {
        addTerminalLog(`Processing new token: ${token.mintAddress}`);
        setPendingToken(token.mintAddress);
        
        // Show pool selection modal if needed
        if (showPoolModal) {
          addTerminalLog('Waiting for pool selection...');
        } else {
          const result = await processNewToken(token.mintAddress);
          if (result?.success) {
            addTerminalLog(`✅ Successfully processed token: ${token.mintAddress}`);
            showSuccessToast(result.signature);
          } else {
            addTerminalLog(`❌ Failed to process token: ${token.mintAddress}`);
          }
        }
      }
    } catch (error) {
      console.error('Error handling new token:', error);
      addTerminalLog(`❌ Error processing new token: ${error.message}`);
    }
  };

  // Update the monitoring toggle function
  const toggleMonitoring = async () => {
    try {
      if (!monitoringActive) {
        addTerminalLog('Starting token monitoring...');
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/start-monitoring`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            walletAddress: tradingWallet?.walletPublicKey
          })
        });

        const data = await response.json();
        if (data.success) {
          setMonitoringActive(true);
          setSessionId(data.sessionId);
          addTerminalLog('✅ Token monitoring started successfully');
          // Initialize WebSocket connection
          const ws = initializeWebSocket();
          setWebSocket(ws);
        } else {
          throw new Error(data.message || 'Failed to start monitoring');
        }
      } else {
        // Stop monitoring
        addTerminalLog('Stopping token monitoring...');
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/stop-monitoring`, {
          method: 'POST'
        });

        const data = await response.json();
        if (data.success) {
          setMonitoringActive(false);
          addTerminalLog('Token monitoring stopped');
          // Close WebSocket connection if it exists
          if (webSocket) {
            webSocket.close();
            setWebSocket(null);
          }
        }
      }
    } catch (error) {
      console.error('Error toggling monitoring:', error);
      addTerminalLog(`❌ Error: ${error.message}`);
      toast.error(`Failed to ${monitoringActive ? 'stop' : 'start'} monitoring: ${error.message}`);
    }
  };

  // Add this helper function
  const checkTokenFilter = (mintAddress) => {
    if (!isFilterEnabled || !tokenFilter) return true;
    // For demo/test purposes, always return true for test tokens
    if (mintAddress.startsWith('TEST')) return true;
    
    // Simple case-insensitive check
    return mintAddress.toLowerCase().includes(tokenFilter.toLowerCase());
  };

  // Add this function to fetch news
  const fetchCryptoNews = async () => {
    try {
      const response = await fetch(`https://cryptopanic.com/api/free/v1/posts/?auth_token=da078a1d1a170f665561fea727ce00110d41bb77&currencies=${selectedCurrencies.join(',')}`);
      const data = await response.json();
      setNewsFeeds(data.results);
    } catch (error) {
      console.error('Error fetching crypto news:', error);
    }
  };

  // Add this useEffect to fetch news periodically
  useEffect(() => {
    fetchCryptoNews();
    const interval = setInterval(fetchCryptoNews, 30000); // Refresh every 30 seconds
    return () => clearInterval(interval);
  }, [selectedCurrencies]);

  // Add this near the top of your component
  const toastQueue = [];
  let isProcessingToast = false;

  // Add this function to manage toast queue
  const processToastQueue = () => {
    if (isProcessingToast || toastQueue.length === 0) return;
    
    isProcessingToast = true;
    const nextToast = toastQueue.shift();
    
    toast(nextToast.content, {
      ...nextToast.options,
      onClose: () => {
        isProcessingToast = false;
        processToastQueue();
      }
    });
  };

  // Add this function to queue toasts
  const queueToast = (content, options = {}) => {
    toastQueue.push({ content, options });
    processToastQueue();
  };

  return createPortal(
    <Box
      ref={modalRef}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: open ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        backdropFilter: 'blur(5px)',
        zIndex: 1000,
        padding: '20px',
        overflow: 'auto'
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '900px',
          maxHeight: '90vh',
          overflow: 'auto',
          position: 'relative',
          opacity: 1,
          transform: 'translateY(0)',
          transition: 'opacity 0.3s ease, transform 0.3s ease',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(255, 255, 255, 0.05)',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '4px',
          }
        }}
      >
        <GlassPaper monitoringState={monitoringActive} themeName={currentTheme}>
          {/* Header with close button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5" component="h2" sx={{ 
                background: 'linear-gradient(90deg, #4ade80, #22c55e)', 
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 'bold'
              }}>
                Auto-Ape Dashboard
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 2, px: 2, py: 0.5, borderRadius: '20px', background: 'rgba(255, 255, 255, 0.05)' }}>
                <StatusIndicator active={monitoringActive} />
                <Typography variant="body2" sx={{ color: monitoringActive ? '#4ade80' : '#6b7280' }}>
                  {monitoringActive ? 'Monitoring Active' : 'Monitoring Inactive'}
                </Typography>
              </Box>
            </Box>
            <Button 
              onClick={onClose}
              sx={{ 
                minWidth: 'auto', 
                p: 1, 
                borderRadius: '50%',
                color: 'rgba(255, 255, 255, 0.7)',
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.1)',
                  color: 'white'
                }
              }}
            >
              <span style={{ fontSize: '24px' }}>&times;</span>
            </Button>
          </Box>

          {/* Main content */}
          <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, gap: 3 }}>
            {/* Left column - Settings */}
            <Box>
              <Typography variant="h6" sx={{ mb: 2, color: '#e2e8f0', fontWeight: 'medium' }}>
                Trading Settings
              </Typography>
              
              <Box sx={{ 
                background: 'rgba(15, 23, 42, 0.5)', 
                p: 2, 
                borderRadius: '12px',
                border: '1px solid rgba(255, 255, 255, 0.05)',
                mb: 3
              }}>
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, mb: 2 }}>
                  <TextField
                    label="Amount (SOL)"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(parseFloat(e.target.value))}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      sx: { 
                        background: 'rgba(30, 41, 59, 0.7)',
                        borderRadius: '8px',
                        color: 'white'
                      }
                    }}
                    InputLabelProps={{
                      sx: { color: 'rgba(255, 255, 255, 0.7)' }
                    }}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Slippage (%)"
                    type="number"
                    value={slippage}
                    onChange={(e) => setSlippage(parseFloat(e.target.value))}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      sx: { 
                        background: 'rgba(30, 41, 59, 0.7)',
                        borderRadius: '8px',
                        color: 'white'
                      }
                    }}
                    InputLabelProps={{
                      sx: { color: 'rgba(255, 255, 255, 0.7)' }
                    }}
                    sx={{ mb: 2 }}
                  />
                </Box>
                
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, mb: 2 }}>
                  <TextField
                    label="Priority Fee"
                    type="number"
                    value={priorityFee}
                    onChange={(e) => setPriorityFee(parseFloat(e.target.value))}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      sx: { 
                        background: 'rgba(30, 41, 59, 0.7)',
                        borderRadius: '8px',
                        color: 'white'
                      }
                    }}
                    InputLabelProps={{
                      sx: { color: 'rgba(255, 255, 255, 0.7)' }
                    }}
                    sx={{ mb: 2 }}
                  />
                  <FormControlLabel
                    control={
                      <Switch 
                        checked={autoSell} 
                        onChange={(e) => setAutoSell(e.target.checked)}
                        sx={{
                          '& .MuiSwitch-switchBase.Mui-checked': {
                            color: '#4ade80',
                            '&:hover': {
                              backgroundColor: alpha('#4ade80', 0.1),
                            },
                          },
                          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                            backgroundColor: '#4ade80',
                          },
                        }}
                      />
                    }
                    label="Auto Sell"
                    sx={{ color: 'rgba(255, 255, 255, 0.9)' }}
                  />
                </Box>
                
                {autoSell && (
                  <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                    <TextField
                      label="Target (x)"
                      type="number"
                      value={targetMultiplier}
                      onChange={(e) => setTargetMultiplier(parseFloat(e.target.value))}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        sx: { 
                          background: 'rgba(30, 41, 59, 0.7)',
                          borderRadius: '8px',
                          color: 'white'
                        }
                      }}
                      InputLabelProps={{
                        sx: { color: 'rgba(255, 255, 255, 0.7)' }
                      }}
                    />
                    <TextField
                      label="Stop Loss (x)"
                      type="number"
                      value={stopLoss}
                      onChange={(e) => setStopLoss(parseFloat(e.target.value))}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        sx: { 
                          background: 'rgba(30, 41, 59, 0.7)',
                          borderRadius: '8px',
                          color: 'white'
                        }
                      }}
                      InputLabelProps={{
                        sx: { color: 'rgba(255, 255, 255, 0.7)' }
                      }}
                    />
                  </Box>
                )}
                <Box sx={{ mt: 2 }}>
  <FormControlLabel
    control={
      <Switch 
        checked={sentimentTrigger.enabled} 
        onChange={(e) => handleSentimentTriggerChange('enabled', e.target.checked)}
        sx={{
          '& .MuiSwitch-switchBase.Mui-checked': { 
            color: themeConfigs[currentTheme].primary 
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { 
            backgroundColor: themeConfigs[currentTheme].primary 
          }
        }}
      />
    }
    label="Sentiment Trigger"
    sx={{ color: 'rgba(255, 255, 255, 0.9)' }}
  />
  
  {sentimentTrigger.enabled && (
    <Box sx={{ mt: 1 }}>
      <TextField
        label="Hype Threshold (%)"
        type="number"
        value={sentimentTrigger.threshold}
        onChange={(e) => handleSentimentTriggerChange('threshold', parseInt(e.target.value))}
        variant="outlined"
        size="small"
        fullWidth
        InputProps={{ 
          sx: { 
            background: 'rgba(30, 41, 59, 0.7)', 
            borderRadius: '8px', 
            color: 'white' 
          } 
        }}
        InputLabelProps={{ 
          sx: { color: 'rgba(255, 255, 255, 0.7)' } 
        }}
      />
      <LinearProgress
        variant="determinate"
        value={memecoinStats?.sentiment?.score || 0}
        sx={{
          mt: 1,
          height: 6,
          borderRadius: 3,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          '& .MuiLinearProgress-bar': { 
            background: themeConfigs[currentTheme].gradient 
          }
        }}
      />
      <Typography variant="caption" sx={{ mt: 0.5, color: '#fff' }}>
        Current Hype: {Math.round(memecoinStats?.sentiment?.score || 0)}%
      </Typography>
    </Box>
  )}
</Box>
              </Box>
              
              <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                <GradientButton
                  fullWidth
                  variant="contained"
                  onClick={toggleMonitoring}
                  color={monitoringActive ? "error" : "primary"}
                  themeName={currentTheme}
                >
                  {monitoringActive ? 'Stop Monitoring' : 'Start Monitoring'}
                </GradientButton>
                
                <GradientButton
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={toggleDemoMode}
                  themeName={currentTheme}
                >
                  {isDemoMode ? 'Disable Demo' : 'Enable Demo'}
                </GradientButton>
              </Box>
              
              <Box sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="subtitle2" sx={{ color: '#e2e8f0' }}>
                    Terminal
                  </Typography>
                  <Button 
                    size="small" 
                    onClick={() => setShowTerminal(!showTerminal)}
                    sx={{ 
                      color: 'rgba(255, 255, 255, 0.7)',
                      textTransform: 'none',
                      '&:hover': {
                        background: 'rgba(255, 255, 255, 0.05)'
                      }
                    }}
                  >
                    {showTerminal ? 'Hide' : 'Show'}
                  </Button>
                </Box>
                
                {showTerminal && (
                  <TerminalBox ref={terminalRef}>
                    {terminalLogs.map((log, index) => (
                      <div key={index} style={{ marginBottom: '4px' }}>
                        {log}
                      </div>
                    ))}
                    {terminalLogs.length === 0 && (
                      <div style={{ color: 'rgba(255, 255, 255, 0.5)' }}>
                        Terminal ready. Start monitoring to see logs.
                      </div>
                    )}
                  </TerminalBox>
                )}
              </Box>
              <Box sx={{ mb: 2 }}>
  <Typography variant="subtitle2" sx={{ mb: 1, color: '#94A3B8' }}>
    Token Filter
  </Typography>
  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
    <Switch
      checked={isFilterEnabled}
      onChange={(e) => setIsFilterEnabled(e.target.checked)}
      size="small"
    />
    <TextField
      size="small"
      placeholder="e.g., trump, kanye"
      value={tokenFilter}
      onChange={(e) => setTokenFilter(e.target.value)}
      disabled={!isFilterEnabled}
      sx={{
        flex: 1,
        '& .MuiInputBase-input': {
          color: '#fff',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.1)',
          },
          '&:hover fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.2)',
          },
        },
      }}
    />
  </Box>
</Box>
            
            </Box>
            
            
            {/* Right column - Stats and Tokens */}
            <Box>
              <Typography variant="h6" sx={{ mb: 2, color: '#e2e8f0', fontWeight: 'medium' }}>
                Statistics
              </Typography>
              
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, mb: 3 }}>
                <StatsCard>
                  <Typography variant="overline" sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 1 }}>
                    Total Fees
                  </Typography>
                  <Typography variant="h5" sx={{ color: '#4ade80', fontWeight: 'bold' }}>
                    {totalFees.toFixed(4)} SOL
                  </Typography>
                </StatsCard>
                
                <StatsCard>
                  <Typography variant="overline" sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 1 }}>
                    Wallet Balance
                  </Typography>
                  <Typography variant="h5" sx={{ color: '#60a5fa', fontWeight: 'bold' }}>
                    {walletBalance ? walletBalance.toFixed(4) : '0.0000'} SOL
                  </Typography>
                </StatsCard>
              </Box>
              
              <Typography variant="h6" sx={{ mb: 2, color: '#e2e8f0', fontWeight: 'medium' }}>
                Discovered Tokens
              </Typography>
              
              <Box sx={{ 
                background: 'rgba(15, 23, 42, 0.5)', 
                p: 2, 
                borderRadius: '12px',
                border: '1px solid rgba(255, 255, 255, 0.05)',
                maxHeight: '300px',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'rgba(255, 255, 255, 0.05)',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '4px',
                }
              }}>
                {discoveredTokens.length > 0 ? (
                  discoveredTokens.map((token, index) => (
                    <TokenCard key={index}>
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center' 
                      }}>
                        <Typography variant="body1" sx={{ color: '#fff' }}>
                          {token.substring(0, 6)}...{token.substring(token.length - 4)}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                          <GradientButton
                            size="small"
                            onClick={() => processNewToken(token, 'pump')}
                            color="primary"
                            themeName={currentTheme}
                          >
                            Buy
                          </GradientButton>
                          <GradientButton
                            size="small"
                            onClick={() => handleSellToken(token)}
                            color="error"
                            themeName={currentTheme}
                          >
                            Sell
                          </GradientButton>
                        </Box>
                      </Box>
                    </TokenCard>
                  ))
                ) : (
                  <Box sx={{ 
                    p: 3, 
                    textAlign: 'center', 
                    background: 'rgba(30, 41, 59, 0.3)',
                    borderRadius: '12px',
                    border: '1px dashed rgba(255, 255, 255, 0.1)'
                  }}>
                    <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      No tokens discovered yet. Start Auto-Ape to begin monitoring.
                    </Typography>
                  </Box>
                )}
              </Box>
              <Typography variant="h6" sx={{ mb: 2, color: '#e2e8f0', fontWeight: 'medium' }}>
  Social Sentiment Feed
</Typography>
<SentimentFeed themeName={currentTheme}>
  {newsFeeds.map((news, index) => (
    <Box key={index} sx={{ 
      mb: 2, 
      p: 2, 
      borderRadius: '8px',
      background: 'rgba(255, 255, 255, 0.05)',
      border: '1px solid rgba(255, 255, 255, 0.1)'
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="caption" sx={{ color: '#94A3B8' }}>
          {news.source.title}
        </Typography>
        <Typography variant="caption" sx={{ color: '#64748B' }}>
          {new Date(news.published_at).toLocaleTimeString()}
        </Typography>
      </Box>
      
      <Typography variant="body2" sx={{ color: '#fff', mb: 1 }}>
        {news.title}
      </Typography>
      
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        {news.currencies?.map((currency, idx) => (
          <Chip
            key={idx}
            label={`$${currency.code}`}
            size="small"
            sx={{
              backgroundColor: 'rgba(74, 222, 128, 0.1)',
              color: '#4ade80',
              fontSize: '0.75rem'
            }}
          />
        ))}
      </Box>
      
      <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
        {news.votes && (
          <>
            <Typography variant="caption" sx={{ 
              color: news.votes.positive > news.votes.negative ? '#4ade80' : '#ef4444'
            }}>
              👍 {news.votes.positive}
            </Typography>
            <Typography variant="caption" sx={{ color: '#64748B' }}>
              💬 {news.votes.comments}
            </Typography>
          </>
        )}
        <a 
          href={news.url} 
          target="_blank" 
          rel="noopener noreferrer"
          style={{ 
            color: '#60a5fa',
            fontSize: '0.75rem',
            textDecoration: 'none',
            marginLeft: 'auto'
          }}
        >
          Read more →
        </a>
      </Box>
    </Box>
  ))}
  {newsFeeds.length === 0 && (
    <Box sx={{ 
      p: 3, 
      textAlign: 'center', 
      color: '#94A3B8',
      background: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '8px'
    }}>
      Loading social sentiment data...
    </Box>
  )}
</SentimentFeed>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
  <CelebrityMonitor />
</Box>
          
          {/* Recent Fees */}
          {recentFees.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" sx={{ mb: 2, color: '#e2e8f0', fontWeight: 'medium' }}>
                Recent Fees
              </Typography>
              <Box sx={{ 
                background: 'rgba(15, 23, 42, 0.5)', 
                p: 2, 
                borderRadius: '12px',
                border: '1px solid rgba(255, 255, 255, 0.05)',
              }}>
                {recentFees.slice(0, 3).map((fee, index) => (
                  <Box 
                    key={index} 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      p: 1,
                      borderBottom: index < recentFees.length - 1 ? '1px solid rgba(255, 255, 255, 0.05)' : 'none'
                    }}
                  >
                    <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
                      {fee.txSignature.substring(0, 6)}...{fee.txSignature.substring(fee.txSignature.length - 4)}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#4ade80' }}>
                      {fee.amount.toFixed(4)} SOL
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </GlassPaper>
        
        {/* Pool Selection Modal */}
        {showPoolModal && pendingToken && createPortal(
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1200,
            }}
          >
            <Box
              sx={{
                background: 'linear-gradient(to right, #0f172a, #1e293b)',
                padding: '24px',
                borderRadius: '12px',
                width: '300px',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.5)',
                opacity: 1,
                transform: 'scale(1)',
                transition: 'opacity 0.2s ease, transform 0.2s ease',
              }}
            >
              <Typography variant="h6" sx={{ 
                color: '#FEFFEB', 
                marginTop: 0,
                marginBottom: '16px',
                textAlign: 'center',
                fontWeight: 'bold'
              }}>
                Select Pool
              </Typography>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                marginBottom: '20px'
              }}>
                {['raydium', 'pump'].map((pool) => (
                  <Button
                    key={pool}
                    onClick={() => setSelectedPool(pool)}
                    sx={{
                      background: selectedPool === pool 
                        ? 'linear-gradient(45deg, #4ade80 30%, #22c55e 90%)'
                        : 'rgba(255, 255, 255, 0.1)',
                      border: 'none',
                      padding: '10px',
                      borderRadius: '6px',
                      color: selectedPool === pool ? '#000' : '#fff',
                      cursor: 'pointer',
                      textTransform: 'capitalize',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        background: selectedPool === pool 
                          ? 'linear-gradient(45deg, #4ade80 30%, #22c55e 90%)'
                          : 'rgba(255, 255, 255, 0.15)',
                        transform: 'translateY(-2px)'
                      }
                    }}
                  >
                    {pool}
                  </Button>
                ))}
              </Box>
              <Box sx={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'center'
              }}>
                <Button
                  onClick={() => setShowPoolModal(false)}
                  sx={{
                    background: 'rgba(255, 255, 255, 0.1)',
                    color: '#fff',
                    borderRadius: '6px',
                    padding: '8px 16px',
                    '&:hover': {
                      background: 'rgba(255, 255, 255, 0.2)',
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={async () => {
                    setShowPoolModal(false);
                    const result = await processNewToken(pendingToken, selectedPool);
                    if (result?.success) {
                      showSuccessToast(result.signature);
                    }
                  }}
                  sx={{
                    background: 'linear-gradient(45deg, #4ade80 30%, #22c55e 90%)',
                    color: '#000',
                    borderRadius: '6px',
                    padding: '8px 16px',
                    fontWeight: 'bold',
                    '&:hover': {
                      boxShadow: '0 4px 10px rgba(74, 222, 128, 0.4)',
                      transform: 'translateY(-2px)'
                    }
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Box>,
          document.body
        )}
        
        <ToastContainer position="bottom-right" theme="dark" />
      </Box>
    </Box>,
    document.body
  );
};

export default DemoAutoApeModal;