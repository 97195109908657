import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  Box,
  Button,
  Card,
  
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Container,
  Paper,
  styled
} from '@mui/material';
import logoTp from '../../../assets/logo-tp.png';
import gapeImage from '../../../assets/gape.png';
import BSCInstructions from './BSCInstructions';
import dexPaidImage from '../../../assets/dexPaid.png';
import GainsCalculator from '../../features/GainsCalculator';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import headingImage from '../../../assets/bsc/Heading.png';
import BSCPreview from './BSCPreview';
import BridgeGuide from './BridgeGuide';

const theme = createTheme({
    palette: {
      primary: {
        main: '#F3BA2F', // BNB Yellow
        light: '#FEFFEB', // ApeOut Light Beige
        dark: '#C59E57', // ApeOut Gold
      },
      secondary: {
        main: '#322E29', // ApeOut Dark
        light: '#423D36',
        dark: '#9B7940',
      },
      background: {
        default: '#14151A', // BNB Dark
        paper: 'rgba(50, 46, 41, 0.9)', // ApeOut Dark with transparency
      },
    },
});
const StyledCard = styled(Card)({
    background: 'linear-gradient(135deg, rgba(243, 186, 47, 0.1), rgba(197, 158, 87, 0.2))',
    backdropFilter: 'blur(16px)',
    border: '1px solid rgba(243, 186, 47, 0.2)',
    borderRadius: '16px',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: '0 8px 24px rgba(243, 186, 47, 0.15)',
    }
});
  
const StyledButton = styled(Button)({
    background: 'linear-gradient(45deg, #F3BA2F 30%, #C59E57 90%)',
    borderRadius: '8px',
    border: 0,
    color: '#322E29',
    padding: '12px 24px',
    marginTop: '8px',
    width: '100%',
    fontWeight: 'bold',
    '&:hover': {
      background: 'linear-gradient(45deg, #C59E57 30%, #F3BA2F 90%)',
    }
});

const StyledContainer = styled(Container)({
  minHeight: '100vh',
  background: 'linear-gradient(to bottom, #111827, #000000)',
  padding: '32px 16px'
});

const CountdownContainer = styled(Box)({
    background: 'linear-gradient(135deg, rgba(243, 186, 47, 0.15), rgba(197, 158, 87, 0.25))',
    borderRadius: '12px',
    padding: '20px',
    marginBottom: '24px',
    textAlign: 'center',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(243, 186, 47, 0.2)'
});
  
const CountdownValue = styled(Typography)({
    fontSize: '2.5rem',
    fontWeight: 'bold',
    background: 'linear-gradient(45deg, #F3BA2F, #C59E57)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
});

const BSCDashboard = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [account, setAccount] = useState('');
  const [tokens] = useState([
    {
      name: 'BNNA',
      description: 'ApeOut Token on BSC',
      address: '0x...',
      image: dexPaidImage,
      strategies: ['DCA', 'DipsNRips', 'TWAP']
    },
    {
      name: 'GAPE',
      description: 'GameFi & Governance Token for ApeOut Ecosystem',
      address: '0x...',
      image: gapeImage,
      strategies: ['DCA', 'DipsNRips']
    }
  ]);
  const [countdown, setCountdown] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const connectWallet = async () => {
    if (!window.ethereum) {
      toast.error('Please install MetaMask!');
      return;
    }

    try {
      const accounts = await window.ethereum.request({ 
        method: 'eth_requestAccounts' 
      });
      
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x38' }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId: '0x38',
              chainName: 'Binance Smart Chain',
              nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18
              },
              rpcUrls: ['https://bsc-dataseed.binance.org/'],
              blockExplorerUrls: ['https://bscscan.com/']
            }]
          });
        }
      }

      setAccount(accounts[0]);
      setIsConnected(true);
      
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to connect wallet');
    }
  };

  useEffect(() => {
    // Set launch time to 36 hours from now
    const launchTime = new Date().getTime() + (36 * 60 * 60 * 1000);

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = launchTime - now;

      setCountdown({
        hours: Math.floor((distance % (1000 * 60 * 60 * 48)) / (1000 * 60 * 60)),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000)
      });

      if (distance < 0) {
        clearInterval(timer);
        setCountdown({ hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.request({ method: 'eth_accounts' })
        .then(accounts => {
          if (accounts.length > 0) {
            setAccount(accounts[0]);
            setIsConnected(true);
          }
        });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
    <StyledContainer maxWidth="xl">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
    <Box
      component="img"
      src={logoTp}
      alt="ApeOut Logo"
      sx={{ width: 48, height: 48 }}
    />
    <Typography variant="h3" component="h1" sx={{ color: 'white', fontWeight: 'bold' }}>
      ApeOut BSC
    </Typography>
  </Box>
  
        <Typography variant="p" component="h1" sx={{ color: 'white', fontWeight: 'bold' }}>
          Welcome to the Banana Stand on BSC!
        </Typography>
        <Button
  variant="contained"
  onClick={connectWallet}
  sx={{
    background: isConnected 
      ? 'linear-gradient(45deg, #F3BA2F 30%, #C59E57 90%)'  // Connected state - BNB Yellow to ApeOut Gold
      : 'linear-gradient(45deg, #F3BA2F 30%, #C59E57 90%)', // Not connected state - same gradient
    color: '#322E29', // Dark text for better contrast on orange
    p: '12px 24px',
    borderRadius: '12px',
    '&:hover': {
      background: 'linear-gradient(45deg, #C59E57 30%, #F3BA2F 90%)' // Reverse gradient on hover
    }
  }}
>
  {isConnected 
    ? `${account.slice(0, 6)}...${account.slice(-4)}` 
    : 'Connect MetaMask'}
</Button>

      </Box>
      <Card sx={{ maxWidth: '100%', borderRadius: '16px', overflow: 'hidden', boxShadow: 3, mb: 4 }}>
  <CardMedia
    component="img"
    src={headingImage} // Make sure to import and define heroImage
    alt="Hero Image"
    sx={{ width: '100%', height: 'auto' }} // Adjust dimensions as needed
  />
</Card>
      <CountdownContainer>
        <Typography variant="h5" sx={{ color: 'white', mb: 2 }}>
          BSC Springboard Launch Countdown
        </Typography>
        <CountdownValue>
          {`${countdown.hours.toString().padStart(2, '0')}:${countdown.minutes.toString().padStart(2, '0')}:${countdown.seconds.toString().padStart(2, '0')}`}
        </CountdownValue>
      </CountdownContainer>
      <BridgeGuide />
      <BSCInstructions />

      <Grid container spacing={3}>
        {tokens.map((token, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <StyledCard>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                  <Box
                    component="img"
                    src={token.image}
                    alt={token.name}
                    sx={{ width: 48, height: 48, borderRadius: '50%' }}
                  />
                  <Typography variant="h5" sx={{ color: 'white' }}>
                    {token.name}
                  </Typography>
                </Box>
                <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 2 }}>
                  {token.description}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {token.strategies.map((strategy, idx) => (
                    <StyledButton
                      key={idx}
                      onClick={() => toast.info(`${strategy} strategy coming soon!`)}
                    >
                      Start {strategy} Strategy
                    </StyledButton>
                  ))}
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <Typography variant="body1" sx={{ color: 'white', mb: 2 }}>
  The Gains Calculator helps you estimate potential profits from your investments in the ApeOut ecosystem and beyond.
</Typography>
      <GainsCalculator />
      <BSCPreview />
      <ToastContainer position="bottom-right" />
    </StyledContainer>
    </ThemeProvider>
  );
};

export default BSCDashboard;