import React from 'react';
import { AutoGraph } from '@mui/icons-material';
import PremiumFeature from '../common/PremiumFeature';
import AutoTPSLModal from './AutoTPSLModal';
import { toast } from 'react-toastify';

const isValidSolanaAddress = (address) => {
  return /^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(address);
};



const AutoTPSLButton = ({ 
  publicKey, 
  tradingWallet, 
  handleBuy, 
  handleAutoTPSLSell,
  handleBuyWithRaydium,
  isPremium,
  handleSell
}) => {
  const [showModal, setShowModal] = React.useState(false);
  
  // Load trade settings from localStorage with fallback
  const [tradeSettings, setTradeSettings] = React.useState(() => {
    const savedSettings = localStorage.getItem('tradeSettings');
    return savedSettings 
      ? JSON.parse(savedSettings) 
      : {
          priorityFee: 0.00001,
          slippage: 15,
          amount: 0.1
        };
  });

  // Update localStorage whenever trade settings change
  React.useEffect(() => {
    localStorage.setItem('tradeSettings', JSON.stringify(tradeSettings));
  }, [tradeSettings]);

  const slippage = tradeSettings.slippage; 
  const priorityFee = tradeSettings.priorityFee;
  return (
    <>
      <PremiumFeature
        publicKey={publicKey}
        onClick={() => setShowModal(true)}
        startIcon={<AutoGraph />}
        sx={{
          background: 'linear-gradient(to right, #3b82f6, #1d4ed8)',
          '&:hover': {
            background: 'linear-gradient(to right, #2563eb, #1e40af)',
          },
          color: 'white'
        }}
      >
        Auto TP/SL
      </PremiumFeature>

      <AutoTPSLModal
        open={showModal}
        onClose={() => setShowModal(false)}
        handleBuy={(mintAddress, amount) => {
          if (!isValidSolanaAddress(mintAddress)) {
            toast.error('Invalid token address format');
            return Promise.reject(new Error('Invalid token address format'));
          }
          return handleBuy(mintAddress, amount);
        }}
        handleBuyWithRaydium={(mintAddress, amount, slippage, priorityFee) => {
          if (!isValidSolanaAddress(mintAddress)) {
            toast.error('Invalid token address format');
            return Promise.reject(new Error('Invalid token address format'));
          }
          return handleBuyWithRaydium(mintAddress, amount, slippage, priorityFee);
        }}
        handleAutoTPSLSell={(mintAddress, amount) => {
          if (!isValidSolanaAddress(mintAddress)) {
            toast.error('Invalid token address format');
            return Promise.reject(new Error('Invalid token address format'));
          }
          // Pass default values for slippage and priorityFee
          return handleAutoTPSLSell(
            mintAddress, 
            amount,
            tradeSettings?.slippage || 15,
            tradeSettings?.priorityFee || 0.001
          );
        }}
        handleSell={(mintAddress, amount) => {
          if (!isValidSolanaAddress(mintAddress)) {
            toast.error('Invalid token address format');
            return Promise.reject(new Error('Invalid token address format'));
          }
          return handleSell(mintAddress, amount);
        }}
        publicKey={publicKey}
        tradingWallet={tradingWallet}
        tradeSettings={tradeSettings}
        setTradeSettings={setTradeSettings}
        isPremium={isPremium}
      />
    </>
  );
};
export default AutoTPSLButton;