import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";

const stats = [
  { label: "Hardware Projects Funded", value: "5" },
  { label: "Crypto Raised for Innovation", value: "$945" },
  { label: "Total Backers", value: "19,000" },
];

const CryptoKickstarterStats = () => {
  return (
    <Box sx={{ textAlign: "center", my: 6 }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
          mb: 2,
          color: "white",
          fontFamily: "Poppins, sans-serif",
        }}
      >
        Fuel the Future of AI & Hardware
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          color: "#9B7940",
          textTransform: "uppercase",
          fontWeight: 600,
          letterSpacing: "1px",
        }}
      >
        ON THE KICKSTARTER OF CRYPTO:
      </Typography>

      <Grid
        container
        spacing={3}
        sx={{ mt: 3, justifyContent: "center", maxWidth: 900, mx: "auto" }}
      >
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Paper
              elevation={3}
              sx={{
                p: 4,
                background: "#FEFFEB",
                borderRadius: "12px",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "#C59E57",
                  fontWeight: 700,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {stat.value}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#423D36", fontSize: "1rem", fontWeight: 500 }}
              >
                {stat.label}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CryptoKickstarterStats;
