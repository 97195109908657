import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Container, 
  Card, 
  CardContent, 
  Button, 
  Grid, 
  TextField, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  LinearProgress,
  Chip,
  IconButton,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import GapeGate from '../components/reusables/GapeGate';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { toast } from 'react-toastify';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: '15px',
  color: '#fff',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const VoteButton = styled(Button)(({ voted }) => ({
  background: voted ? 'linear-gradient(45deg, #4CAF50 30%, #45a049 90%)' : 'linear-gradient(45deg, #FF0000 30%, #FF8E53 90%)',
  borderRadius: '20px',
  padding: '8px 20px',
  color: 'white',
  '&:hover': {
    opacity: 0.9,
  },
}));

const ProposalButton = styled(Button)({
  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  borderRadius: '20px',
  padding: '10px 30px',
  color: 'white',
  marginBottom: '20px',
});

const SecretCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)), url(https://i.imgur.com/YourSecretImage.jpg)`, // Replace with your image
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  border: '2px solid rgba(255, 215, 0, 0.3)', // Golden border
  borderRadius: '15px',
  color: '#fff',
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 0 20px rgba(255, 215, 0, 0.2)',
    '& .secret-overlay': {
      opacity: 0.9,
    },
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(45deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 100%)',
    zIndex: 1,
  },
}));

const SecretButton = styled(Button)({
  background: 'linear-gradient(45deg, #FFD700 30%, #FFA500 90%)',
  border: 0,
  borderRadius: '25px',
  boxShadow: '0 3px 5px 2px rgba(255, 215, 0, .3)',
  color: 'black',
  padding: '10px 30px',
  fontWeight: 'bold',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FFA500 30%, #FFD700 90%)',
    transform: 'scale(1.05)',
  },
});

const LeaderboardButton = styled(Button)({
  background: 'linear-gradient(45deg, #9c27b0 30%, #d81b60 90%)',
  borderRadius: '20px',
  padding: '10px 30px',
  color: 'white',
  marginBottom: '20px',
  marginRight: '15px',
  '&:hover': {
    opacity: 0.9,
  },
});

const Governance = () => {
  const [openProposalDialog, setOpenProposalDialog] = useState(false);
  const [proposals, setProposals] = useState([
    {
      id: 1,
      title: "Integration of New DEX Protocol",
      description: "Proposal to integrate Jupiter DEX for enhanced liquidity and better swap rates.",
      votesFor: 6500,
      votesAgainst: 3500,
      status: "active",
      endDate: "2024-03-01",
      voted: false
    },
    {
      id: 2,
      title: "Reduce Platform Fees",
      description: "Reduce trading fees from 0.3% to 0.2% to attract more volume.",
      votesFor: 8200,
      votesAgainst: 1800,
      status: "active",
      endDate: "2024-02-28",
      voted: false
    },
    {
      id: 3,
      title: "Launch Staking V2",
      description: "Implement new staking mechanism with dynamic rewards based on TVL.",
      votesFor: 7500,
      votesAgainst: 2500,
      status: "pending",
      endDate: "2024-03-05",
      voted: false
    }
  ]);
  const [newProposal, setNewProposal] = useState({
    title: '',
    description: '',
    endDate: ''
  });

  const handleVote = (proposalId, voteType) => {
    setProposals(proposals.map(proposal => {
      if (proposal.id === proposalId) {
        if (proposal.voted) {
          toast.error('You have already voted on this proposal');
          return proposal;
        }
        return {
          ...proposal,
          votesFor: voteType === 'for' ? proposal.votesFor + 100 : proposal.votesFor,
          votesAgainst: voteType === 'against' ? proposal.votesAgainst + 100 : proposal.votesAgainst,
          voted: true
        };
      }
      return proposal;
    }));
    toast.success('Vote recorded successfully!');
  };

  const handleSubmitProposal = () => {
    if (!newProposal.title || !newProposal.description || !newProposal.endDate) {
      toast.error('Please fill all fields');
      return;
    }

    const proposal = {
      id: proposals.length + 1,
      ...newProposal,
      votesFor: 0,
      votesAgainst: 0,
      status: 'pending',
      voted: false
    };

    setProposals([...proposals, proposal]);
    setOpenProposalDialog(false);
    setNewProposal({ title: '', description: '', endDate: '' });
    toast.success('Proposal submitted successfully!');
  };

  return (
    <GapeGate requiredBalance={1000}>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <Typography variant="h3" sx={{ color: '#fff' }}>
            Governance Portal
          </Typography>
          <Box>
          <LeaderboardButton
              startIcon={<HowToVoteIcon />}
              onClick={() => window.open('/leaderboard', '_blank')}
            >
              $GAPE Leaderboard
            </LeaderboardButton>
            <ProposalButton
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => setOpenProposalDialog(true)}
            >
              Create Proposal
            </ProposalButton>
          </Box>
        </Box>

        <Box sx={{ mb: 4 }}>
          <SecretCard>
            <CardContent sx={{ position: 'relative', zIndex: 2, py: 4 }}>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                textAlign: 'center'
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 2 
                }}>
                  <LockIcon sx={{ 
                    fontSize: 40, 
                    color: '#FFD700', 
                    mr: 1 
                  }} />
                  <Typography variant="h4" 
                    sx={{ 
                      fontWeight: 'bold',
                      background: 'linear-gradient(45deg, #FFD700 30%, #FFA500 90%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    Private Ape Council
                  </Typography>
                </Box>

                <Typography variant="h6" 
                  sx={{ 
                    mb: 2,
                    color: 'rgba(255, 255, 255, 0.9)',
                    maxWidth: '600px'
                  }}
                >
                  An exclusive enclave for elite $GAPE holders. Access confidential alpha, 
                  test unreleased features, and receive priority support.
                </Typography>

                <Box sx={{ 
                  display: 'flex', 
                  gap: 2, 
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  mb: 3 
                }}>
                  <Chip 
                    icon={<VpnKeyIcon />} 
                    label="Exclusive Alpha" 
                    sx={{ 
                      backgroundColor: 'rgba(255, 215, 0, 0.1)',
                      color: '#FFD700',
                      border: '1px solid rgba(255, 215, 0, 0.3)'
                    }} 
                  />
                  <Chip 
                    icon={<LockIcon />} 
                    label="Beta Features" 
                    sx={{ 
                      backgroundColor: 'rgba(255, 215, 0, 0.1)',
                      color: '#FFD700',
                      border: '1px solid rgba(255, 215, 0, 0.3)'
                    }} 
                  />
                  <Chip 
                    label="Priority Support" 
                    sx={{ 
                      backgroundColor: 'rgba(255, 215, 0, 0.1)',
                      color: '#FFD700',
                      border: '1px solid rgba(255, 215, 0, 0.3)'
                    }} 
                  />
                </Box>

                <Typography variant="body2" 
                  sx={{ 
                    color: '#FFD700',
                    mb: 3,
                    fontStyle: 'italic'
                  }}
                >
                  Requires 100,000 $GAPE tokens to access
                </Typography>

                <SecretButton
                  startIcon={<LockIcon />}
                  onClick={() => {
                    // Add your access check and navigation logic here
                    toast.info('Checking eligibility for Private Ape Council...');
                  }}
                >
                  Request Access
                </SecretButton>
              </Box>
            </CardContent>
          </SecretCard>
        </Box>

        <Grid container spacing={3}>
          {proposals.map((proposal) => (
            <Grid item xs={12} md={6} key={proposal.id}>
              <StyledCard>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h5" component="div">
                      {proposal.title}
                    </Typography>
                    <Chip 
                      label={proposal.status.toUpperCase()} 
                      color={proposal.status === 'active' ? 'success' : 'warning'}
                      size="small"
                    />
                  </Box>
                  
                  <Typography variant="body2" sx={{ mb: 2, color: 'rgba(255, 255, 255, 0.7)' }}>
                    {proposal.description}
                  </Typography>

                  <Box sx={{ mb: 2 }}>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Votes: {((proposal.votesFor / (proposal.votesFor + proposal.votesAgainst)) * 100).toFixed(1)}% in favor
                    </Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={(proposal.votesFor / (proposal.votesFor + proposal.votesAgainst)) * 100}
                      sx={{ height: 8, borderRadius: 5 }}
                    />
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                      <VoteButton 
                        size="small" 
                        onClick={() => handleVote(proposal.id, 'for')}
                        disabled={proposal.voted}
                        voted={proposal.voted}
                        sx={{ mr: 1 }}
                      >
                        For ({proposal.votesFor.toLocaleString()})
                      </VoteButton>
                      <VoteButton 
                        size="small"
                        onClick={() => handleVote(proposal.id, 'against')}
                        disabled={proposal.voted}
                        voted={proposal.voted}
                      >
                        Against ({proposal.votesAgainst.toLocaleString()})
                      </VoteButton>
                    </Box>
                    <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.5)' }}>
                      Ends: {proposal.endDate}
                    </Typography>
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>

        <Dialog 
          open={openProposalDialog} 
          onClose={() => setOpenProposalDialog(false)}
          PaperProps={{
            style: {
              backgroundColor: '#1a1a1a',
              color: '#fff',
              borderRadius: '15px',
            },
          }}
        >
          <DialogTitle>Create New Proposal</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Proposal Title"
              fullWidth
              variant="outlined"
              value={newProposal.title}
              onChange={(e) => setNewProposal({ ...newProposal, title: e.target.value })}
              sx={{ mb: 2 }}
              InputLabelProps={{ sx: { color: 'rgba(255, 255, 255, 0.7)' } }}
              InputProps={{ sx: { color: '#fff' } }}
            />
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              variant="outlined"
              value={newProposal.description}
              onChange={(e) => setNewProposal({ ...newProposal, description: e.target.value })}
              sx={{ mb: 2 }}
              InputLabelProps={{ sx: { color: 'rgba(255, 255, 255, 0.7)' } }}
              InputProps={{ sx: { color: '#fff' } }}
            />
            <TextField
              margin="dense"
              label="End Date"
              fullWidth
              variant="outlined"
              type="date"
              value={newProposal.endDate}
              onChange={(e) => setNewProposal({ ...newProposal, endDate: e.target.value })}
              sx={{ mb: 2 }}
              InputLabelProps={{ sx: { color: 'rgba(255, 255, 255, 0.7)' } }}
              InputProps={{ sx: { color: '#fff' } }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenProposalDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmitProposal} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </GapeGate>
  );
};

export default Governance; 