import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import styled from '@emotion/styled';
import icon from '../assets/gifs/chopnana.gif'
import bananaSmileIcon from '../assets/gifs/banana-smile.gif'
import bananasIcon from '../assets/gifs/bananas.gif'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { toast } from 'react-toastify';

// Import wallet adapter styles
require('@solana/wallet-adapter-react-ui/styles.css');

const Container = styled(Box)({
  backgroundImage: 'url(https://github.com/apeoutmeme/resources/blob/32f5092cc73b7141524c2ac0abe5ea0daa56c25c/assets/staking/2024.webp?raw=true)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: '#fff',
  minHeight: '100vh',
  padding: '20px',
  fontFamily: 'Pixel, Arial, sans-serif',
});

const Header = styled(Box)({
  textAlign: 'center',
  paddingBottom: '20px',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
});

const UnlockBox = styled(Box)({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  border: '1px solid #fff',
  borderRadius: '10px',
  padding: '20px',
  textAlign: 'center',
  margin: '10px',
});

const ClaimButton = styled(Button)({
  backgroundColor: '#00bcd4',
  color: '#fff',
  marginTop: '20px',
  '&:hover': {
    backgroundColor: '#0097a7',
  },
});

const StyledLink = styled('a')({
    color: '#00bcd4',
    textDecoration: 'none',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: '#FFD700',
      textDecoration: 'underline',
    }
  });
  
  const Footer = styled(Box)({
    textAlign: 'center',
    marginTop: '20px',
    padding: '20px',
    fontSize: '12px',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  });

const StyledWalletButton = styled(WalletMultiButton)({
  backgroundColor: 'rgba(0, 188, 212, 0.1) !important',
  color: '#fff !important',
  border: '1px solid #00bcd4 !important',
  '&:hover': {
    backgroundColor: 'rgba(0, 188, 212, 0.2) !important',
  },
  '&:disabled': {
    backgroundColor: 'rgba(107, 114, 128, 0.3) !important',
    borderColor: '#6b7280 !important',
    color: '#9ca3af !important',
  },
});

const CheckBalanceButton = styled(Button)({
  backgroundColor: 'rgba(0, 188, 212, 0.1)',
  color: '#fff',
  marginRight: '10px',
  border: '1px solid #00bcd4',
  '&:hover': {
    backgroundColor: 'rgba(0, 188, 212, 0.2)',
  },
});

const Claim = () => {
  const { connected, publicKey } = useWallet();
  const [tokenBalances, setTokenBalances] = useState({
    BNNA: 0,
    AIAI: 0,
    GAPE: 0,
    FADER: 0
  });
  const [isLoading, setIsLoading] = useState({
    BNNA: false,
    GAPE: false,
  });

  const TOKEN_MINTS = {
    BNNA: 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump',
    GAPE: '4FM5bXZS4qnMK4Vdn4yRM4mANUVd97xYKZ2yKxziJRkd',
  };

  const REQUIRED_BALANCES = {
    BNNA: 1000, // Example: Require 1000 BNNA to claim
    GAPE: 100,  // Example: Require 100 GAPE to claim
  };

  const checkTokenBalance = async (tokenName) => {
    if (!publicKey || !TOKEN_MINTS[tokenName]) return;

    setIsLoading(prev => ({ ...prev, [tokenName]: true }));
    try {
      const response = await fetch(`/api/token-balance/${publicKey.toString()}/${TOKEN_MINTS[tokenName]}`);
      const data = await response.json();

      if (data.success) {
        setTokenBalances(prev => ({
          ...prev,
          [tokenName]: data.balance
        }));

        // Check if balance meets requirement
        if (data.balance < REQUIRED_BALANCES[tokenName]) {
          toast.error(`Insufficient ${tokenName} balance. Required: ${REQUIRED_BALANCES[tokenName]}`);
        } else {
          toast.success(`${tokenName} balance verified! You can proceed with the claim.`);
        }
      } else {
        toast.error(`Failed to fetch ${tokenName} balance`);
      }
    } catch (error) {
      console.error(`Error checking ${tokenName} balance:`, error);
      toast.error(`Error checking ${tokenName} balance`);
    } finally {
      setIsLoading(prev => ({ ...prev, [tokenName]: false }));
    }
  };

  const handleClaim = async (tokenName) => {
    if (!connected) {
      toast.error('Please connect your wallet first');
      return;
    }

    if (!tokenBalances[tokenName]) {
      toast.error('Please check your balance first');
      return;
    }

    if (tokenBalances[tokenName] < REQUIRED_BALANCES[tokenName]) {
      toast.error(`Insufficient ${tokenName} balance. Required: ${REQUIRED_BALANCES[tokenName]}`);
      return;
    }

    // Add claim logic here
    toast.info('Claim functionality coming soon!');
  };

  return (
    <Container>
      <Header>
        <Typography variant="h4" gutterBottom>
          CLAIM BNNA
        </Typography>
        <StyledWalletButton />
        {connected && (
          <Typography variant="subtitle1" style={{ marginTop: '10px', color: '#00bcd4' }}>
            BNNA Balance: {tokenBalances.BNNA.toFixed(2)}
          </Typography>
        )}
        <Button 
          target="_blank" 
          href="https://dexscreener.com/solana/g3rhsoewmmvembdkbec6sfwu91rbmkdyeztwdnmawjxb" 
          variant="outlined" 
          color="primary" 
          style={{ color: '#fff', borderColor: '#fff', marginLeft: '10px' }}
        >
          Buy $BNNA
        </Button>
      </Header>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={4}>
          <UnlockBox>
            <Typography variant="h6">NEXT UNLOCK IN 5 HOURS</Typography>
            <img src={bananaSmileIcon} alt="Fire Sale" style={{ width: '100px', margin: '10px auto' }} />
            <Typography>
              It's money time! Claim your BNNA from Fire Sale and Private Sale.
            </Typography>
            {connected && (
              <Typography variant="caption" style={{ color: '#00bcd4', display: 'block', margin: '10px 0' }}>
                Balance: {tokenBalances.BNNA.toFixed(2)} BNNA
              </Typography>
            )}
            <Box sx={{ mt: 2 }}>
              <CheckBalanceButton
                onClick={() => checkTokenBalance('BNNA')}
                disabled={!connected || isLoading.BNNA}
              >
                {isLoading.BNNA ? 'Checking...' : 'Check Balance'}
              </CheckBalanceButton>
              <ClaimButton 
                variant="contained"
                onClick={() => handleClaim('BNNA')}
                disabled={!connected || tokenBalances.BNNA < REQUIRED_BALANCES.BNNA || isLoading.BNNA}
              >
                Claim BNNA
              </ClaimButton>
            </Box>
          </UnlockBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <UnlockBox>
            <Typography variant="h6">GOATINDEX.AI AIRDROP</Typography>
            <img src={icon} alt="AIAI Airdrop" style={{ width: '100px', margin: '10px auto' }} />
            <Typography>
              Claim 4,000 $AIAI tokens from the GoatIndex.ai airdrop.
            </Typography>
            <Typography variant="caption" style={{ color: '#00bcd4' }}>
              Available: 4,000 $AIAI
            </Typography>
            <ClaimButton variant="contained">
              Claim $AIAI
            </ClaimButton>
          </UnlockBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <UnlockBox>
            <Typography variant="h6">$GAPE AIRDROP</Typography>
            <img src={bananasIcon} alt="GAPE Airdrop" style={{ width: '100px', margin: '10px auto' }} />
            <Typography>
              Claim your share of 1M $GAPE tokens.
            </Typography>
            {connected && (
              <Typography variant="caption" style={{ color: '#00bcd4', display: 'block', margin: '10px 0' }}>
                Balance: {tokenBalances.GAPE.toFixed(2)} GAPE
              </Typography>
            )}
            <Box sx={{ mt: 2 }}>
              <CheckBalanceButton
                onClick={() => checkTokenBalance('GAPE')}
                disabled={!connected || isLoading.GAPE}
              >
                {isLoading.GAPE ? 'Checking...' : 'Check Balance'}
              </CheckBalanceButton>
              <ClaimButton 
                variant="contained"
                onClick={() => handleClaim('GAPE')}
                disabled={!connected || tokenBalances.GAPE < REQUIRED_BALANCES.GAPE || isLoading.GAPE}
              >
                Claim GAPE
              </ClaimButton>
            </Box>
          </UnlockBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <UnlockBox>
            <Typography variant="h6">$FADER AIRDROP</Typography>
            <img src={bananasIcon} alt="GAPE Airdrop" style={{ width: '100px', margin: '10px auto' }} />
            <Typography>
              Claim your share of 1M $FADER tokens.
            </Typography>
            <Typography variant="caption" style={{ color: '#00bcd4' }}>
              Available: 1,000,000 $FADER
            </Typography>
            <ClaimButton variant="contained">
              Claim $FADER
            </ClaimButton>
          </UnlockBox>
        </Grid>
      </Grid>

      <Footer>
  By continuing, you agree to{' '}
  <StyledLink target="_blank" href="/terms-of-use">Terms of Use</StyledLink>,{' '}
  <StyledLink target="_blank" href="/terms-fire-sale">Terms for Fire Sale</StyledLink>, and{' '}
  <StyledLink target="_blank" href="/terms-airdrop">Terms for Airdrop</StyledLink>.
</Footer>
    </Container>
  );
};

export default Claim;

